/* eslint-disable @typescript-eslint/no-unused-vars */

import { Panels, ServiceReports, Settings } from '../pages';
import { Policy, TextSnippet, Info, Dashboard } from '@mui/icons-material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Support } from '@brightlayer-ui/icons-mui';
import TuneIcon from '@mui/icons-material/Tune';
import GroupIcon from '@mui/icons-material/Group';
import AccountUsers from '../components/AccountUsers/AccountUsers';
import SettingsIcon from '@mui/icons-material/Settings';
import ArticleIcon from '@mui/icons-material/Article';
import { EndUserInfo } from '../pages/EndUserInfo/EndUserInfo';
import { Help } from '../pages/HelpMenu/Help';
import { Admins } from '../pages/Admins/Admins';
import { Installers } from '../pages/Installers/Installers';
import { AdminLogs } from '../pages/AdminLogs/AdminLogs';
import { SupportTools } from '../pages/SupportTools/SupportTools';
import { SystemConfiguration } from '../pages/SystemConfiguration/SystemConfiguration';

export const INSTALLER_MENU = [
    {
        title: 'Overview',
        route: '',
        component: Panels,
        icon: Dashboard,
    },
    {
        title: 'End User Info',
        route: 'end-user-info',
        component: EndUserInfo,
        icon: Info,
    },
    {
        title: 'Accounts',
        route: 'account-users',
        component: AccountUsers,
        icon: GroupIcon,
    },
    {
        title: 'Service Reports',
        route: 'service-reports',
        component: ServiceReports,
        icon: TextSnippet,
    },
    {
        title: 'Settings',
        route: 'settings',
        component: Settings,
        icon: SettingsIcon,
    },
    {
        title: 'Information',
        route: 'information',
        component: Help,
        icon: Policy,
    },
];

export const ADMIN_MENU = [
    {
        title: 'Overview',
        route: '',
        component: Panels,
        icon: Dashboard,
    },
    {
        title: 'Installers',
        route: 'installers',
        component: Installers,
        icon: ManageAccountsIcon,
    },
    {
        title: 'Administrators',
        route: 'admins',
        component: Admins,
        icon: GroupIcon,
    },
    {
        title: 'Settings',
        route: 'settings',
        component: Settings,
        icon: SettingsIcon,
    },
    {
        title: 'Support tools',
        route: 'support_tools',
        component: SupportTools,
        icon: Support,
    },
    {
        title: 'System config',
        route: 'system_configuration',
        component: SystemConfiguration,
        icon: TuneIcon,
    },
    {
        title: 'Accounts log',
        route: 'logs',
        component: AdminLogs,
        icon: ArticleIcon,
    },
];

export const MANAGER_MENU = [
    {
        title: 'Overview',
        route: '',
        component: Panels,
        icon: Dashboard,
    },
    {
        title: 'Accounts',
        route: 'account-users',
        component: AccountUsers,
        icon: GroupIcon,
    },
    {
        title: 'End User Info',
        route: 'end-user-info',
        component: EndUserInfo,
        icon: Info,
    },
    {
        title: 'Settings',
        route: 'settings',
        component: Settings,
        icon: SettingsIcon,
    },
];

export const OFFICESTAFF_MENU = [
    {
        title: 'Overview',
        route: '',
        component: Panels,
        icon: Dashboard,
    },
    {
        title: 'Settings',
        route: 'settings',
        component: Settings,
        icon: SettingsIcon,
    },
];

export const ENGINEER_MENU = [
    {
        title: 'Overview',
        route: '',
        component: Panels,
        icon: Dashboard,
    },
    {
        title: 'Settings',
        route: 'settings',
        component: Settings,
        icon: SettingsIcon,
    },
];

export const ENDUSER_MENU = [
    {
        title: 'Overview',
        route: '',
        component: Panels,
        icon: Dashboard,
    },
];