/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Divider, IconButton, MobileStepper, Paper, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import { Spacer } from '@brightlayer-ui/react-components';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { deleteCompletedReportFromListForPanel } from '../../redux/actions/remoteServicingActions';
import DeleteCompletedServiceReportModal from '../RemoteServicingTab/DeleteCompletedServiceReportModal'
import { useTranslation } from 'react-i18next';
import { getDateFormat, getTimeFormat } from '../Timestamp/timestampCal';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    backgroundColor: 'background.paper',
    boxShadow: 20,
    p: 0,
};

type ReportDetailsProps = {
    page:string
    setOpenPdfReport: (openPdfReport: boolean) => void
    report: any
    open: boolean
    setOpen: (open: boolean) => void
    counter: number;
    setCounter: (counter: number) => void;
}

export default function CompletedReportDetailsModal({page, setOpenPdfReport, report, open, setOpen, counter, setCounter }: ReportDetailsProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useTypedDispatch();
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const { specificPanel } = useSelector((state: any) => state.specificPanelAndEstateReducer);
    const { t } = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        setOpenDeleteModal(true)
    };

    const handleView = () => {
        setOpenPdfReport(true)
        setOpen(false);
    };


    return (
        <div>
            <>
                <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box
                        sx={{
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'background.paper',
                            boxShadow: 20,
                            p: 0,
                        }}
                    >
                        <Paper elevation={0} sx={{ pb: 0 }}>
                            <Paper
                                elevation={4}
                                sx={{
                                    paddingTop: 2,
                                    paddingBottom: 1,
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <IconButton onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                                &ensp;&ensp;
                                <Typography sx={{ pt: 0.5 }} variant="h6">
                                    {t('lbl_reportDetails')}
                                </Typography>
                                <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                                <IconButton>
                                    <DownloadIcon />
                                </IconButton>
                            </Paper>
                            <Divider />
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    paddingTop: 3,
                                    paddingBottom: 5,
                                    paddingLeft: 5,
                                    paddingRight: 3,
                                    maxHeight: 800,
                                    overflow: 'auto',
                                }}
                            >
                               {page === 'service-reports'?  
                               <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{display:'flex',flexDirection:'column',paddingBottom:'3vh'}}>
                                        <Typography variant='subtitle1' color={'primary'}>{t('lbl_panelId')}</Typography>
                                        <Typography variant='body2'>{report?.code}</Typography>
                                    </div>
                                    <div style={{display:'flex',flexDirection:'column',paddingBottom:'3vh'}}>
                                        <Typography variant='subtitle1' color={'primary'}>{t('lbl_systmName')}</Typography>
                                        <Typography variant='body2'>{report?.name}</Typography>
                                    </div>
                                    <div style={{display:'flex',flexDirection:'column',paddingBottom:'3vh'}}>
                                        <Typography variant='subtitle1' color={'primary'}>{t('lbl_status')}</Typography>
                                        <Typography variant='body2'>{report?.status}</Typography>
                                    </div>
                                    <div style={{display:'flex',flexDirection:'column',paddingBottom:'3vh'}}>
                                        <Typography variant='subtitle1' color={'primary'}>{t('lbl_cmpltd_date')}</Typography>
                                        <Typography variant='body2'>{`${getDateFormat(Number(report?.sysTimestampEnd) * 1000)} , ${getTimeFormat(Number(report?.sysTimestampEnd) * 1000)}`}</Typography>
                                    </div>
                                    <div style={{display:'flex',flexDirection:'column',paddingBottom:'3vh'}}>
                                        <Typography variant='subtitle1' color={'primary'}>{t('lbl_cmpltd_Classification')}</Typography>
                                        <Typography  sx={{ color: report?.analysis === 'RED' ? '#CA3C3D' : report?.analysis === 'GREEN' ? '#39B620' : '#E57F0A' }} variant='body2'>
                                            {report?.analysis}</Typography>
                                    </div>
                                    <div style={{display:'flex',flexDirection:'column',paddingBottom:'3vh'}}>
                                        <Typography variant='subtitle1' color={'primary'}>{t('lbl_cmpltd_date')}</Typography>
                                        <Typography variant='body2'>{report?.id}</Typography>
                                    </div>
                               </div>
                               :
                               <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '3vh' }}>
                                        <Typography variant='subtitle1' color={'primary'}>{t('lbl_report_no')}</Typography>
                                        <Typography variant='body2'>{report?.id}</Typography>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '3vh' }}>
                                        <Typography variant='subtitle1' color={'primary'}>{t('lbl_cmpltd_date')}</Typography>
                                        <Typography variant='body2'> {`${getDateFormat(Number(report?.sysTimestampEnd) * 1000)}, ${getTimeFormat(Number(report?.sysTimestampEnd) * 1000)}`} UTC</Typography>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '3vh' }}>
                                        <Typography variant='subtitle1' color={'primary'}>{t('lbl_cmpltd_Classification')}</Typography>
                                        <Typography sx={{ color: report?.analysis === 'red' ? '#CA3C3D' : report?.analysis === 'green' ? '#39B620' : '#E57F0A' }}
                                            variant="body1">{report?.analysis !== undefined ? report?.analysis.toLocaleUpperCase() : ''}</Typography>
                                    </div>
                                </div>}
                            </Box>
                        </Paper>

                        <Paper
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '100dvh',
                                transform: 'translateY(-100%)',
                                width: '100%',
                            }}
                        >
                            <MobileStepper
                                variant="dots"
                                steps={0}
                                position="static"
                                activeStep={0}
                                sx={{
                                    maxWidth: '100%',
                                    flexGrow: 1,
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    paddingLeft: 3,
                                    paddingRight: 3,
                                    backgroundColor: 'inherit',
                                }}
                                nextButton={
                                    <Button size="large" variant="contained" onClick={handleView}>
                                        {t('lbl_cmpltd_View')}
                                    </Button>
                                }
                                backButton={
                                    <Button size="large" sx={{ border: 1 }} variant="outlined" color='error' onClick={handleDelete}>
                                        {t('lbl_delete')}
                                    </Button>
                                }
                            />
                        </Paper>
                    </Box>
                </Modal>
                <DeleteCompletedServiceReportModal selectedReportId={report?.id} openDeleteModal={openDeleteModal} setOpenDeleteModal={setOpenDeleteModal} counter={counter} setCounter={setCounter} open={open} setOpen={setOpen}></DeleteCompletedServiceReportModal>
            </>
        </div>
    );
}