/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import Appbar from '../../components/Appbar/Appbar';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {
    Button,
    Theme,
    InputAdornment,
    TextField,
    useMediaQuery,
    useTheme,
    IconButton,
    Fab,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableBody,
    Typography,
    TablePagination,
    Snackbar,
    Stack,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import { EmptyState, InfoListItem, ListItemTag } from '@brightlayer-ui/react-components';
import { useSelector } from 'react-redux';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AddIcon from '@mui/icons-material/Add';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { useTranslation } from 'react-i18next';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { fetchAdminAccountsLog } from '../../redux/actions/administratorsActions';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SortListMenu from '../../components/SortListButton/SortListMenu';
import FilterListIcon from '@mui/icons-material/FilterList';
import { AccountsLogProps } from '../../components/AccountsLog/AccountsLogType';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    })
);

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
}));

export function AdminLogs(): JSX.Element {
    const theme = useTheme();
    const classes = useStyles(theme);
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useTypedDispatch();
    const { t } = useTranslation();
    const { accountsLog } = useSelector((state: any) => state.administratorReducer);
    const [query, setQuery] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [count, setCount] = React.useState(0);
    const [counter, setCounter] = React.useState(0);
    const [deleteAdminMessage, setDeleteAdminMessage] = React.useState('');
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [sortListType, setSortListType] = React.useState<string>('A-Z');
    const [tableKey, setTableKey] = React.useState<number | undefined>(undefined)

    const open = Boolean(anchorEl);
    const { deleteAdminStatus } = useSelector((state: any) => state.administratorReducer);

    const fetching = async () => {
        await dispatch(fetchAdminAccountsLog((page * 10)));
    };

    React.useEffect(() => {
        void fetching();
    }, [page]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const handleSortClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const getHours = (timestamp: number): string => {
        const hours =
            new Date(new Date(timestamp).toUTCString()).getUTCHours() > 12
                ?(new Date(new Date(timestamp).toUTCString()).getUTCHours() - 12) > 9 ? (new Date(new Date(timestamp).toUTCString()).getUTCHours() - 12).toString() : `0${(new Date(new Date(timestamp).toUTCString()).getUTCHours() - 12).toString()}`
                : (new Date(new Date(timestamp).toUTCString()).getUTCHours()) > 9 ? new Date(new Date(timestamp).toUTCString()).getUTCHours().toString() : `0${new Date(new Date(timestamp).toUTCString()).getUTCHours().toString()}`

        return hours;
    };
    const getMinutes = (timestamp: number): string => {
        const minutes = new Date(new Date(timestamp).toUTCString()).getUTCMinutes() > 9 
                        ? new Date(new Date(timestamp).toUTCString()).getUTCMinutes().toString() :
                        `0${new Date(new Date(timestamp).toUTCString()).getUTCMinutes().toString()}`

        return minutes;
    };
    const getAMorPM = (timestamp: number): string => {
        const dayType = new Date(new Date(timestamp).toUTCString()).getUTCHours() >= 12 ? 'PM' : 'AM';

        return dayType;
    };
    const getDate = (timestamp: number): string => {
        const date = new Date(new Date(timestamp).toUTCString()).getUTCDate() > 9 ? (new Date(new Date(timestamp).toUTCString()).getUTCDate().toString()):(`0${new Date(new Date(timestamp).toUTCString()).getUTCDate().toString()}`) ;

        return date;
    };
    const getMonth = (timestamp: number): string => {
        const month = new Date(new Date(timestamp).toUTCString()).getUTCMonth() + 1 >9 ? (new Date(new Date(timestamp).toUTCString()).getUTCMonth() + 1).toString() : `0${(new Date(new Date(timestamp).toUTCString()).getUTCMonth() + 1).toString()}`

        return month;
    };
    const getYear = (timestamp: number): number => {
        const year = new Date(new Date(timestamp).toUTCString()).getUTCFullYear();

        return year;
    };

    const getDateFormat = (timestamp: number):string =>{
        const dateFormat = `${getDate(timestamp).toString()}/${getMonth(timestamp).toString()}/${getYear(timestamp).toString()}`

        return dateFormat
    }

    const getTimeFormat = (timestamp: number):string =>{
        const timeFormat = `${getHours(timestamp).toString()}:${getMinutes(timestamp).toString()} ${getAMorPM(timestamp).toString()}`
    
        return timeFormat
    }

    const handleLogClick = (log:AccountsLogProps,key:number,e : React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.checked)
        setTableKey(key)
        else setTableKey(undefined)
    }

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackClose}>
                {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    return (
        <>
            <div className="main-div">
            <div style={{maxHeight:'100vh',overflow:'auto'}}>
                <Appbar toolbarMenu={false} title={'Accounts Log'} />
                    <Box sx={{ flexGrow: 1, marginLeft: '4%', marginRight: '4%', marginTop: md? 2:5 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={md? 12 : 2.2}>
                                <Item elevation={0}>
                                    {/* <Button fullWidth variant="outlined" sx={{ fontSize: 13 }}>
                                        <AddIcon sx={{ fontSize: 15 }} />
                                        &nbsp;&nbsp;{'Today'}
                                    </Button> */}
                                    <TextField  
                                        fullWidth
                                        variant='outlined' 
                                        value={'Today'}
                                        color='secondary'
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {(
                                                        <IconButton>
                                                            <CalendarTodayIcon />
                                                        </IconButton>
                                                    )}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>
                {md ? (
                    <div style={{ backgroundColor: 'white' }}>
                        <Box
                            sx={{
                                flexGrow: 1,
                                marginLeft: 2,
                                marginRight: 2,
                                marginTop: 2,
                                backgroundColor: 'background.paper',
                                height: '100vh',
                            }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={10.5}>
                                    <Item elevation={0}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder={t('lbl_search')}
                                            onChange={(e) => setQuery(e.target.value)}
                                            value={query}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {query.length !== 0 && (
                                                            <IconButton>
                                                                <CancelIcon />
                                                            </IconButton>
                                                        )}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Item>
                                </Grid>
                                <Grid item xs={1.5}>
                                    <IconButton onClick={(e) => handleSortClick(e)} size="small">
                                        <FilterListIcon style={{ marginTop: 15 }} />
                                    </IconButton>
                                    <SortListMenu setSortListType={setSortListType} anchorEl={anchorEl} open={open} setAnchorEl={setAnchorEl} />
                                </Grid>
                                <Grid item sx={{ mb: 15 }} xs={12}>
                                                <Item elevation={1}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell width="45%">
                                                                    {'Name'}&ensp;
                                                                    <Checkbox
                                                                        icon={
                                                                            <ArrowDownwardIcon
                                                                                fontSize="small"
                                                                                color="disabled"
                                                                            />
                                                                        }
                                                                        checkedIcon={
                                                                            <ArrowUpwardIcon
                                                                                fontSize="small"
                                                                                color="disabled"
                                                                            />
                                                                        }
                                                                    />
                                                                </TableCell>
                                                                <TableCell width="45%">
                                                                    {'Action'}&ensp;
                                                                    <Checkbox
                                                                        icon={
                                                                            <ArrowDownwardIcon
                                                                                fontSize="small"
                                                                                color="disabled"
                                                                            />
                                                                        }
                                                                        checkedIcon={
                                                                            <ArrowUpwardIcon
                                                                                fontSize="small"
                                                                                color="disabled"
                                                                            />
                                                                        }
                                                                    />
                                                                </TableCell>
                                                                <TableCell width= '10%' ></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {accountsLog !== undefined &&
                                                                accountsLog.length > 0 &&
                                                                accountsLog.map(
                                                                    (log: AccountsLogProps, key: number) => (
                                                                        <>
                                                                            <TableRow sx={{ backgroundColor: 'white' }}>
                                                                            <TableCell
                                                                                    align="left"
                                                                                    padding="normal"
                                                                                >
                                                                                    <label>
                                                                                        {
                                                                                            <Typography variant="subtitle1">
                                                                                                {log.userFirstName} {log.userLastName}
                                                                                            </Typography>
                                                                                        }
                                                                                    </label>
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    align="left"
                                                                                    padding="normal"
                                                                                >
                                                                                    <ListItemTag nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                                                     label={log.actionType} fontColor={log.actionType === 'LOGIN' ? '#2CA618' : '#ffff'} backgroundColor={log.actionType === 'LOGIN' ?'#d3fbd0' : '#CA3C3D'} />
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Checkbox checkedIcon={<KeyboardArrowUpIcon />} icon={<KeyboardArrowDownIcon />}
                                                                                     onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleLogClick(log,key,e)}  />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            {(tableKey === key && tableKey !== undefined)&&
                                                                            <TableRow>
                                                                                <TableCell>
                                                                                    <Stack direction={'column'} spacing={4}>
                                                                                    <Typography variant='subtitle1'>
                                                                                        Date
                                                                                    </Typography>
                                                                                    <Typography variant='subtitle1'>
                                                                                        Executor
                                                                                    </Typography>
                                                                                    </Stack>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                <Stack direction={'column'} spacing={2}>
                                                                                    <div style={{display:'flex',flexDirection:'column'}}>
                                                                                    <Typography>
                                                                                        {getDateFormat(log.dateInMilliseconds)}
                                                                                    </Typography>
                                                                                    <Typography>
                                                                                        {new Date(log.dateInMilliseconds).toTimeString().split(' ')[0]}
                                                                                    </Typography>
                                                                                    </div>
                                                                                    <Typography>
                                                                                        -
                                                                                    </Typography>
                                                                                </Stack>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            }
                                                                        </>
                                                                    )
                                                                )}
                                                        </TableBody>
                                                    </Table>
                                                </Item>
                                </Grid>
                                <Snackbar
                                    open={snackOpen}
                                    autoHideDuration={6000}
                                    onClose={handleSnackClose}
                                    message={deleteAdminMessage}
                                    action={action}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                />
                                <Item
                                    sx={
                                        md
                                            ? {
                                                position: 'fixed',
                                                top: '100%',
                                                transform: 'translate(-0%,-100%)',
                                                width: '100%',
                                                boxShadow:
                                                    '0px -3px 5px -1px rgba(0, 0, 0, 0.2), 0px -6px 10px rgba(0, 0, 0, 0.14), 0px -1px 18px rgba(0, 0, 0, 0.12)',
                                                borderRadius: 0,
                                                p: 1,
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }
                                            : {}
                                    }
                                    elevation={md ? 2 : 0}
                                >
                                    <TablePagination
                                        component="div"
                                        count={-1}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={10}
                                        rowsPerPageOptions={[10]}
                                        showFirstButton
                                        showLastButton
                                    />
                                </Item>
                                {query !== '' && (
                                    <div
                                        style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%' }}
                                    >
                                        <div style={{ flex: '1 1 0px' }}>
                                            <EmptyState nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                icon={<ManageSearchIcon fontSize={'inherit'} />}
                                                title={`${t('msg_noSearch_results')} ${query}...`}
                                            />
                                        </div>
                                    </div>
                                )}
                            </Grid>
                        </Box>
                    </div>
                ) : (
                    <Box sx={{ flexGrow: 1, marginLeft: '4%', marginRight: '4%', marginTop: 4 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Item elevation={0}>
                                    <Box sx={{ flexGrow: 1, marginLeft: 3, marginRight: 3, pt: 3, pb: 3 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={11.5}>
                                                <Item elevation={0}>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        placeholder={t('lbl_search')}
                                                        value={query}
                                                        onChange={(e) => setQuery(e.target.value)}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIcon />
                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    {query.length !== 0 && (
                                                                        <IconButton>
                                                                            <CancelIcon />
                                                                        </IconButton>
                                                                    )}
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Item>
                                            </Grid>
                                            <Grid item xs={0.5}>
                                                <Item elevation={0}>
                                                    <IconButton onClick={(e) => handleSortClick(e)}>
                                                        <FilterListIcon sx={{ mt: 1 }} />
                                                    </IconButton>
                                                    <SortListMenu setSortListType={setSortListType} anchorEl={anchorEl} open={open} setAnchorEl={setAnchorEl} />
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Item elevation={1}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell width="20%">
                                                                    {'Action'}&ensp;
                                                                    <Checkbox
                                                                        icon={
                                                                            <ArrowDownwardIcon
                                                                                fontSize="small"
                                                                                color="disabled"
                                                                            />
                                                                        }
                                                                        checkedIcon={
                                                                            <ArrowUpwardIcon
                                                                                fontSize="small"
                                                                                color="disabled"
                                                                            />
                                                                        }
                                                                    />
                                                                </TableCell>
                                                                <TableCell width="20%">
                                                                    {'Executor'}&ensp;
                                                                    <Checkbox
                                                                        icon={
                                                                            <ArrowDownwardIcon
                                                                                fontSize="small"
                                                                                color="disabled"
                                                                            />
                                                                        }
                                                                        checkedIcon={
                                                                            <ArrowUpwardIcon
                                                                                fontSize="small"
                                                                                color="disabled"
                                                                            />
                                                                        }
                                                                    />
                                                                </TableCell>
                                                                <TableCell width="25%">
                                                                    {'User'}&ensp;
                                                                    <Checkbox
                                                                        icon={
                                                                            <ArrowDownwardIcon
                                                                                fontSize="small"
                                                                                color="disabled"
                                                                            />
                                                                        }
                                                                        checkedIcon={
                                                                            <ArrowUpwardIcon
                                                                                fontSize="small"
                                                                                color="disabled"
                                                                            />
                                                                        }
                                                                    />
                                                                </TableCell>
                                                                <TableCell width={'20%'}></TableCell>
                                                                <TableCell width="10%" align='left'>
                                                                    {'Date'}&ensp;
                                                                    <Checkbox
                                                                        icon={
                                                                            <ArrowDownwardIcon
                                                                                fontSize="small"
                                                                                color="disabled"
                                                                            />
                                                                        }
                                                                        checkedIcon={
                                                                            <ArrowUpwardIcon
                                                                                fontSize="small"
                                                                                color="disabled"
                                                                            />
                                                                        }
                                                                    />
                                                                </TableCell>
                                                                <TableCell width= '5%' ></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {accountsLog !== undefined &&
                                                                accountsLog.length > 0 &&
                                                                accountsLog.map(
                                                                    (log: AccountsLogProps, key: number) => (
                                                                        <>
                                                                            <TableRow sx={{ backgroundColor: 'white' }}>
                                                                                <TableCell
                                                                                    align="left"
                                                                                    padding="normal"
                                                                                >
                                                                                    <ListItemTag nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                                                     label={log.actionType} fontColor={log.actionType === 'LOGIN' ? '#2CA618' : '#ffff'} backgroundColor={log.actionType === 'LOGIN' ?'#d3fbd0' : '#CA3C3D'} />
                                                                                </TableCell>

                                                                                <TableCell
                                                                                    align="left"
                                                                                    padding="normal"
                                                                                >
                                                                                    <label>
                                                                                        {
                                                                                            <Typography variant="body1">
                                                                                                -
                                                                                            </Typography>
                                                                                        }
                                                                                    </label>
                                                                                </TableCell>

                                                                                <TableCell
                                                                                    align="left"
                                                                                    padding="normal"
                                                                                >
                                                                                    <label>
                                                                                        {
                                                                                            <Typography variant="body1">
                                                                                                {log.userFirstName} {log.userLastName}
                                                                                            </Typography>
                                                                                        }
                                                                                    </label>
                                                                                </TableCell>
                                                                                <TableCell></TableCell>
                                                                                <TableCell
                                                                                    align="left"
                                                                                    padding="normal"
                                                                                >
                                                                                    <div style={{display:'flex',flexDirection:'column'}}>
                                                                                    <Typography>
                                                                                        {getDateFormat(log.dateInMilliseconds)}
                                                                                    </Typography>
                                                                                    <Typography>
                                                                                        {new Date(log.dateInMilliseconds).toTimeString().split(' ')[0]}
                                                                                    </Typography>
                                                                                    </div>
                                                                                </TableCell>
                                                                                <TableCell></TableCell>
                                                                            </TableRow>
                                                                        </>
                                                                    )
                                                                )}
                                                        </TableBody>
                                                    </Table>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Item elevation={0}>
                                                    <TablePagination
                                                        component="div"
                                                        count={-1}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        rowsPerPage={10}
                                                        rowsPerPageOptions={[10]}
                                                        showFirstButton
                                                        showLastButton
                                                        nextIconButtonProps={{disabled:accountsLog.length < 10}}
                                                    />
                                                </Item>
                                            </Grid>
                                            {query !== '' && (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        height: '100%',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <div style={{ flex: '1 1 0px' }}>
                                                        <EmptyState nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                            icon={<ManageSearchIcon fontSize={'inherit'} />}
                                                            title={`${t('msg_noSearch_results')} ${query}...`}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </Grid>
                                    </Box>
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>
                )}
                </div>
            </div>
        </>
    );
}
