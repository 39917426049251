/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { NewEstateActionTypes } from './../constants/newEstateActionTypes';
import { EstateProps,CurrentUserProps } from './../../components/ListAndGridView/panelEstateTypes';
import { Dispatch } from 'react';
import { DispatchTypesProps } from '../dispatchActionTypes/dispatchAndThunkActionTypes';
import { REACT_APP_BASE_URL } from '../../entities/Endpoints';

const setNewEState = (newEstateID: number): any => {
    return {
        type: NewEstateActionTypes.NEW_ESTATE,
        payload: newEstateID
    };
};

export function postNewEstate(groupId: number,body: any,deviceList: any){
    return async function (dispatch:Dispatch<DispatchTypesProps>):Promise<any>{
       await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/group/${groupId}`,{
      method:'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body:JSON.stringify(body)
    })
    .then((res) => res.json())
    .then(async(data) => {
      const id = data.ID !== undefined ? data.ID : undefined;
      dispatch(setNewEState(id))
      
      
      if(id !== undefined){
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/assign/${id}`,{
          method:'PUT',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body:JSON.stringify(deviceList)
        })
      }
    })
    }
}