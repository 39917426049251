/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/no-shadow */

import React from 'react';
import { EmptyState, InfoListItem, ListItemTag } from '@brightlayer-ui/react-components';
import {
    Button,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CompletedReportDetailsModal from '../ServiceReportsModals/CompletedServiceReportModal';
import PdfReportModal from '../ServiceReportsModals/PdfReportModal';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { fetchCompletedReportsListForPanel } from '../../redux/actions/remoteServicingActions';
import { RemoteServiceReportProps } from '../RemoteServicingModal/remoteServicingTypeProps';
import DeleteCompletedServiceReportModal from '../RemoteServicingTab/DeleteCompletedServiceReportModal'
import FolderIcon from '@mui/icons-material/Folder';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import { useTranslation } from 'react-i18next';
import { getAMorPM, getDate, getDateFormat, getHours, getMinutes, getMonth, getTimeFormat, getYear } from '../Timestamp/timestampCal';
import Loader from '../CircularLoader/Loader';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),

    color: theme.palette.text.secondary,
}));

export default function CompletedServicesReports(): JSX.Element {
    const theme = useTheme();
    const dispatch = useTypedDispatch();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [openReport, setOpenReport] = React.useState(false)
    const [openPdfReport, setOpenPdfReport] = React.useState(false)
    const [openDeleteReport, setOpenDeleteReport] = React.useState(false)
    const [reportId, setReportId] = React.useState(0)
    const [counter, setCounter] = React.useState(0)
    const [openPdfReportBrowser, setOpenPdfReportBrowser] = React.useState(false)
    const [report, setReport] = React.useState<any>({})
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [dataLoaded, setDataLoaded] = React.useState(false);
    const { t } = useTranslation();
    const { specificPanel } = useSelector((state: any) => state.specificPanelAndEstateReducer);
    const { remoteServiceAllReports, deleteReportForPanelStatus } = useSelector((state: any) => state.remoteServicingReducer);

    React.useEffect(() => {
        if (counter !== 0) {
            if (deleteReportForPanelStatus === 200) {
                setMessage(`${t('msg_svcReport_deleted')}`)
                setDataLoaded(false);
                dispatch(fetchCompletedReportsListForPanel(specificPanel?.panelId))
                setDataLoaded(true);
            } else {
                setMessage(`${t('lbl_errorOccurred')}`)
            }
            setSnackOpen(true)
        } else {
            setDataLoaded(false);
            dispatch(fetchCompletedReportsListForPanel(specificPanel?.panelId))
            setDataLoaded(true);
        }
       
    }, [counter])

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackClose}>
            {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>,service:RemoteServiceReportProps) => {
        setReportId(service?.id)
        setReport(service)
        setAnchorEl(event.currentTarget);
    };
    const handleReportModal = (service: any) => {
        setReport(service)
        setOpenReport(true)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        setOpenDeleteReport(true)
        setAnchorEl(null);
    }

    const handleView = () => {
        setOpenPdfReportBrowser(true)
        setAnchorEl(null);
    };

    return (
        <div>
           
            {md ?
                <>
                    <Table>
                        <TableBody>
                            {remoteServiceAllReports?.length !== 0 && remoteServiceAllReports !== undefined && remoteServiceAllReports?.map((service: RemoteServiceReportProps, key: number) => (
                                <>
                                    <TableRow sx={{ backgroundColor: 'white' }}>
                                        <TableCell align="left" padding="normal">
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant="body2">{service?.id}</Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell align="left" padding="normal">
                                            <ListItemTag nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                label={service?.analysis}
                                                backgroundColor={service?.analysis === 'red' ? '#CA3C3D' : service?.analysis === 'green' ? '#39B620' : '#E57F0A'}
                                                fontColor={'#FFFFFF'}
                                            />
                                        </TableCell>
                                        <TableCell align="right" padding="normal">
                                            <IconButton onClick={() => handleReportModal(service)} sx={{ mt: -1 }} size="small">
                                                <ChevronRightIcon />
                                            </IconButton>
                                        </TableCell>
                                        <CompletedReportDetailsModal page={'remote-servicing-tab'} setOpenPdfReport={setOpenPdfReportBrowser} report={report} open={openReport} setOpen={setOpenReport} counter={counter} setCounter={setCounter} />
                                        {/* <PdfReportModal page={'remote-servicing-tab'} specificPanel={specificPanel} report={report} open={openPdfReport} setOpen={setOpenPdfReport} /> */}
                                    </TableRow>
                                </>
                            ))}
                        </TableBody>
                    </Table>
                </>
                : <Table>
                    {remoteServiceAllReports.length !== 0 &&
                        <TableHead>
                            <TableRow>
                                <TableCell width="30%">
                                {t('lbl_report_no')}&ensp;
                                    <ArrowDownwardIcon fontSize="small" color="disabled" />
                                </TableCell>
                                <TableCell width="30%">
                                {t('lbl_cmpltd_date')}&ensp;
                                    <ArrowDownwardIcon fontSize="small" color="disabled" />
                                </TableCell>
                                <TableCell width="30%">
                                {t('lbl_cmpltd_Classification')}&ensp;
                                    <ArrowDownwardIcon fontSize="small" color="disabled" />
                                </TableCell>
                                <TableCell width="10%"></TableCell>
                            </TableRow>
                        </TableHead>
                    }
                    <TableBody>
                        {remoteServiceAllReports?.length !== 0 && remoteServiceAllReports !== undefined && remoteServiceAllReports?.map((service: RemoteServiceReportProps, key: number) => (
                            <>
                                <TableRow sx={{ backgroundColor: 'white' }}>
                                    <TableCell align="left" padding="normal">
                                        <label>{<Typography variant="body1">{service?.id}</Typography>}</label>
                                    </TableCell>

                                    <TableCell align="left" padding="normal">
                                        <label>{<Typography variant="body1">
                                            {`${getDateFormat(Number(service?.sysTimestampEnd) * 1000)} , ${getTimeFormat(Number(service?.sysTimestampEnd) * 1000)}`} UTC
                                        </Typography>}</label>
                                    </TableCell>

                                    <TableCell align="left" padding="normal">
                                        <Typography
                                            sx={{ color: service?.analysis === 'red' ? '#CA3C3D' : service?.analysis === 'green' ? '#39B620' : service?.analysis === null ? 'grey': service?.analysis === 'initial' ? '#000000' : '#E57F0A' }}
                                            variant="body1"
                                        >
                                            {service?.analysis === null? 'NULL' : service?.analysis.toUpperCase()}
                                        </Typography>
                                    </TableCell>

                                    <TableCell align="right" padding="normal">
                                        <IconButton onClick={(e) => handleClick(e,service)} sx={{ mt: -1 }} size="small">
                                            <MoreVertIcon />
                                        </IconButton>
                                        <div>
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={anchorEl}
                                                sx={{ width: '100%' }}
                                                open={open}
                                                onClose={handleClose}
                                                MenuListProps={{
                                                    'aria-labelledby': 'basic-button',
                                                }}
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                                transformOrigin={{ vertical: 'center', horizontal: 'center' }}
                                            >
                                                <MenuItem sx={{ width: 200 }} onClick={handleView}>View</MenuItem>
                                                <MenuItem onClick={handleDelete}>Delete</MenuItem>
                                            </Menu>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </>
                        ))}
                    </TableBody>
                </Table>}
            {remoteServiceAllReports.length === 0 &&
                <div>
                    <Grid item xs={12}>
                        <Item elevation={0}>
                            <EmptyState nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                icon={
                                    <FolderIcon
                                        sx={{ color: '#727E84' }}
                                        fontSize="large"
                                    />
                                }
                                description={[
                                    <Typography
                                        sx={{
                                            color: '#6A767C',
                                            fontWeight: 600,
                                            fontSize: 14,
                                        }}
                                        key={0}
                                        color="action"
                                    >
                                        {t('lbl_noCmpltd_svcReports')}
                                    </Typography>,
                                ]}
                                title={undefined}
                            />
                        </Item>
                    </Grid>
                </div>
            }
            <Snackbar
                open={snackOpen}
                autoHideDuration={6000}
                onClose={handleSnackClose}
                message={message}
                action={action}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
            <PdfReportModal page={'remote-servicing-tab'} specificPanel={specificPanel} report={report} open={openPdfReportBrowser} setOpen={setOpenPdfReportBrowser} />
            <DeleteCompletedServiceReportModal selectedReportId={reportId} openDeleteModal={openDeleteReport} setOpenDeleteModal={setOpenDeleteReport} counter={counter} setCounter={setCounter} open={openReport} setOpen={setOpenReport}></DeleteCompletedServiceReportModal>
        </div>
    );
}
