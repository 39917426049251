/* eslint-disable arrow-body-style */
import { DispatchTypesProps } from '../dispatchActionTypes/dispatchAndThunkActionTypes';
import { Dispatch } from 'react';
import { InstallerActionTypes } from '../constants/installersActionTypes';
import { InstallersArrayProps } from '../../components/Installers/InstallersTypes';
import { PanelArrayProps } from '../../components/ListAndGridView/panelEstateTypes';
import { AccountUsersArrayProps, AccountUsersProps } from '../../components/AccountUsers/AccountUsersTypes';
import { REACT_APP_BASE_URL } from '../../entities/Endpoints';
import secureLocalStorage  from  "react-secure-storage";

export const setInstallers = (installers: InstallersArrayProps): any => {
    return {
        type: InstallerActionTypes.SET_INSTALLERS,
        payload: installers,
    };
}
export const setInstallerDetails = (installerDetails:any):any => {
    return {
        type: InstallerActionTypes.SET_INSTALLER_DETAILS,
        payload: installerDetails,
    };
}
export const setInstallerPanels = (totalPanels:PanelArrayProps):any => {
    return {
        type: InstallerActionTypes.SET_INSTALLER_PANELS,
        payload: totalPanels,
    };
}
export const setInstallerUsers = (installerUsersList:AccountUsersArrayProps):any => {
    return {
        type: InstallerActionTypes.SET_INSTALLER_USERS,
        payload: installerUsersList,
    };
}
export const setActivateOrDeactivateInstallerStatus = (activateOrDeactivateInstallerStatus:number):any => {
    return {
        type: InstallerActionTypes.SET_ACTIVATE_DEACTIVATE_INSTALLER,
        payload: activateOrDeactivateInstallerStatus,
    };
}
export const setRemoveInstallerStatus = (removeInstallerStatus:number):any => {
    return {
        type: InstallerActionTypes.SET_REMOVE_INSTALLER,
        payload: removeInstallerStatus,
    };
}
export const setRemoveInstallerState = (removeInstallerState:boolean):any => {
    return {
        type: InstallerActionTypes.SET_REMOVE_INSTALLER_STATE,
        payload: removeInstallerState,
    };
}
export const setInstallersLength = (installersLength:number):any => {
    return {
        type: InstallerActionTypes.SET_INSTALLERS_LENGTH,
        payload: installersLength,
    };
}
export const setEditInstallerDetailsStatus = (editInstallerStatus:number):any => {
    return {
        type: InstallerActionTypes.SET_EDIT_INSTALLER_STATUS,
        payload: editInstallerStatus,
    };
}

export function fetchInstallers(start: number) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(
            `${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/installers?limit=10&search=&start=${start}`,
            {
                method: 'GET',
                credentials: 'include',
            }
        )
            .then((res) => res.json())
            .then((data) => {
                dispatch(setInstallers(data));
            });
    }}

    export function fetchInstallersLength() {
        return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
            await fetch(
                `${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/installers?limit=5000&search=&start=0`,
                {
                    method: 'GET',
                    credentials: 'include',
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    dispatch(setInstallersLength(data.length));
                });
        }
    }

export function fetchInstallerUsers(id:any,start:number):any {
    let notificationEnableUsersArray: any;
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${id}`,{
            method: 'GET',
            credentials: 'include',
        }
    )
            .then((res) => res.json())
            .then(async (installer) => {
                await fetch(
                    `${REACT_APP_BASE_URL}/m2m-web-admin/rest/assignUserToInstaller/${installer.ID}`,
                    {
                        method: 'GET',
                        credentials: 'include',
                    }
                )
                    .then((res) => res.json())
                    .then((data1) => {
                        notificationEnableUsersArray = data1;
                    });

                await fetch(
                    `${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/bygroup/${installer.groupId}?filter=&limit=10&start=${start}`,
                    {
                        method: 'GET',
                        credentials: 'include',
                    }
                )
                    .then((res) => res.json())
                    .then((data) => {
                        data.forEach((element: AccountUsersProps) => {
                            element.notificationEnabledUserArray = notificationEnableUsersArray.some(
                                (item: AccountUsersProps) => element.ID === item.ID
                            );
                        });
                        dispatch(setInstallerUsers(data));
                    });
            });
    };}
    
export function fetchInstallerDetails(id:any) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${id}`,{
                method: 'GET',
                credentials: 'include',
            }
        )
            .then((res) => res.json())
            .then((data) => {
                dispatch(setInstallerDetails(data));
            });
    }}

    export function fetchInstallerPanels(id:any) {
        return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
            await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${id}`,{
                method: 'GET',
                credentials: 'include',
            }
        )
            .then((res) => res.json())
            .then(async(data) => {
            await fetch(
                `${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/recursiveDevice/from/${data.groupId}`,
                {
                    method: 'GET',
                    credentials: 'include',
                }
            )
                .then((res) => res.json())
                .then((panels) => {
                    dispatch(setInstallerPanels(panels));
                });
            });
        }}

        export function fetchActivateOrDeactivateInstaller(body:any,id:number,activate:string){
            return async function (dispatch:Dispatch<DispatchTypesProps>):Promise<any> {
                await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${id}/activate?active=${activate}`, {
                                method: 'PUT',
                                credentials: 'include',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(body)
                            }).then( (res) => {
                                 dispatch((setActivateOrDeactivateInstallerStatus(res.status)))
                            })
            };
        };
        export function fetchRemoveInstaller(id:number){
            return async function (dispatch:Dispatch<DispatchTypesProps>):Promise<any> {
                await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${id}`, {
                                method: 'DELETE',
                                credentials: 'include',
                                headers: {
                                    'Content-Type': 'application/json',
                                }
                            }).then( (res) => {
                                 dispatch((setRemoveInstallerStatus(res.status)))
                            })
            };
        };

        export function setRemoveInstaller(state:boolean){
             return function (dispatch: Dispatch<DispatchTypesProps>): any {
                dispatch(setRemoveInstallerState(state));
            };
        };

        export function fetchEditInstallerDetails(body: any) {
            return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
                await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${body.ID}`, {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body)
                }).then((res) => {
                    if(res.status === 200 && res !== undefined){
                        secureLocalStorage.setItem('installer',body)
                         dispatch((setEditInstallerDetailsStatus(res.status)))
                     }
                     else{
                          dispatch((setEditInstallerDetailsStatus(res.status)))
                     }
                })
            };
        };
