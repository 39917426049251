export const authWorkflowEn =  {
    translation: {
        LOGIN: {
            INCORRECT_CREDENTIALS: 'Incorrect Email or Password',
            INVALID_CREDENTIALS: 'Your username/password combination is not recognized.',
            GENERIC_ERROR: 'Your request could not be processed at this time.',
        },
        HEADER: {
            FORGOT_PASSWORD: 'Forgot Password',
        },
        CHANGE_PASSWORD: {
            PASSWORD_INFO:
                'Please select a password. Make sure that your password meets the necessary complexity requirements outlined below.',
        },
        FORGOT_PASSWORD: {
            INSTRUCTIONS_ALT:
                'Please enter the account email associated with the account.<br/><br/>' +
                'If this email has an account with Eaton, you will receive a response within <1>{{time}}</1>.',
        },
        CONTACT_SUPPORT: {
            GENERAL_QUESTIONS: '',
            SUPPORT_MESSAGE: 'Installers questions, feedback, or support please email us at admin@eatonsecureconnect.com or call 01594 541978.<br/><br/>',
            EMERGENCY_SUPPORT: 'For end users, please contact your installer for support.',
            TECHNICAL_ASSISTANCE: '',
        },
    },
}
// Common Keys shared by Auth and Registration workflows
export const commonWorkflowEn =  {
    translation: {
        ACTIONS: {
            FINISH: 'Finish',
            NEXT: 'Next',
            BACK: 'Back',
            CREATE_ACCOUNT: 'Create Account',
            OKAY: 'Okay',
            CANCEL: 'Cancel',
            CONTINUE: 'Continue',
            DONE: 'Done',
            LOG_IN: 'Log In',
            LOG_OUT: 'Log Out',
            RESEND: 'Send Again',
            REMEMBER: 'Remember Me',
            SUBMIT: 'Submit',
        },
        LABELS: {
            EMAIL: 'Email Address',
            PASSWORD: 'Password',
            FORGOT_PASSWORD: 'Forgot your password?',
            NEED_ACCOUNT: 'Need an account?',
        },
        MESSAGES: {
            EMAIL_SENT: 'Email Sent',
            WELCOME: 'Welcome',
            REQUEST_ERROR: 'Sorry, there was a problem sending your request.',
            ERROR: 'Error!',
            EMAIL_ENTRY_ERROR: 'Please enter a valid email',
            CONTACT: 'Contact an Eaton Support Representative',
            LOADING: 'Loading...',
            PASSWORD_REQUIRED_ERROR: 'Password Required',
        },
        FORMS: {
            FIRST_NAME: 'First Name',
            LAST_NAME: 'Last Name',
            PASSWORD: 'Password',
            CONFIRM_PASSWORD: 'Confirm Password',
        },
        PASSWORD_REQUIREMENTS: {
            LENGTH: '8-16 Characters',
            NUMBERS: 'One number',
            UPPER: 'One uppercase letter',
            LOWER: 'One lowercase letter',
            SPECIAL: 'One special character',
        },
    },
}
// Registration Workflow Keys
export const registrationWorkflowEn = {
    translation: {
        REGISTRATION: {
            EULA: {
                LOADING: 'Loading End User License Agreement...',
                AGREE_TERMS: 'I have read and agree to the Terms & Conditions9',
            },
            STEPS: {
                CREATE_ACCOUNT: 'Create an Account',
                LICENSE: 'License Agreement',
                VERIFY_EMAIL: 'Verify Email',
                PASSWORD: 'Create Password',
                ACCOUNT_DETAILS: 'Account Details',
                COMPLETE: 'Account Created!',
            },
            INSTRUCTIONS: {
                ACCOUNT_DETAILS: 'Enter your details below to complete account creation.',
            },
            SELF_REGISTRATION: {
                INSTRUCTIONS: `To register for an Eaton account, enter the required information below. You will need to verify your email address to continue.`,
                VERIFY_EMAIL: {
                    RESEND: 'Resend Verification Email',
                    VERIFICATION: 'Verification Code',
                },
            },
        },
    },
};