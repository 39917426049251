/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Divider, IconButton, MobileStepper, Paper, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import Snackbar from '@mui/material/Snackbar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useTypedDispatch } from '../../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { useSelector } from 'react-redux';
import { addUser, fetchUserGroups } from '../../../redux/actions/accountUsersActions';
import { useTranslation, Trans } from 'react-i18next';
import { AdministratorsProps } from '../AdministratorsTypes';
import AddAdminForm from './AddAdminForm';
import { fetchAddAdministrator, fetchAdminsLength } from '../../../redux/actions/administratorsActions';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    backgroundColor: 'background.paper',
    boxShadow: 20,
    p: 0,
};

type AddAdminModalProps = {
    count : number
    setCount: (count:number) => void
    open : boolean
    setOpen : (open:boolean) => void
}

export default function AddAdminModal({ count, setCount, open, setOpen }:AddAdminModalProps ): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useTypedDispatch();
    //const { currentUser } = useSelector((state: any) => state.userReducer);
    const { administrators, addAdminStatus } = useSelector((state: any) => state.administratorReducer);
    const [activeStep, setActiveStep] = React.useState(0);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [availableGroups, setAvailableGroups] = React.useState<number[]>([]);
    const [firstName, setFirstName] = React.useState<string>('');
    const [lastName, setLastName] = React.useState<string>('');
    const [login, setLogin] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [repeatPassword, setRepeatPassword] = React.useState<string>('');
    const [language, setLanguage] = React.useState('')
    const [userType, setUserType] = React.useState<string>('');
    const [message, setMessage] = React.useState<string>('');
    const [status, setStatus] = React.useState(false);
    const { t } = useTranslation();

    const isValidEmail = (email: string) => {
        const isValid = /\S+@\S+\.\S+/.test(email);
        return isValid
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const steps = [
        {
            component: (
                <AddAdminForm
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    login={login}
                    setLogin={setLogin}
                    password={password}
                    setPassword={setPassword}
                    repeatPassword={repeatPassword}
                    setRepeatPassword={setRepeatPassword}
                    userType={userType}
                    setUserType={setUserType}
                    language={language}
                    setLanguage={setLanguage}
                />
            ),
        },
    ];

    React.useEffect(() => {
        if (status === true) {
            if (addAdminStatus === 200) {
                setCount(count + 1);
                setMessage(`Administrator added successfully`);
            } else if (addAdminStatus === 500) {
                setMessage(`${t('msg_internal_error')}`);
            } else if (addAdminStatus === 400) {
                setMessage(`${t('lbl_unauthorizedUser')}`);
            } else {
                setMessage(`${t('lbl_someError')}`);
            }
            setSnackOpen(true);
            setStatus(false);
        }
    }, [status]);

    const handleClose = () => {
        setFirstName('');
        setLastName('');
        setLogin('');
        setUserType('');
        setPassword('');
        setRepeatPassword('')
        setLanguage('')
        setOpen(false);
        setActiveStep(0);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSave = async() => {
        if (administrators.some((item: AdministratorsProps) => item.login === login)) {
            setMessage(`${t('msg_usrExist')}`);
        } else {
            const body = {
                firstName: firstName,
                lastName: lastName,
                locale: language,
                login: login,
                password: password,
                userType: userType,
            };
            await dispatch(fetchAddAdministrator(body));
            await dispatch(fetchAdminsLength());
            setStatus(true);
        }
        setFirstName('');
        setLastName('')
        setLogin('')
        setPassword('')
        setRepeatPassword('')
        setUserType('');
        setLanguage('')
        setActiveStep(0);
        setOpen(false);
    };

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackClose}>
                {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    return (
        <div>
            {md ? (
                <>
                    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'background.paper',
                                boxShadow: 20,
                                p: 0,
                            }}
                        >
                            <Paper elevation={0} sx={{ pb: 0 }}>
                                <Paper
                                    elevation={4}
                                    sx={{
                                        paddingTop: 2,
                                        paddingBottom: 1,
                                        paddingLeft: 2,
                                        paddingRight: 2,
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <IconButton onClick={handleClose}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                    &ensp;&ensp;
                                    <Typography sx={{ pt: 0.5 }} variant="h6">
                                        Add Administrator
                                    </Typography>
                                </Paper>
                                <Divider />
                                {steps[activeStep].component}
                            </Paper>
                            <Divider />
                            <Paper sx={{
                                position: 'absolute' as 'absolute',
                                top: '93vh',
                                zIndex: 1000,
                                transform: 'translateY(-100%)',
                                width: '100%',
                            }}>
                                <MobileStepper
                                    variant="dots"
                                    steps={0}
                                    position="static"
                                    activeStep={activeStep}
                                    sx={{
                                        maxWidth: '100%',
                                        flexGrow: 1,
                                        paddingTop: 2,
                                        paddingBottom: 4,
                                        paddingLeft: 3,
                                        paddingRight: 3,
                                        backgroundColor: 'inherit',
                                    }}
                                    nextButton={
                                        <Button
                                            size="medium"
                                            variant="contained"
                                            disabled={
                                                firstName === '' ||
                                                lastName === '' ||
                                                login === '' ||
                                                password === '' ||
                                                repeatPassword === '' ||
                                                userType === '' ||
                                                password !== repeatPassword ||
                                                (!isValidEmail(login))
                                            }
                                            onClick={handleSave}
                                        >
                                            {t('btn_save')}
                                        </Button>

                                    }
                                    backButton={
                                        <Button size="small" variant="outlined" onClick={handleClose}>
                                            {t('btn_cancel')}
                                        </Button>
                                    }
                                />
                            </Paper>
                        </Box>
                    </Modal>
                    <Snackbar
                        open={snackOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackClose}
                        message={message}
                        action={action}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    />
                </>
            ) : (
                <>
                    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={style}>
                            <Paper
                                elevation={0}
                                sx={{ paddingTop: 2, paddingBottom: 3, paddingLeft: 3, paddingRight: 2 }}
                            >
                                <Typography variant="h6">Add Administrator</Typography>
                            </Paper>
                            <Divider />
                            <Paper elevation={0} sx={{}}>
                                {steps[activeStep].component}
                            </Paper>
                            <Divider />
                            <MobileStepper
                                variant="dots"
                                steps={0}
                                position="static"
                                activeStep={activeStep}
                                sx={{
                                    maxWidth: 500,
                                    flexGrow: 1,
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    paddingLeft: 3,
                                    paddingRight: 3,
                                    backgroundColor: 'inherit',
                                }}
                                nextButton={

                                    <Button
                                        size="medium"
                                        variant="contained"
                                        disabled={
                                            firstName === '' ||
                                            lastName === '' ||
                                            login === '' ||
                                            password === '' ||
                                            repeatPassword === '' ||
                                            userType === '' ||
                                            password !== repeatPassword ||
                                            (!isValidEmail(login))
                                        }
                                        onClick={handleSave}
                                    >
                                        {t('btn_save')}
                                    </Button>

                                }
                                backButton={
                                    <Button size="small" variant="outlined" onClick={handleClose}>
                                        {t('btn_cancel')}
                                    </Button>
                                }
                            />
                        </Box>
                    </Modal>
                    <Snackbar
                        open={snackOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackClose}
                        message={message}
                        action={action}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    />
                </>
            )}
        </div>
    );
}
