/* eslint-disable @typescript-eslint/naming-convention */
const resources = {
    "menu_oveview": "Panoramica",
    "menu_endUserInfo": "Informazioni sull'utente finale",
    "menu_accntUsrs": "Utenti dell'account",
    "menu_serviceReports": "Rapporti di servizio",
    "menu_settings": "Impostazioni",
    "menu_help": "Informazione",
    "lbl_cloudId": "ID nuvola",
    "lbl_logOut": "Disconnettersi",
    "lbl_contactSupport": "CONTATTA IL SUPPORTO",
    "lbl_estates": "Tenute",
    "lbl_ESTATES": "TENUTA",
    "lbl_panels": "Pannelli",
    "lbl_PANELS": "PANNELLI",
    "lbl_connected": "Collegata",
    "lbl_disconnected": "Disconnessa",
    "lbl_manageEstates": "Gestisci le proprietà",
    "lbl_newEstate": "Nuova tenuta",
    "lbl_estate": "Proprietà",
    "lbl_search": "Ricerca…",
    "lbl_manage_estateAndpanels": "Gestisci tenute e pannelli",
    "btn_cancel": "Annulla",
    "btn_save": "Salva",
    "msg_panels_moved": "I pannelli sono stati spostati correttamente",
    "lbl_name": "Nome",
    "lbl_description": "Descrizione",
    "btn_next": "Prossima",
    "lbl_contact_name": "Nome del contatto",
    "lbl_contact_phNo": "numero di contatto telefonico",
    "lbl_country": "Paese",
    "lbl_address": "Indirizzo",
    "lbl_postcode": "Codice postale",
    "lbl_selectedPanels": "Pannelli selezionati",
    "lbl_close": "Vicina",
    "msg_newEstate_created": "Nuova proprietà creata con successo",
    "msg_estateExists": "La tenuta con questo nome esiste già",
    "tooltip_ARC_Access": "Accesso ARC",
    "tooltip_usrEmail_alerts": "Avvisi e-mail utente",
    "tooltip_usrAccess": "Accesso utente",
    "tooltip_installerEmail_alerts": "Avvisi e-mail dell'installatore",
    "tooltip_remoteServicing": "Assistenza remota",
    "lbl_allPanel": "Tutti i pannelli",
    "lbl_favorites": "Preferite",
    "lbl_ARC_enabled": "ARC abilitato",
    "lbl_end_usrEmail_enabled": "E-mail dell'utente finale abilitata",
    "lbl_end_usrAccess_disabled": "Accesso utente finale disattivato",
    "lbl_installer_emailAlerts_enabled": "Avvisi e-mail dell'installatore abilitati",
    "lbl_remoteServicing_enabled": "Assistenza remota abilitata",
    "msg_no_panels_found": "Nessun pannello disponibile per i filtri di cui sopra",
    "lbl_move_panel": "Sposta pannello",
    "lbl_delete_panel": "Elimina pannello",
    "lbl_delete": "Eliminare",
    "lbl_delete_estate": "Elimina proprietà",
    "msg_delete_estate": "Sei sicuro di voler eliminare l'immobile",
    "msg_no_action_revert": "Questa azione non può essere annullata.",
    "msg_estate_deleted": "Tenuta eliminata con successo",
    "msg_deleteEstate_error": "Erreur lors de la soppressione del dominio",
    "msg_noSearch_results": "Nessun risultato corrispondente",
    "msg_deletePanel": "Sei sicuro di voler eliminare il pannello",
    "msg_deletePanel_error": "Errore nell'eliminazione dell'immobile",
    "msg_panelDeleted": "Pannello eliminato con successo",
    "lbl_viewEstate": "Visualizza tenuta",
    "lbl_goToPanel": "Vai al pannello",
    "lbl_subEstate": "Sub-proprietà",
    "msg_noEstatesConfigured": "Nessuna proprietà configurata",
    "msg_noPanelsConnected": "Nessun pannello collegato",
    "tab_notifications": "STORIA DEL PANNELLO",
    "tab_overview": "PANORAMICA",
    "tab_panelLog": "REGISTRO DEL PANNELLO",
    "tab_ARC_reporting": "SEGNALAZIONE DELL'ARCO",
    "tab_connect": "COLLEGARE",
    "tab_keypad": "TASTIERINO",
    "tab_remoteServicing": "ASSISTENZA REMOTA",
    "tab_mobileApp_usrs": "UTENTI DI APP PER DISPOSITIVI MOBILI",
    "lbl_mobAppUsrs": "Utenti di applicazioni mobili",
    "lbl_summary": "Riepilogo",
    "lbl_status": "Stato",
    "lbl_panelId": "ID pannello",
    "lbl_macAddress": "Indirizzo MAC",
    "lbl_firmware": "firmware",
    "msg_panelIdCopied": "PanelID copiato negli appunti",
    "lbl_panelAccess": "Accesso al pannello",
    "lbl_enabled": "Abilitata",
    "lbl_disabled": "Disabilitata",
    "lbl_connectionStats": "Statistiche di connessione",
    "lbl_totalTime": "Tempo totale (ore)",
    "lbl_connectedTime": "Connesso (ore)",
    "lbl_disconnectedTime": "Disconnesso (ore)",
    "lbl_connectedPercent": "Collegata(%)",
    "lbl_siteDetails": "Dettagli del sito",
    "lbl_cloudReference": "Riferimento nuvola",
    "lbl_siteAddress": "Indirizzo del sito",
    "lbl_optional": "Opzionale",
    "lbl_somethingWrong": "Qualcosa è andato storto",
    "msg_siteDetails_edited": "Dettagli del sito modificati correttamente",
    "lbl_ARCProvider": "Fornitore ARC",
    "lbl_ARCName": "Nome dell'ARCO",
    "lbl_accntNo": "Numero di conto",
    "lbl_IP_version": "Versione SIA-IP",
    "lbl_primaryIP": "IP primario",
    "lbl_secondaryIP": "IP secondario",
    "lbl_primaryPort": "Porto primario",
    "lbl_secondaryPort": "Porto secondario",
    "lbl_encryption": "Crittografia",
    "option_no_encryption": "NESSUNA CRITTOGRAFIA",
    "lbl_report_email": "E-mail di segnalazione",
    "msg_ARC_edited": "Accesso ARC modificato correttamente",
    "msg_ARC_enabled": "Accesso ARC abilitato correttamente",
    "msg_ARC_updated": "Accesso ARC abilitato e aggiornato",
    "btn_back": "Indietro",
    "msg_ARC_disabled": "Accesso ARC disattivato",
    "msg_error": "Errore",
    "lbl_dateOfService": "Data del prossimo servizio",
    "lbl_serviceFrquency": "Frequenza del servizio",
    "option_svc_frqncy_1": "Mensile",
    "option_svc_frqncy_2": "6 mensili",
    "msg_remote_srvcng_enable": "Assistenza remota abilitata correttamente",
    "msg_remote_srvcng_disabled": "Assistenza remota disabilitata correttamente",
    "msg_email_alert_enabled": "Avviso e-mail del programma di installazione abilitato correttamente",
    "msg_email_alert_edited": "Avviso e-mail dell'installatore modificato correttamente",
    "lbl_email_alert_disable": "Disabilita gli avvisi e-mail dell'installatore",
    "msg_email_alert_disable": "Sei sicuro di voler disabilitare gli avvisi e-mail dell'installatore?",
    "btn_disable": "disattivare",
    "msg_emailAlert_disabled": "Avviso e-mail del programma di installazione disabilitato correttamente",
    "lbl_enable_usrAccess": "Abilita l'accesso utente",
    "lbl_disable_usrAccess": "Disabilita l'accesso utente",
    "msg_enable_usrAccess": "Sei sicuro di voler abilitare l'accesso utente?",
    "btn_enable": "Abilitare",
    "msg_usrAccess_enabled": "Accesso utente abilitato correttamente",
    "msg_usrAccess_disabled": "Accesso utente disattivato correttamente",
    "msg_usrAccess_error": "Errore nell'abilitazione/disabilitazione dell'accesso utente",
    "btn_addUsr": "Aggiungi utente",
    "lbl_addUsr": "aggiungi utenti in modo che vengano avvisati sugli eventi per questo pannello",
    "lbl_events": "Eventi",
    "lbl_sets": "Imposta",
    "lbl_alarms": "Allarmi",
    "lbl_tampers": "Manomissioni",
    "lbl_system": "Sistema",
    "msg_emailAlert_added": "Avvisi e-mail utente aggiunti correttamente.",
    "lbl_edit_emailAlerts": "Modifica avvisi e-mail utente",
    "msg_usrEmail_edited": "L'adresse e-mail de l'utilisateur a été modifiée avec succès",
    "lbl_delete_usrEmail": "Elimina avvisi e-mail utente",
    "msg_delete_usrEmail": "Sei sicuro di voler eliminare gli avvisi e-mail degli utenti?",
    "msg_usr_emailDeleted": "Avviso e-mail dell'utente eliminato correttamente",
    "tootltip_exportNotification": "Notifiche di esportazione",
    "lbl_delivered": "CONSEGNATO",
    "lbl_failed": "FALLITO",
    "lbl_information": "Informazione",
    "lbl_evntDetails": "dettagli dell'evento",
    "lbl_systmName": "Nome del sistema",
    "lbl_Id": "ID",
    "lbl_partition": "Partizione",
    "lbl_user": "Utente",
    "lbl_username": "Nome utente",
    "lbl_text": "Testo",
    "lbl_verbose": "Prolissa",
    "lbl_timestamp": "Timestamp",
    "lbl_code": "Codice",
    "lbl_group": "Gruppo",
    "lbl_SIA_IP": "SIA IP DIRETTO",
    "lbl_CLS_Dualcom": "CSL Dual Com",
    "lbl_webway": "WebWayOne",
    "lbl_noResponse": "NESSUNA RISPOSTA",
    "lbl_rejected": "RESPINTO",
    "lbl_readyForDelivering": "IN PRONTA CONSEGNA",
    "lbl_sent": "INVIATA",
    "lbl_exportLogs": "Esporta registri",
    "lbl_failedSvc": "Servizi falliti",
    "lbl_ScheduledSvc": "Servizi programmati",
    "lbl_pendingSvc": "Nessun servizio programmato o non riuscito",
    "lbl_cmpltd_svcReports": "Rapporti di servizio completati",
    "lbl_report_no": "Numero rapporto",
    "lbl_cmpltd_date": "Data completata",
    "lbl_cmpltd_Classification": "Classificazione",
    "lbl_cmpltd_View": "Visualizzazione",
    "lbl_service_report": "Rapporto di servizio",
    "lbl_delete_svcReport": "Elimina rapporto di servizio",
    "msg_delete_svcReport": "Sei sicuro di voler eliminare il rapporto di servizio?",
    "msg_svcReport_deleted": "Rapporto di servizio eliminato con successo",
    "btn_perform_remoteSvc": "Eseguire il servizio remoto",
    "msg_remoteSvc_initiated": "Servizio remoto avviato",
    "msg_remoteSvc_inProgress": "il servizio è in corso",
    "lbl_errorOccurred": "Errore",
    "lbl_noCmpltd_svc": "Nessun servizio completato",
    "lbl_noCmpltd_svcReports": "Nessun rapporto di servizio completato",
    "lbl_mobApp_usrs": "Utenti di app mobili",
    "msg_usr_authorized": "Utente non autorizzato",
    "msg_internal_error": "Errore interno del server",
    "msg_try_later_error": "Qualcosa è andato storto. Per favore riprova.",
    "lbl_info_diplayed": "Informazioni visualizzate nell'app mobile",
    "lbl_compnyName": "Nome della ditta",
    "lbl_officeNo": "Numero di telefono dell'ufficio",
    "lbl_outOfHrs": "Numero di telefono fuori orario",
    "lbl_contactEmail": "Contatto email",
    "lbl_logo": "Logo",
    "btn_uploadLogo": "Carica logo",
    "btn_dragLogo": "o trascina il logo qui",
    "msg_endUsrInfo_edited": "Informazioni sull'utente finale modificate correttamente",
    "btn_remove": "Rimuovere",
    "lbl_userType": "Tipologia di utente",
    "lbl_login": "Login",
    "lbl_email_notification": "Notifica per email",
    "lbl_delete_accntUsr": "Elimina account utente",
    "msg_deleteUsr": "Sei sicuro di voler eliminare l'utente?",
    "msg_deleteUsr_error": "Errore nell'eliminazione dell'utente dell'account",
    "msg_accntUsr_deleted": "Account utente eliminato correttamente",
    "lbl_firstName": "Nome di battesimo",
    "lbl_lastName": "Cognome",
    "lbl_typeUsr": "Digitare Utente",
    "lbl_emailNotifications": "Notifiche di posta elettronica",
    "lbl_userPemissions": "Autorizzazioni utente",
    "msg_userEdited": "Utente modificato con successo",
    "lbl_unauthorizedUser": "Utente non autorizzato",
    "lbl_someError": "Si è verificato un errore",
    "lbl_pwd": "Parola d'ordine",
    "lbl_repeat_pwd": "Ripeti la password",
    "lbl_engineer": "Ingegnera",
    "lbl_manager": "Manager",
    "lbl_installer": "Installatrice",
    "lbl_officeStaff": "Personale dell'ufficio",
    "msg_noMatch_pwd": "Password senza corrispondenza!",
    "msg_usr_added": "Utente aggiunto con successo",
    "lbl_panel": "Pannello",
    "lbl_accnt_holder": "Titolare del conto",
    "lbl_spoken_pwd": "Parola d'ordine parlata",
    "lbl_language": "Lingua",
    "lbl_cmpnyInfo": "Informazioni sulla società",
    "lbl_firstPh": "Primo telefono",
    "lbl_secondPh": "Secondo telefono",
    "lbl_thirdPh": "Terzo telefono",
    "lbl_oldPwd": "vecchia password",
    "lbl_newPwd": "nuova password",
    "lbl_repeatPwd": "Ripeti la password",
    "msg_accntHolder_edited": "Titolare dell'account modificato correttamente",
    "lbl_firstPhType": "Primo tipo di telefono",
    "lbl_firstPhone": "Primo numero di telefono",
    "lbl_secondPhone": "Secondo numero di telefono",
    "lbl_thirdPhone": "Terzo numero di telefono",
    "lbl_secondPhType": "Secondo tipo di telefono",
    "lbl_thirdPhType": "Terzo tipo di telefono",
    "lbl_home": "Casa",
    "lbl_mobile": "Mobile",
    "lbl_office": "Ufficio",
    "msg_cmpnyInfo_edited": "Informazioni sull'azienda modificate correttamente",
    "lbl_termsConditions": "Termini e Condizioni",
    "lbl_privacyPolicy": "politica sulla riservatezza",
    "msg_email_invalid": "L'email non è valida",
    "msg_unmatchedPwd": "Password senza corrispondenza!",
    "lbl_installerGrp": "Gruppo installatori",
    "msg_usrExist": "L'utente con il login esiste già",
    "lbl_editUsr": "Modifica utente",
    "msg_field_required": "Questo campo è obbligatorio",
    "lbl_disable_ARC": "Disabilita l'accesso ARC",
    "msg_disable_ARC": "Sei sicuro di voler disabilitare l'accesso ARC?",
    "lbl_SiaEvnts": "Sia Eventi",
    "lbl_SIAEvnt": "Evento SIA",
    "msg_prohibited_chars": "input contiene caratteri proibiti",
    "msg_inputLength": "la lunghezza dell'input non deve superare i 30 caratteri",
    "lbl_favPanels": "PANNELLI PREFERITI",
    "lbl_lastConnection": "Ultima connessione",
    "lbl_androidPh": "Telefono Android",
    "lbl_iPhone": "Il mio iPhone",
    "lbl_alexa": "Alexa",
    "lbl_gHome": "Google Casa",
    "lbl_removePairing": "Rimuovi associazione",
    "msg_removePairing": "Sei sicuro di voler rimuovere l'associazione per questo utente dell'app?",
    "lbl_no": "NO",
    "lbl_yes": "SÌ",
    "lbl_device": "Dispositivo",
    "lbl_appDetails": "Dettagli dell'applicazione",
    "lbl_myAndroid": "Il mio Android",
    "lbl_myAlexa": "La mia Alexa",
    "lbl_myiPhone": "Il mio iPhone",
    "lbl_mygHome": "Il mio Google Home",
    "lbl_appID": "ID applicazione",
    "lbl_mobOS": "Sistema operativo mobile",
    "lbl_token": "Gettone",
    "msg_didNotMovePanl": "Non ha spostato alcun pannello",
    "lbl_panlsSelected": "Pannelli selezionati",
    "lbl_panlSelected": "Pannello selezionato",
    "lbl_ARCCode": "Codice ARC",
    "lbl_response": "Risposta",
    "lbl_data": "Dati",
    "lbl_state": "Stato",
    "lbl_signal_Timeout": "Timeout del segnale",
    "lbl_sysID": "ID di sistema",
    "lbl_date": "Data",
    "lbl_remove_destination": "Destinazioni e-mail di assistenza remota",
    "lbl_emailAddress": "Indirizzo e-mail",
    "Reports": "Rapporti",
    "lbl_green": "VERDE",
    "lbl_amber": "AMBRA",
    "lbl_red": "ROSSA",
    "lbl_reportDetails": "Dettagli del rapporto",
    "lbl_dueDate": "Scadenza",
    "btn_edit": "modificare",
    "lbl_nameAZ": "Nome (A-Z)",
    "lbl_nameZA": "Nome (Z-A)",
    "lbl_mostAlarms": "La maggior parte degli allarmi",
    "lbl_fewerAlarms": "Meno allarmi",
    "lbl_updatedRecent": "Aggiornato di recente",
    "lbl_none": "Nessuno",
    "msg_confirmation": "Sei sicuro di volere",
    "msg_enable": "abilitare",
    "msg_disable": "disattivare",
    "msg_edited": "modificato con successo",
    "btn_addEmail": "Aggiungi destinazione e-mail",
    "lbl_emailDestinationAdd": "aggiungere la destinazione e-mail in modo che i rapporti di servizio remoti possano essere inviati via e-mail",
    "lbl_termsToUseWebsite": "I termini in base ai quali è possibile utilizzare il nostro sito web",
    "lbl_termsToUseWebsite_1.1": "Le presenti Condizioni d'uso (unitamente agli altri documenti indicati di seguito) stabiliscono i termini in base ai quali è possibile utilizzare il nostro sito web",
    "lbl_termsToUseWebsite_1.1part": "Si consiglia di stampare una copia delle presenti Condizioni d'uso per riferimento futuro.",
    "lbl_termsToUseWebsite_1.2": "È necessario leggere attentamente le presenti Condizioni d'uso prima di utilizzare il nostro sito Web, poiché utilizzando il nostro sito Web si conferma di accettare le presenti Condizioni d'uso e di rispettarle.",
    "lbl_termsToUseWebsite_1.3": "Se non si accettano le presenti Condizioni d'uso, è necessario abbandonare immediatamente il nostro sito web",
    "lbl_termsToUseWebsite_1.4": "La seguente politica si applica anche al tuo utilizzo del nostro sito web",
    "lbl_termsToUseWebsite_1.4.1": "La nostra politica sulla privacy e sui cookie",
    "lbl_termsToUseWebsite_1.4.1part": "Utilizzando il nostro sito Web, acconsenti al trattamento dei dati personali che ci fornisci o che raccogliamo da te come risultato del tuo utilizzo del nostro sito Web. La nostra politica sulla privacy stabilisce cosa facciamo con tali dati personali",
    "lbl_termsToUseWebsite_1.5": "Sei responsabile di garantire che chiunque altro acceda al nostro sito Web tramite la tua connessione Internet sia a conoscenza delle presenti Condizioni d'uso e delle politiche di cui sopra e che le rispetti.",
    "lbl_chngesToTerms": "Modifiche alle presenti Condizioni d'uso e alle nostre altre politiche",
    "lbl_chngesToTerms_2.1": "Di tanto in tanto apportiamo modifiche alle presenti Condizioni d'uso. Dovresti quindi controllare le presenti Condizioni d'uso ogni volta che ritorni sul nostro sito Web per vedere se sono state apportate modifiche, poiché queste saranno vincolanti per te.",
    "lbl_chngesToTerms_2.2": "Di tanto in tanto apportiamo anche modifiche alla nostra politica sulla privacy e sulla politica sui cookie, quindi dovresti anche controllarle regolarmente per vedere se sono state apportate modifiche.",
    "lbl_aboutUs": "Chi siamo",
    "lbl_aboutUs_3.1": "Noi, Eaton Electrical Products Ltd, gestiamo questo sito Web all'indirizzo www.eatonsecureconnect.com. Siamo una società registrata in Inghilterra e Galles con il numero di registrazione 01342230 e la nostra sede legale è a 6 Jephson Court, Tancred Close, Queensway, Royal Leamington Spa, Warwickshire, CV31 3RZ.",
    "lbl_aboutUs_3.2": "Puoi contattarci",
    "lbl_aboutUs_3.2part": "Via e-mail all'indirizzo",
    "lbl_restrictionsOnUseOfWebsite": "Restrizioni sull'utilizzo del nostro sito web",
    "lbl_restrictionsOnUseOfWebsite_4.1": "È possibile utilizzare il nostro sito Web solo per scopi leciti e in conformità con tutte le leggi applicabili, incluse, a titolo esemplificativo, le leggi sulla protezione dei dati e sulla privacy, le leggi relative al copyright dei contenuti e le leggi relative ai messaggi elettronici commerciali non richiesti.",
    "lbl_restrictionsOnUseOfWebsite_4.2": "Inoltre, non devi",
    "lbl_restrictionsOnUseOfWebsite_4.2.1": "utilizzare il nostro sito Web per inviare o trasmettere, direttamente o indirettamente, materiale pubblicitario o promozionale non richiesto o non autorizzato, catene di Sant'Antonio o schemi di vendita piramidale;",
    "lbl_restrictionsOnUseOfWebsite_4.2.2": "utilizzare il nostro sito web per mail bombing o flooding o per tentare deliberatamente di sovraccaricare un sistema;",
    "lbl_restrictionsOnUseOfWebsite_4.2.3": "utilizzare il nostro sito Web per pubblicare contenuti o collegarsi a contenuti di cui sai o avresti dovuto sapere che il proprietario di tali contenuti non ha acconsentito o autorizzato tale pubblicazione o collegamento o laddove tale pubblicazione o collegamento viola i diritti di tale proprietario;",
    "lbl_restrictionsOnUseOfWebsite_4.2.4": "introdurre consapevolmente o sconsideratamente nel nostro sito Web o utilizzare il nostro sito Web per trasmettere virus, trojan horse, worm, bombe logiche, bombe a orologeria, keystroke logger, spyware, malware, adware o altri materiali dannosi o dannosi;",
    "lbl_restrictionsOnUseOfWebsite_4.2.5": "tentare di ottenere l'accesso non autorizzato al nostro sito Web, al server, alle apparecchiature o alla rete su cui è memorizzato il nostro sito Web, a qualsiasi server, computer o database connesso al nostro sito Web o a qualsiasi software;",
    "lbl_restrictionsOnUseOfWebsite_4.2.6": "utilizzare il nostro sito Web per tentare di ottenere l'accesso non autorizzato a qualsiasi altro sito Web, account Internet, server, computer, apparecchiature, sistema, rete, dati o informazioni;",
    "lbl_restrictionsOnUseOfWebsite_4.2.7": "utilizzare il nostro sito Web per monitorare dati o traffico su qualsiasi rete o sistema;",
    "lbl_restrictionsOnUseOfWebsite_4.2.8": "utilizzare il nostro sito Web per raccogliere o utilizzare informazioni, inclusi, a titolo esemplificativo, indirizzi e-mail, nomi di schermate o altri identificatori, con l'inganno (come phishing, truffe su Internet, furto di password, spidering, scraping e raccolta);",
    "lbl_restrictionsOnUseOfWebsite_4.2.9": "utilizzare il nostro sito web per distribuire software;",
    "lbl_restrictionsOnUseOfWebsite_4.2.10": "eseguire un attacco di negazione del servizio o un attacco di negazione del servizio distribuito sul nostro sito Web o utilizzare il nostro sito Web per eseguire un attacco di questo tipo su qualsiasi altro sito Web;",
    "lbl_restrictionsOnUseOfWebsite_4.2.11": "usa il nostro sito web:",
    "lbl_restrictionsOnUseOfWebsite_4.2.11.1": "per qualsiasi scopo che; O",
    "lbl_restrictionsOnUseOfWebsite_4.2.11.2": "inviare, trasmettere, pubblicare, caricare, scaricare, utilizzare, archiviare, riprodurre o ricevere consapevolmente qualsiasi materiale che non sia conforme agli standard di contenuto stabiliti in",
    "lbl_para8": "paragrafo 8",
    "lbl_restrictionsOnUseOfWebsite_4.2.12": "utilizzare il nostro sito Web per adescare, danneggiare o sfruttare i minori in qualsiasi modo o per tentare di farlo; O",
    "lbl_restrictionsOnUseOfWebsite_4.2.13": "utilizzare il nostro sito Web in violazione delle leggi sull'esportazione, dei controlli, dei regolamenti o delle politiche sanzionatorie degli Stati Uniti o di qualsiasi altro paese.",
    "lbl_restrictionsOnUseOfWebsite_4.3": "In caso di violazione di qualsiasi termine delle presenti Condizioni d'uso, il diritto di utilizzare il nostro sito Web terminerà immediatamente. Inoltre, potremmo intraprendere altre azioni, incluse, a titolo esemplificativo, azioni legali che riteniamo appropriate.",
    "lbl_restrictionsOnUseOfWebsite_4.4": "In caso di violazione di qualsiasi termine delle presenti Condizioni d'uso, o se riceviamo una richiesta da parte di un'autorità incaricata dell'applicazione della legge, potremmo segnalare la tua identità, i dettagli di qualsiasi violazione e qualsiasi altra informazione che riteniamo necessaria alle autorità incaricate dell'applicazione della legge.",
    "lbl_pwdNSecRequirement": "Password e requisiti di sicurezza",
    "lbl_pwdNSecRequirement_5.1": "Se registri un account sul nostro sito Web, devi fornire informazioni di registrazione accurate e complete e mantenerle sempre aggiornate.",
    "lbl_pwdNSecRequirement_5.2": "L'utilizzo di alcune parti di questo sito Web è protetto da password. Per poterlo utilizzare è necessario un codice identificativo utente o una password per accedervi. È tua responsabilità mantenere queste informazioni segrete e riservate e non divulgarle a nessun'altra persona.",
    "lbl_pwdNSecRequirement_5.3": "Se ritieni che qualcun altro possa conoscere il tuo codice identificativo utente o la tua password, devi accedere al tuo account e modificarli il prima possibile",
    "lbl_pwdNSecRequirement_5.4": "Ci riserviamo il diritto di disabilitare qualsiasi codice di identificazione utente o password in qualsiasi momento se, a nostro ragionevole parere, non rispetti uno qualsiasi dei presenti Termini di utilizzo, se riteniamo che tu stia utilizzando il nostro sito Web per scaricare contenuti o violare qualsiasi modo i diritti di un'altra persona o se riteniamo che possa esserci un rischio per la sicurezza.",
    "lbl_webSiteNContent": "Il nostro sito Web e il suo contenuto",
    "lbl_webSiteNContent_6.1": "Non garantiamo che l'accesso al nostro sito Web o il contenuto su di esso (incluso qualsiasi contenuto caricato sul nostro sito Web) sarà sempre disponibile o ininterrotto. Il tuo diritto di accedere al nostro sito Web è concesso solo su base temporanea.",
    "lbl_webSiteNContent_6.2": "Ci riserviamo il diritto di modificare il nostro sito Web e il contenuto su di esso, anche senza limitazioni aggiungendo o rimuovendo contenuti o funzionalità, in qualsiasi momento e senza preavviso.",
    "lbl_webSiteNContent_6.3": "Non possiamo garantire che tutti i contenuti del nostro sito Web siano accurati, completi o aggiornati. Pertanto non forniamo alcuna garanzia, dichiarazione, impegno o garanzia che il contenuto del nostro sito Web sia accurato, completo o aggiornato.",
    "lbl_webSiteNContent_6.4": "Il contenuto del nostro sito Web è fornito solo a scopo informativo generale. Nulla sul nostro sito Web è destinato a essere un consiglio su cui dovresti fare affidamento. Dovresti sempre ottenere una consulenza specialistica appropriata prima di intraprendere o non intraprendere azioni sulla base di qualsiasi contenuto del nostro sito web.",
    "lbl_webSiteNContent_6.5": "Non forniamo alcuna garanzia, dichiarazione, impegno o garanzia che il nostro sito Web sia sicuro o privo di bug o virus. Sei responsabile di garantire che il dispositivo da cui accedi al nostro sito Web disponga di un software antivirus adeguato.",
    "lbl_contentUploaded": "Contenuti caricati sul nostro sito Web da te o da altri utenti",
    "lbl_contentUploaded_7.1": "Se carichi contenuti sul nostro sito Web, devi rispettare gli standard di contenuto stabiliti in",
    "lbl_contentUploaded_7.1part": "rispetto a quel contenuto",
    "lbl_contentUploaded_7.2": "Ci garantisci di essere il proprietario di qualsiasi contenuto che carichi sul nostro sito Web o, se non sei il proprietario, di avere il diritto di caricarlo.",
    "lbl_contentUploaded_7.3": "Continuerai a possedere qualsiasi contenuto che carichi sul nostro sito Web, ma finché tale contenuto rimane sul nostro sito Web ci concedi una licenza mondiale per utilizzare, archiviare, copiare, modificare, eseguire, distribuire, visualizzare e rendere disponibile quel contenuto e per consentire ad altri utenti del nostro sito Web di utilizzarlo, scaricarlo, stamparlo e riprodurlo in conformità con i nostri Termini di utilizzo.",
    "lbl_contentUploaded_7.4": "Ci risarcirai per eventuali perdite, responsabilità, costi, spese, rivendicazioni o procedimenti che subiamo o sosteniamo, o subiremo o incorreremo, a seguito di o in connessione con qualsiasi violazione da parte tua dei tuoi obblighi o garanzie in questo",
    "lbl_para7": "paragrafo 7",
    "lbl_contentUploaded_7.5": "Non siamo responsabili e non forniamo alcuna garanzia, dichiarazione, impegno o garanzia in relazione a qualsiasi contenuto del nostro sito Web caricato da un utente. Qualsiasi opinione espressa da qualsiasi utente del nostro sito Web è la loro opinione e non la nostra opinione né quella dei nostri funzionari o dipendenti.",
    "lbl_contentUploaded_7.6": "Non siamo responsabili per l'archiviazione o il backup di qualsiasi contenuto caricato sul nostro sito Web e non saremo responsabili per qualsiasi perdita, danneggiamento o distruzione di tale contenuto.",
    "lbl_contentUploaded_7.7": "Ti consigliamo di eseguire il backup di tutti i contenuti che carichi sul nostro sito web.",
    "lbl_contentUploaded_7.8": "Ci riserviamo il diritto di rimuovere qualsiasi contenuto caricato sul nostro sito Web in qualsiasi momento.",
    "lbl_contentUploaded_7.9": "Ci riserviamo inoltre il diritto di rivelare la tua identità a qualsiasi persona che affermi che qualsiasi contenuto che hai caricato sul nostro sito Web viola i loro diritti di proprietà intellettuale o il loro diritto alla privacy o è diffamatorio.",
    "lbl_contentStd": "Standard di contenuto",
    "lbl_contentStd_8.1": "Ricorda che qualsiasi contenuto che carichi sul nostro sito Web può essere visualizzato da altri utenti, quindi pensa sempre attentamente prima di inviare qualsiasi contenuto.",
    "lbl_contentStd_8.2": "Qualsiasi contenuto che carichi sul nostro sito Web deve:",
    "lbl_contentStd_8.2.1": "rispettare tutte le leggi applicabili;",
    "lbl_contentStd_8.2.2": "essere accurato (dove afferma i fatti); E",
    "lbl_contentStd_8.2.3": "essere genuinamente ritenuto (dove esprime opinioni).",
    "lbl_contentStd_8.3": "Non devi caricare sul nostro sito web alcun contenuto che:",
    "lbl_contentStd_8.3.1": "è diffamatorio, osceno, offensivo, indecente, dannoso, odioso o provocatorio;",
    "lbl_contentStd_8.3.2": "è pornografico o sessualmente esplicito",
    "lbl_contentStd_8.3.3": "sfrutta i minori;",
    "lbl_contentStd_8.3.4": "promuove la violenza o il terrorismo;",
    "lbl_contentStd_8.3.5": "è discriminatorio in base a razza, sesso, religione, nazionalità, disabilità, orientamento sessuale o età;",
    "lbl_contentStd_8.3.6": "viola qualsiasi diritto d'autore, diritto di database, marchio o altro diritto di proprietà intellettuale di qualsiasi altra persona;",
    "lbl_contentStd_8.3.7": "sollecita o tenta di sollecitare password o informazioni di identificazione personale per scopi commerciali o illegali da altri utenti;",
    "lbl_contentStd_8.3.8": "è fraudolento o suscettibile di ingannare qualsiasi persona;",
    "lbl_contentStd_8.3.9": "è fatto in violazione di qualsiasi obbligo legale dovuto a chiunque altro, come un obbligo contrattuale o un obbligo di fiducia;",
    "lbl_contentStd_8.3.10": "crea un rischio per la salute o la sicurezza di una persona o per la salute o la sicurezza pubblica;",
    "lbl_contentStd_8.3.11": "promuove o costituisce attività illegali o illecite;",
    "lbl_contentStd_8.3.12": "compromette la sicurezza nazionale;",
    "lbl_contentStd_8.3.13": "interferisce con un'indagine da parte delle forze dell'ordine o dell'autorità di regolamentazione;",
    "lbl_contentStd_8.3.14": "è minaccioso o offensivo o invade la privacy di un'altra persona o causa fastidio, disagio o ansia inutile;",
    "lbl_contentStd_8.3.15": "è suscettibile di molestare, turbare, mettere in imbarazzo, allarmare o infastidire qualsiasi altra persona;",
    "lbl_contentStd_8.3.16": "impersonates any person or misrepresents your identity or affiliation with any person;",
    "lbl_contentStd_8.3.17": "dà l'impressione che provenga da noi;",
    "lbl_contentStd_8.3.18": "costituisce materiale pubblicitario; O",
    "lbl_contentStd_8.3.19": "sostiene, promuove o assiste qualsiasi attività illecita, incluse, a titolo esemplificativo ma non esaustivo, la violazione del copyright o l'uso improprio del computer.",
    "lbl_IntellctlProprty": "Diritti di proprietà intellettuale",
    "lbl_IntellctlProprty_9.1": "Noi, oi nostri licenziatari, possediamo il copyright e tutti gli altri diritti di proprietà intellettuale nel nostro sito Web e tutti i contenuti su di esso diversi dai contenuti caricati o collegati da altri.",
    "lbl_IntellctlProprty_9.2": "È possibile scaricare il contenuto di nostra proprietà dal nostro sito Web e/o stampare un numero ragionevole di copie di qualsiasi pagina del nostro sito Web. Non devi apportare alcuna modifica ai contenuti che hai scaricato o stampato dal nostro sito web. Se riproduci il contenuto scaricato o stampato con qualsiasi mezzo, devi assicurarti di riprodurlo in modo accurato e completo e includere una dichiarazione che riconosca che proviene dal nostro sito Web",
    "lbl_IntellctlProprty_9.3": "Se utilizzi, scarichi, stampi o riproduci contenuti dal nostro sito Web in violazione delle presenti Condizioni d'uso, il tuo diritto di utilizzare il nostro sito Web cesserà immediatamente e dovrai restituirci o distruggere (a nostra discrezione) tutte le copie digitali e cartacee del contenuto che hai fatto.",
    "lbl_linksToOtherWebsite": "Collegamenti dal nostro sito web ad altri siti web",
    "lbl_linksToOtherWebsite_text": "Il nostro sito Web può contenere collegamenti ad altri siti Web di volta in volta. Questi collegamenti sono forniti per comodità dell'utente. Non abbiamo alcun controllo e nessuna responsabilità nei tuoi confronti per tali altri siti Web o il loro contenuto, e qualsiasi utilizzo o accesso da parte tua a tali siti Web sarà soggetto ai relativi termini e condizioni d'uso di tali siti Web e non a questi Termini d'uso.",
    "lbl_linksFromOtherWebsite": "Collegamenti da altri siti Web al nostro sito Web",
    "lbl_linksFromOtherWebsite_11.1": "È possibile creare un collegamento al nostro sito Web da un altro sito Web, ma solo se si rispettano le seguenti condizioni:",
    "lbl_linksFromOtherWebsite_11.1.1": "devi informarci della tua intenzione di creare un collegamento al nostro sito Web da un altro sito Web e ottenere la nostra approvazione scritta prima di creare tale collegamento;",
    "lbl_linksFromOtherWebsite_11.1.2": "è possibile collegarsi solo alla home page del nostro sito web. Non è necessario creare un collegamento a qualsiasi altra pagina del nostro sito Web, né eseguire il frame di alcuna pagina del nostro sito Web;",
    "lbl_linksFromOtherWebsite_11.1.3": "puoi collegarti al nostro sito Web solo da un sito Web di tua proprietà e conforme agli standard stabiliti in",
    "lbl_linksFromOtherWebsite_11.1.4": "la creazione del collegamento non introdurrà al nostro sito Web, né utilizzerà il nostro sito Web per trasmettere, virus, cavalli di troia, worm, bombe logiche, bombe a orologeria, keystroke logger, spyware, malware, adware o altri materiali dannosi o nocivi ;",
    "lbl_linksFromOtherWebsite_11.1.5": "rispetterai tali requisiti e/o politiche che potremmo notificarti di volta in volta in relazione a qualsiasi collegamento al nostro sito Web da un altro sito Web; E",
    "lbl_linksFromOtherWebsite_11.1.6": "non devi suggerire che abbiamo alcuna associazione con te o che approviamo o approviamo il tuo sito web o uno qualsiasi dei tuoi prodotti o servizi.",
    "lbl_linksFromOtherWebsite_11.2": "Ci riserviamo il diritto di richiedere all'utente di rimuovere qualsiasi collegamento al nostro sito Web in qualsiasi momento e senza preavviso",
    "lbl_ourLiability": "La nostra responsabilità nei tuoi confronti",
    "lbl_ourLiability_12.1": "Nulla in queste Condizioni d'uso limita o esclude la nostra responsabilità nei tuoi confronti per:",
    "lbl_ourLiability_12.1.1": "morte o lesioni personali derivanti da negligenza;",
    "lbl_ourLiability_12.1.2": "frode o falsa dichiarazione fraudolenta; O",
    "lbl_ourLiability_12.1.3": "qualsiasi altra questione per la quale non siamo autorizzati dalla legge ad escludere o limitare la nostra responsabilità.",
    "lbl_ourLiability_12.2": "Poiché le presenti Condizioni d'uso regolano l'accesso e l'utilizzo del nostro sito Web, tutte le garanzie, condizioni e altri termini relativi al nostro sito Web e/o al suo contenuto che sarebbero altrimenti impliciti per legge (per statuto, diritto comune o altro) sono esclusi.",
    "lbl_ourLiability_12.3": "Non saremo responsabili nei confronti dell'utente per eventuali perdite o danni derivanti da o in connessione con l'utilizzo (o l'impossibilità di utilizzare) del nostro sito Web o l'utilizzo o l'affidamento da parte dell'utente di qualsiasi contenuto del nostro sito Web, sia in contratto, illecito (incluso senza limitazione di negligenza), falsa dichiarazione, restituzione, ai sensi di legge o altro.",
    "lbl_ourLiability_13": "Legge applicabile",
    "lbl_ourLiability_13.1": "Se sei un consumatore, le presenti Condizioni d'uso sono disciplinate dalla legge dell'Inghilterra e del Galles e i tribunali di Inghilterra e Galles hanno giurisdizione non esclusiva per determinare qualsiasi controversia derivante da o in connessione con esse. Se risiedi in Scozia puoi anche intentare un'azione legale in Scozia e se sei residente in Irlanda del Nord puoi anche intentare un'azione legale in Irlanda del Nord. I residenti di altri paesi in cui il sito Web è commercializzato sono soggetti alla legge dell'Inghilterra e del Galles",
    "lbl_ourLiability_13.2": "Se sei un utente aziendale, le presenti Condizioni d'uso (e qualsiasi obbligo extracontrattuale derivante da o in connessione con esse) sono disciplinate dalla legge dell'Inghilterra e del Galles e i tribunali di Inghilterra e Galles hanno giurisdizione esclusiva per determinare qualsiasi controversia derivante al di fuori o in connessione con essi (incluso, a titolo esemplificativo, in relazione a qualsiasi obbligo extracontrattuale). I residenti di altri paesi in cui il sito Web è commercializzato sono soggetti alla legge dell'Inghilterra e del Galles",
    "lbl_privPolicy": "politica sulla riservatezza",
    "lbl_privPolicy_text": "Il sito Web Eaton SecureConnect è un sito Web e un portale gestito da Eaton Electrical Products Limited, una società registrata presso la Companies House con il numero di registrazione 01342230, il cui indirizzo registrato è Jephson Court Tancred Close, Queensway, Royal Leamington Spa, Warwickshire, CV31 3RZ.",
    "lbl_privPolicy_text1": "Eaton Electrical Products Limited",
    "lbl_privPolicy_textWe": "Noi",
    "lbl_privPolicy_textUs": "noi",
    "lbl_privPolicy_textOur": "nostro",
    "lbl_privPolicy_text2": "è il responsabile del trattamento dei dati personali da voi forniti attraverso questo sito web.",
    "lbl_privPolicy_text3": "Questo avviso (insieme ai nostri termini di utilizzo:",
    "lbl_privPolicy_text4": "e qualsiasi altro documento a cui si fa riferimento) stabilisce la base su cui qualsiasi dato personale che raccogliamo da voi, o che ci fornite, attraverso questo sito Web sarà da noi elaborato. Si prega di leggere attentamente quanto segue per comprendere le nostre pratiche relative ai dati personali e come li tratteremo.",
    "lbl_privPolicy_text5": "Se hai domande su questo avviso o non sei d'accordo con esso, ti preghiamo di contattarci all'indirizzo dataprotection@eaton.com prima di utilizzare questo sito web.",
    "lbl_privPolicy_text6": "Potremmo modificare questo avviso di volta in volta aggiornando questa pagina. Dovresti controllare questa pagina di tanto in tanto per assicurarti di essere soddisfatto di eventuali modifiche. Il presente avviso è in vigore dal 20 dicembre 2019.",
    "lbl_infoWeCollect": "Quali informazioni raccogliamo?",
    "lbl_infoWeCollect_text": "Potrai accedere al sito web senza fornirci alcuna informazione. Tuttavia, la maggior parte dei servizi forniti attraverso il sito Web (come quelli disponibili attraverso il portale) saranno disponibili solo se elaboriamo determinate informazioni su di te.",
    "lbl_infoWeCollect_text1": "Potremmo raccogliere le seguenti informazioni:",
    "lbl_infoWeCollect_text2": "il tuo nome e le informazioni di contatto (inclusi indirizzo, indirizzo e-mail e numeri di telefono) e i dettagli dell'azienda;",
    "lbl_infoWeCollect_text3": "le informazioni di registrazione del tuo account se diventi un utente registrato del sito Web (compresi i dettagli di accesso e la password dell'utente);",
    "lbl_infoWeCollect_text4": "il paese da cui si accede al sito web;",
    "lbl_infoWeCollect_text5": "informazioni relative ai tuoi interessi personali o professionali, dati demografici, esperienze con i nostri prodotti e preferenze di contatto al fine di fornirti ulteriore corrispondenza sui nostri prodotti e servizi;",
    "lbl_infoWeCollect_text6": "informazioni relative ai tuoi clienti (inclusi nomi, indirizzi, prodotti che hanno acquistato e sistemi installati presso la loro sede, informazioni relative allo stato dell'allarme (ad esempio se è attivato o meno e/o funziona correttamente), immagini CCTV e se sono un utente dell'applicazione mobile Eaton SecureConnect);",
    "lbl_infoWeCollect_text7": "registrazioni di qualsiasi contatto tra te e noi; E",
    "lbl_infoWeCollect_text8": "qualsiasi informazione fornitaci volontariamente o immessa tramite il sito Web e/o la linea di assistenza.",
    "lbl_whatWeDowithInfo": "Cosa facciamo con le informazioni che abbiamo raccolto?",
    "lbl_whatWeDowithInfo_text": "Utilizziamo queste informazioni per fornirti i nostri prodotti e servizi, e in particolare per i seguenti motivi",
    "lbl_whatWeDowithInfo_text1": "per consentirci di fornirti supporto e assistenza tecnica in relazione ai prodotti che hai acquistato da noi;",
    "lbl_whatWeDowithInfo_text2": "per informarti dei periodi di inattività della manutenzione in relazione al portale e ad altri servizi a te forniti;",
    "lbl_whatWeDowithInfo_text3": "tenuta dei registri interni; E",
    "lbl_whatWeDowithInfo_text4": "per migliorare i nostri prodotti e servizi.",
    "lbl_whatWeDowithInfo_text5": "Potremmo contattarti via e-mail, telefono o posta",
    "lbl_whatWeDowithInfo_text6": "Elaboriamo questi dati personali allo scopo di adempiere ai nostri obblighi ed esercitare i nostri diritti in relazione ai nostri contratti con voi e per i nostri interessi legittimi che includono la fornitura di prodotti, servizi e soluzioni per i nostri clienti e installatori accreditati Eaton, rispettando i nostri obblighi legali, contrattuali e morali, aumentando l'efficienza delle nostre operazioni e proteggendo la nostra attività",
    "lbl_infoShareWith": "Con chi condividiamo queste informazioni?",
    "lbl_infoShareWith_text": "Ad eccezione di quanto indicato di seguito, non trasferiremo, divulgheremo, venderemo, distribuiremo o affitteremo le tue informazioni personali a terze parti diverse dalle nostre sussidiarie e/o società madre a meno che non abbiamo il tuo permesso o richiesto dalla legge.",
    "lbl_infoShareWith_text1": "A volte (e con la tua approvazione ove richiesto), condivideremo i tuoi dati personali con terze parti accuratamente selezionate al di fuori del nostro gruppo aziendale. Potremmo farlo per i seguenti motivi:",
    "lbl_infoShareWith_text2": "per consentire a tali terze parti di svolgere servizi per noi, incluso il nostro fornitore di hosting di siti Web accuratamente selezionato;",
    "lbl_infoShareWith_text3": "per consentire al tuo datore di lavoro, committente e/o appaltatore di visualizzare i dettagli contenuti nel portale relativi alla sua attività di installatore e/o ai prodotti che ha acquistato;",
    "lbl_infoShareWith_text4": "quando riteniamo che sia necessario rispettare la legge o proteggere i diritti, la proprietà o la sicurezza nostra o di un'altra persona; e/o",
    "lbl_infoShareWith_text5": "se c'è (o deve esserci) un cambio di proprietà della nostra attività o dei nostri beni, allora potremmo voler condividere le tue informazioni in modo che i nuovi (potenziali) proprietari possano continuare a gestire la nostra attività in modo efficace e continuare a fornire servizi ai clienti. Ciò può includere nuovi azionisti o qualsiasi organizzazione che potrebbe assumere un'assegnazione o il trasferimento di eventuali accordi che abbiamo stipulato con i nostri clienti.",
    "lbl_infoShareWith_text6": "Questo processo può includere l'invio di dati personali ad altri paesi all'interno dello Spazio economico europeo. Se, per qualsiasi motivo, trasferiamo i tuoi dati personali al di fuori dello Spazio economico europeo, attueremo misure di salvaguardia per garantire livelli adeguati di protezione per tutti questi dati personali e ti renderemo disponibile o ti forniremo un mezzo per ottenere una copia di tali tutele.",
    "lbl_infoShareWith_text7": "This process may include sending personal data to other countries within the European Economic Area. If, for any reason, we transfer your personal data outside of the European Economic Area, we will implement safeguards to ensure the appropriate levels of protection for all such personal data and we will make available or provide a means for you to obtain a copy of such safeguards.",
    "lbl_howLongStoreData": "Per quanto tempo conserveremo i tuoi dati personali?",
    "lbl_howLongStoreData_text": "Per quanto tempo conserviamo le tue informazioni dipende dalla base su cui sono state fornite, ma in generale:",
    "lbl_howLongStoreData_text1": "conserveremo le informazioni necessarie per consentirci di fornirti un servizio che hai richiesto tramite o in connessione con questo sito Web per tutto il tempo necessario a fornire tale servizio;",
    "lbl_howLongStoreData_text2": "generalmente terremo traccia di tutte le transazioni effettuate con noi per un minimo di sei anni dalla fine del rapporto. Questo è così che possiamo risolvere problemi e rispondere a eventuali reclami o controversie che sorgono in quel periodo. In caso contrario, conserveremo le informazioni ove appropriato per legittime esigenze aziendali;",
    "lbl_howLongStoreData_text3": "conserveremo altre informazioni su di te se è necessario per noi per rispettare la legge o, ove appropriato, per legittime esigenze aziendali.",
    "lbl_sensativeInfo": "Informazione sensibile",
    "lbl_sensativeInfo_text": "Le informazioni su di te che sono considerate sensibili o una categoria speciale di dati personali ai sensi delle leggi sulla protezione dei dati possono includere informazioni sulle tue condizioni mediche o di salute, origine razziale o etnica, opinioni politiche, appartenenza a sindacati, convinzioni religiose o filosofiche, dati genetici, dati biometrici , vita sessuale e orientamento sessuale, attività criminale sospetta o provata e relativi procedimenti. Se abbiamo bisogno di elaborare dati personali sensibili, verrai informato di tale elaborazione e ti verrà chiesto di acconsentire specificamente all'uso di tali informazioni come appropriato.",
    "lbl_security": "Sicurezza",
    "lbl_security_text": "Ci impegniamo a garantire che le tue informazioni siano al sicuro. Al fine di impedire l'accesso o la divulgazione non autorizzati, abbiamo messo in atto procedure fisiche, elettroniche e gestionali per salvaguardare e proteggere le informazioni che raccogliamo online.",
    "lbl_security_text1": "Ci sforziamo di adottare tutte le misure ragionevoli per proteggere i tuoi dati personali. Tuttavia, tieni presente che esistono rischi intrinseci per la sicurezza nel fornire informazioni e negoziare online su Internet e pertanto non possiamo garantire la sicurezza dei dati personali divulgati online. Ti chiediamo di non fornirci dati personali sensibili online (consulta la sezione `Informazioni sensibili` sopra) a meno che non lo richiediamo espressamente.",
    "lbl_cookies": "Biscotti",
    "lbl_cookies_text": "Come molte aziende, utilizziamo `cookie` e strumenti simili sul nostro sito Web per migliorarne le prestazioni e migliorare la tua esperienza utente.",
    "lbl_cookies_text1": "Cosa sono i cookie?",
    "lbl_cookies_text2": "Un cookie è un piccolo file di testo che viene inserito nel dispositivo che utilizzi per accedere al sito web (il tuo",
    "lbl_cookies_text3": "I cookie aiutano ad analizzare il traffico web e consentono alle applicazioni web di rispondere a te come individuo. L'applicazione web può adattare le sue operazioni alle tue esigenze, simpatie e antipatie raccogliendo e ricordando informazioni sulle tue preferenze. Alcuni cookie possono contenere dati personali: ad esempio, se fai clic su `ricordami` quando accedi, un cookie potrebbe memorizzare il tuo nome utente. La maggior parte dei cookie non raccoglierà dati personali che ti identificano e raccoglieranno invece informazioni più generali, come il modo in cui gli utenti arrivano e utilizzano il nostro sito Web o la posizione generale di un utente.",
    "lbl_cookies_text4": "Per cosa utilizziamo i cookie?",
    "lbl_cookies_text5": "Possiamo inserire cookie o file simili sul tuo dispositivo per motivi di sicurezza, per dirci se hai già visitato il sito Web in precedenza, per ricordare le tue preferenze di lingua, se sei un nuovo visitatore o per facilitare in altro modo la navigazione del sito e per personalizzare la tua esperienza durante la visita nostri siti web. I cookie ci consentono di raccogliere informazioni tecniche e di navigazione, come il tipo di browser, il tempo trascorso sui nostri siti Web e le pagine visitate. I cookie possono migliorare la tua esperienza online salvando le tue preferenze mentre visiti un determinato sito web. Nel complesso, i cookie ci aiutano a fornirti un sito Web migliore consentendoci di monitorare quali pagine trovi utili e quali no. Un cookie non ci consente in alcun modo di accedere al tuo dispositivo o a qualsiasi informazione su di te, a parte i dati che scegli di condividere con noi.",
    "lbl_cookies_text6": "Che tipo di cookie utilizziamo?",
    "lbl_cookies_text7": "In generale, i cookie utilizzati nel sito Web possono essere suddivisi nelle seguenti categorie:",
    "lbl_cookies_text8": "Cookie di sessione:",
    "lbl_cookies_text9": "Questi cookie vengono utilizzati `durante la sessione` ogni volta che visiti e poi scadono quando esci da un sito Web o poco dopo: non vengono memorizzati sul tuo dispositivo in modo permanente, non contengono dati personali e aiutano a ridurre al minimo la necessità di trasferire dati personali attraverso Internet. Questi cookie possono essere cancellati o puoi rifiutare di consentirne l'uso, ma ciò potrebbe ostacolare le prestazioni e la tua esperienza nell'utilizzo dei siti web. Questi cookie prendono anche timestamp che registrano quando accedi a un sito Web e quando esci da un sito Web.",
    "lbl_cookies_text10": "Cookie di tracciamento:",
    "lbl_cookies_text11": "Questi cookie ci consentono di riconoscere i visitatori abituali dei nostri siti web. Abbinando un identificatore anonimo generato casualmente, un cookie di tracciamento tiene traccia della provenienza di un utente dei nostri siti Web, quale motore di ricerca potrebbe aver utilizzato, su quale collegamento ha fatto clic, quale parola chiave ha utilizzato e dove si trovava nel mondo quando hanno avuto accesso a un sito web. Monitorando questi dati, possiamo apportare miglioramenti ai nostri siti web.",
    "lbl_cookies_text12": "Cookie persistenti:",
    "lbl_cookies_text13": "Questo tipo di cookie viene salvato sul tuo Dispositivo per un periodo determinato (a volte per poche ore, a volte per un anno o più) e non viene eliminato alla chiusura del browser. I cookie persistenti vengono utilizzati laddove abbiamo bisogno di ricordare chi sei per più di una sessione di navigazione. Ad esempio, questo tipo di cookie può essere utilizzato per memorizzare le tue preferenze, in modo che vengano ricordate alla successiva visita a un sito web.",
    "lbl_cookies_text14": "Cookie prestazionali o analitici:",
    "lbl_cookies_text15": "I cookie per le prestazioni vengono utilizzati per analizzare come vengono utilizzati i siti Web e per monitorarne le prestazioni, il che ci consente di migliorare la tua esperienza nell'utilizzo dei siti Web. Questi cookie ci aiutano a personalizzare il contenuto del",
    "lbl_cookies_text16": "siti web",
    "lbl_cookies_text17": "per riflettere ciò che gli utenti del sito web trovano più interessante e per identificare quando sorgono problemi tecnici con i siti web. Potremmo anche utilizzare questi dati per compilare report che ci aiutino ad analizzare come vengono utilizzati i siti Web, quali sono i problemi più comuni e come possiamo migliorare i siti Web.",
    "lbl_cookies_text18": "I cookie possono essere bloccati?",
    "lbl_cookies_text19": "Si può scegliere di accettare o rifiutare i cookies. La maggior parte dei browser Web accetta automaticamente i cookie, ma di solito è possibile modificare le impostazioni del browser per rifiutare i cookie, se si preferisce. Se preferisci non accettare i cookie, la maggior parte dei browser ti consentirà di: (i) modificare le impostazioni del browser per avvisarti quando ricevi un cookie, il che ti consente di scegliere se accettarlo o meno; (ii) disabilitare i cookie esistenti; o (iii) per impostare il browser in modo da rifiutare automaticamente qualsiasi cookie. Tuttavia, tieni presente che se disabiliti o rifiuti i cookie, alcune funzionalità e servizi sui nostri siti Web potrebbero non funzionare correttamente perché potremmo non essere in grado di riconoscerti e associarti ai tuoi account. Inoltre, le offerte che forniamo quando ci visiti potrebbero non essere pertinenti per te o adattate ai tuoi interessi.",
    "lbl_cookies_text20": "Istruzioni per bloccare o consentire i cookie nei software di navigazione Web più diffusi Internet Explorer 7 e 8 Firefox Google Chrome Apple Safari AboutCookies.org",
    "lbl_linking": "Collegamento",
    "lbl_linking_text": "Potremmo collegarci ad altri siti Web che non sono sotto il nostro controllo. Una volta che hai lasciato il nostro sito Web, non possiamo essere responsabili della protezione e della privacy delle informazioni fornite. È necessario prestare attenzione e consultare l'informativa sulla privacy applicabile al sito Web in questione.",
    "lbl_yourRights": "I tuoi diritti",
    "lbl_yourRights_text": "Puoi richiedere una copia di qualsiasi informazione personale che conserviamo su di te. Potrebbe essere dovuta una piccola tassa. Puoi farlo scrivendoci all'indirizzo dataprotection@eaton.com",
    "lbl_yourRights_text1": "In alcune circostanze, potresti avere il diritto di ricevere i tuoi dati personali in un formato strutturato, di uso comune e leggibile da dispositivo automatico.",
    "lbl_yourRights_text2": "Se ritieni che qualsiasi informazione che abbiamo su di te sia errata o incompleta, ti preghiamo di scriverci o inviarci un'e-mail il prima possibile. Correggeremo o aggiorneremo qualsiasi informazione, a seconda dei casi, il prima possibile.",
    "lbl_yourRights_text3": "Hai il diritto di richiederci di limitare il nostro trattamento dei tuoi dati personali in alcune circostanze in cui:",
    "lbl_yourRights_text4": "contesti l'accuratezza dei dati personali che elaboriamo su di te;",
    "lbl_yourRights_text5": "il nostro trattamento dei tuoi dati è illegale;",
    "lbl_yourRights_text6": "non abbiamo più bisogno dei dati personali per le finalità per le quali li stiamo trattando, ma tu richiedi i dati personali per l'accertamento, l'esercizio o la difesa di azioni legali;",
    "lbl_yourRights_text7": "si contesta se i motivi legittimi del responsabile del trattamento prevalgano su quelli dell'interessato.",
    "lbl_yourRights_text8": "In caso di domande, commenti o suggerimenti sul nostro utilizzo dei dati personali, scrivere a dataprotection@eaton.com",
    "lbl_yourRights_text9": "Hai anche il diritto di presentare un reclamo all'Ufficio del Commissario per le informazioni se non sei soddisfatto del nostro trattamento dei tuoi dati personali.",
    "lbl_noticeToCalfrniaResidents": "Avviso per i residenti in California",
    "lbl_noticeToCalfrniaResidents_text": "Questa sezione si applica ai residenti in California.",
    "lbl_noticeToCalfrniaResidents_text1": "Come descritto nella sezione `Quali informazioni raccogliamo?`, potremmo raccogliere informazioni personali tra cui identificatori, informazioni commerciali, informazioni su Internet o altre attività di rete, informazioni sulla geolocalizzazione, informazioni professionali o relative all'impiego e deduzioni tratte da una qualsiasi delle informazioni sopra identificate per creare un profilo su un consumatore.",
    "lbl_noticeToCalfrniaResidents_text2": "Come descritto nella sezione `Con chi condividiamo le tue informazioni?`, le informazioni personali che raccogliamo dai consumatori potrebbero essere condivise per scopi commerciali con terze parti. Potremmo aver divulgato tutte le categorie di informazioni personali sopra elencate, in base al caso d'uso, per scopi commerciali negli ultimi 12 mesi.",
    "lbl_noticeToCalfrniaResidents_text3": "Non vendiamo le tue informazioni personali.",
    "lbl_noticeToCalfrniaResidents_text4": "In qualità di residente in California, hai determinati diritti, soggetti a limitazioni legali ed eccezioni applicabili, in merito alla raccolta, all'uso e alla condivisione delle tue informazioni personali. Quelli giusti sono in particolare:",
    "lbl_noticeToCalfrniaResidents_text5": "Il diritto di sapere",
    "lbl_noticeToCalfrniaResidents_text6": "Hai il diritto di richiedere informazioni sulle categorie di informazioni personali che abbiamo raccolto su di te, le categorie di fonti da cui abbiamo raccolto le informazioni personali, gli scopi per la raccolta delle informazioni personali, le categorie di terze parti con cui abbiamo condiviso le tue informazioni personali e lo scopo per il quale abbiamo condiviso le tue informazioni personali (`Rapporto sulle categorie`). Puoi anche richiedere informazioni sulle parti specifiche di informazioni personali che abbiamo raccolto su di te (`Rapporto su parti specifiche`). Si prega di specificare quale richiesta si desidera eseguire.",
    "lbl_noticeToCalfrniaResidents_text7": "Il diritto di cancellare",
    "lbl_noticeToCalfrniaResidents_text8": "Hai il diritto di richiedere che cancelliamo le informazioni personali che abbiamo raccolto da te.",
    "lbl_noticeToCalfrniaResidents_text9": "Il diritto all'opt-out",
    "lbl_noticeToCalfrniaResidents_text10": "Hai il diritto di rinunciare alla vendita delle tue informazioni personali.",
    "lbl_noticeToCalfrniaResidents_text11": "In conformità con la legge applicabile, non discrimineremo l'utente per l'esercizio di tali diritti. Puoi utilizzare un agente autorizzato per esercitare i tuoi diritti per tuo conto. Se stai effettuando una delle richieste di cui sopra tramite un agente autorizzato, ti chiederemo un'autorizzazione scritta e cercheremo di verificare come descritto sopra o accetteremo una procura legale ai sensi del codice di successione della California all'agente autorizzato.",
    "lbl_noticeToCalfrniaResidents_text12": "Puoi esercitare il tuo diritto in qualsiasi momento contattandoci inviando una mail a",
    "lbl_noticeToCalfrniaResidents_text13": "chiamando +1-800-386-1911 o utilizzando questo",
    "lbl_noticeToCalfrniaResidents_text14": "modulo in linea",
    "lbl_noticeToCalfrniaResidents_text15": "Per esercitare i tuoi diritti, avremo bisogno di ottenere informazioni per localizzarti nei nostri archivi o verificare la tua identità a seconda della natura della richiesta. Se stai inviando una richiesta per conto di un nucleo familiare, dovremo verificare ogni membro del nucleo familiare secondo le modalità indicate in questa sezione. Pertanto, in base alla richiesta, potremmo contattarti per ulteriori informazioni.",
    "lbl_noticeToCalfrniaResidents_text16": "Risponderemo a una richiesta di rinuncia entro 15 giorni. Risponderemo alle Richieste di Eliminazione e Richieste di Conoscenza entro 45 giorni, a meno che non ci serva più tempo, nel qual caso ti informeremo e potremmo impiegare fino a 90 giorni in totale per rispondere alla tua richiesta.",
    "lbl_viewSubEstate": "Visualizza sottoimmobile",
    "lbl_installers": "Installatori",
    "lbl_managedPanels": "Pannelli non gestiti",
    "lbl_days": "Giorni",
    "lbl_email": "E-mail",
    "lbl_company": "Azienda",
    "lbl_removeInstaller": "Rimuovi programma di installazione",
    "lbl_confirm": "Confermare",
    "msg_deleteAdminConfirmation": "Sei sicuro di voler rimuovere",
    "lbl_editDetails": "Modifica i dettagli",
    "msg_adminAdded": "L'amministratore ha modificato correttamente",
    "msg_adminDeleted": "Amministratore eliminato con successo",
    "msg_errorDeleteAdmin": "Errore durante l'eliminazione dell'amministratore",
    "lbl_addAdmin": "Aggiungi amministratore",
    "lbl_info": "Informazioni",
    "lbl_noOfPanels": "Numero di pannelli",
    "lbl_dateRegistered": "Data di registrazione",
    "lbl_cmpnyAddress": "indirizzo aziendale",
    "lbl_addressDetails": "dettagli dell'indirizzo",
    "txt_addressDetails": "Per registrarti per un account aziendale, fornisci i dettagli dell'indirizzo della tua azienda.",
    "lbl_contactDetails": "Dettagli del contatto",
    "txt_contactDetails": "Per registrarti per un account aziendale, fornisci i dettagli di contatto della tua azienda.",
    "lbl_phoneType": "Tipo di telefono",
    "msg_alreadyAccnt": "Esiste già un account per questa email.",
    "txt_invalidEmail": "e-mail non valido",
    "lbl_createAccnt": "Creare un account",
    "lbl_createAccntDetails": "Per registrarti per un account aziendale, inserisci le informazioni richieste di seguito.",
    "msg_pwdValidation": "Minimo 8 caratteri, almeno 1 lettera maiuscola, 1 lettera minuscola, 1 numero e 1 carattere speciale",
    "lbl_createPwd": "Crea una password",
    "txt_pwdLength": "La password deve essere lunga almeno 8 caratteri, contenere almeno un carattere maiuscolo, un numero e un carattere speciale.",
    "lbl_licenseAgreememnt": "Contratto di licenza",
    "lbl_licenseAgreememntDetails": "Per registrarti per un account aziendale, dovrai leggere e accettare i Termini e Condizioni.",
    "lbl_accntActivation": "attivazione dell'account",
    "msg_accntActivation": "Congratulazioni, sei stato registrato con il login di",
    "lbl_accntActivationEmailSent": "Ti abbiamo inviato un'e-mail di attivazione. Fai clic sul collegamento nel messaggio per attivare il tuo account.",
    "lbl_notReceive": "Non hai ricevuto l'e-mail?",
    "lbl_sendAgain": "Invia di nuovo",
    "lbl_cnfrmPwd": "Conferma password",
    "msg_accntExists": "Esiste già un account per questa email.",
    "lbl_forgotPwd": "Se l'ID utente esiste, verrà inviato un collegamento per reimpostare la password",
    "lbl_RmtSvsMsg": "Impossibile procedere, la centrale è in modalità installatore",
    "lbl_planned": "PIANIFICATO",
    "lbl_noPlannedService": "Nessun rapporto di servizio pianificato",
    "lbl_rmtServInitiationMsg": "È stato richiesto un servizio remoto manuale, attendere 5-10 minuti per il completamento del rapporto.",
    "lbl_okay": "Va bene",
    "lbl_pwdReset": "Reimpostazione della password",
    "lbl_setPwd": "Impostare la password",
    "lbl_pwdResetMsg": "Per reimpostare la password, inserisci una nuova password qui sotto.",
    "lbl_pwdResetSuccess": "Reimpostazione della password riuscita",
    "lbl_pwdResetSuccessMsg": "La tua password è stata reimpostata con successo. Accedi all'app utilizzando la password aggiornata.",
    "lbl_pwdResetErrMsg": "Reimpostazione della password già richiesta negli ultimi 15 minuti.",
    "btn_download_notifications": "Scarica la cronologia dei pannelli",
    "btn_download_logs": "Scarica notifiche",
    "lbl_userAccDelete": "Cancellazione dell'account utente",
    "lbl_userAccDeleteConfirm": "Vuoi confermare l'eliminazione dell'account?",
    "lbl_userAccDeleteMsg": "Per richiedere la rimozione di un account utente, inserisci l'indirizzo email dell'account di seguito e quindi il pulsante di invio.",
    "lbl_userAccDeleteSubmitMsg": "La tua richiesta di cancellazione dell'account utente è stata inviata. Se l'ID utente esiste, verrà inviato un collegamento per reimpostare la password",
    "btn_rmvLogo": "Rimuovi logo",
    "btn_cgnLogo": "Cambia logo",
    "lbl_endUserMsg": "Le informazioni fornite qui verranno visualizzate sia nell'applicazione mobile che nei rapporti di assistenza remota",
    "lbl_dwnPanelLog": "Scarica i log del pannello",
    "lbl_estatelessPanels": "Pannelli non all'interno di una tenuta",
    "lbl_siteComments": "Commenti",
    "mainHeadingforsystemConfig": "Intestazione",
    "lbl_EatonGCMorFCMurl": "URL Eaton GCM o FCM",
    "lbl_EatonapiKey": "Chiave API Eaton",
    "lbl_eatonApplepassword": "Password Eaton Apple",
    "lbl_applePushNotification": "Apple utilizza la notifica push di produzione"
};
export default resources;