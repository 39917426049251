/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useEffect } from 'react';
import {
    Button,
    Checkbox,
    IconButton,
    Paper,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme,
    Snackbar,
    Chip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import Switch from '@mui/material/Switch';
import './background.css';
import { useSelector } from 'react-redux';
import PanelAccessAppbar from '../../components/SpecificPanelAccessAppbar/PanelAccessAppbar';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { useParams } from 'react-router';
import { fetchInstallerEmailAlerts, fetchPanelARCSettings, fetchSpecificPanelAndEstate, fetchUserEmailAlerts, fetchZoneMonitoringList } from '../../redux/actions/specificPanelActions';
import UserAccessModal from '../../components/UserAccessModal/UserAccessModal';
import ARCAccessModal from '../../components/ARCAccessModal/ARCEditModal';
import UserEmailAlertsModal from '../../components/UserEmailAlertsModal/UserEmailAlertsModal';
import ARCAccessDisableModal from '../../components/ARCAccessModal/ARCAccessDisableModal';
import AlertsAccessModal from '../../components/AlertsAccessModal/AlertsAccessModal';
import AlertsDisableModal from '../../components/AlertsAccessModal/AlertsDisableModal';
import AddIcon from '@mui/icons-material/Add';
import { ListItemTag, Spacer } from '@brightlayer-ui/react-components';
import RemoteServiceModal from '../../components/RemoteServicingModal/RemoteServicingModal';
import { Maintenance } from '@brightlayer-ui/icons-mui';
import { Delete } from '@mui/icons-material';
import EditUserEmailAlertsModal from '../../components/EditUserEmailAlerts/EditUserEmailAlertsModal';
import DeleteUserEmailAlertsModal from '../../components/DeleteUserEmailAlerts/DeleteUserEmailAlertsModal';
import { SiaEmailSettingsProps } from '../../components/UserEmailAlertsModal/UserEmailAlertsTypes';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Report from '@mui/icons-material/Report';
import { Stack } from '@mui/system';
import RemoteServicingEmailDestinationsModal from '../../components/RemoteServicingEmailDestinationsModal/RemoteServicingEmailDestinationModal';
import { useTranslation } from 'react-i18next';
import { getCurrentUserDetails } from '../../redux/actions/accountUsersActions';
import { fetchARDscheduler, fetchRemoteServicingEmailDestinations, getRemoteServicing, performRemoteServicing, setRemoteServicingEnabled } from '../../redux/actions/remoteServicingActions';
import RemoteServicingMonitoringModal from '../../components/RemoteServicingActivityMonitoring/RemoteServicingActivityMonitoringModal';
import { ZoneMonitoringProps } from '../../components/RemoteServicingActivityMonitoring/ZonesMoniteringListTypes';
import InfoIcon from '@mui/icons-material/Info';
import EmailAddressVerifyModal from '../../components/RemoteServicingEmailDestinationsModal/EmailAddressVerificationModal';
import DeleteEmailDestinationsModal from '../../components/RemoteServicingEmailDestinationsModal/DeleteEmailDestinationsModal';
import PeopleIcon from '@mui/icons-material/People';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
}));

export function PanelAccess(): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const ms = useMediaQuery(theme.breakpoints.up('md'));
    const dispatch = useTypedDispatch();
    const { id } = useParams();
    const [openModal, setOpenModal] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [status, setStatus] = React.useState(false);
    const [installerEmailAlertsStatus, setInstallerEmailAlertsStatus] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    const [flagForInstallerEmailAlertsDisable, setFlagForInstallerEmailAlertsDisable] = React.useState(false);
    const [modalCancelClick, setModalCancelClick] = React.useState(0);
    const [openArcDisableModal, setOpenArcDisableModal] = React.useState(false);
    const [arcModalEnableTrigger, setArcModalEnableTrigger] = React.useState(false);
    const [alertsModalOpen, setAlertsModalOpen] = React.useState(false);
    const [deleteStatusCode, setDeleteStatusCode] = React.useState(0);
    const [EmailAlertsModalOpen, setEmailAlertsModalOpen] = React.useState(false);
    const [alertsDisableModalOpen, setAlertsDisableModalOpen] = React.useState(false);
    const [remoteServicingModalOpen, setRemoteServicingModalOpen] = React.useState(false);
    const [editUserEmailAlertsModalOpen, setEditUserEmailAlertsModalOpen] = React.useState(false);
    const [deleteUserEmailAlertsModalOpen, setDeleteUserEmailAlertsModalOpen] = React.useState(false);
    const [alertsSnackOpen, setAlertsSnackOpen] = React.useState(false);
    const [alertsmessage, setAlertsMessage] = React.useState('');
    const [deleteUserId, setDeleteUserId] = React.useState<number>(0);
    const [editUserId, setEditUserId] = React.useState<number>(0);
    const [editUserIndex, setEditUserIndex] = React.useState<number>();
    const [siaEmailSetting, setSiaEmailSetting] = React.useState<SiaEmailSettingsProps>()
    const [refreshArcModal, setRefreshArcModal] = React.useState<boolean>(false)
    const [remoteServicingOpen, setRemoteServicingOpen] = React.useState<boolean>(false)
    const [isMobile, setIsMobile] = React.useState<boolean>(false)
    const [isInstallerEmailAlertsEdit, setIsInstallerEmailAlertsEdit] = React.useState<boolean>(false)
    const [isARCEdit, setIsARCEdit] = React.useState<boolean>(false)
    const [counter, setCounter] = React.useState<number>(0)
    const [count, setCount] = React.useState<number>(0)
    const [openRemoteMsg, setOpenRemoteMsg] = React.useState<boolean>(false)
    const [enableMsg, setEnableMsg] = React.useState(false)
    const [openRemoteEmailModal, setOpenRemoteEmailModal] = React.useState(false)
    const [openRemoteMonitoringModal, setOpenRemoteMonitoringModal] = React.useState(false)
    const [openEmailVerification, setOpenEmailVerification] = React.useState(false)
    const [openDeleteEmailDestination, setOpenDeleteEmailDestination] = React.useState(false)
    const [typeOfARDModal, setTypeOfARDModal] = React.useState('')
    const [typeOfDestinationsModal, setTypeOfDestinationsModal] = React.useState('')
    const [emailUser, setEmailUser] = React.useState<any>({})
    const { t } = useTranslation();
    const { specificPanel, panelARCSettings, panelARCCountryCodes, addUserEmailAlertsStatus, installerEmailAlertsStatusValue, installerEmailAlertsDisableStatus, userEmailAlerts, zonesList } = useSelector(
        (state: any) => state.specificPanelAndEstateReducer
    );
    const { currentUserDetails } = useSelector((state: any) => state.accountUsersReducer)
    const { performRemoteServicingStatus, enabled, schedulerStatus, emailDestinations } = useSelector((state: any) => state.remoteServicingReducer)
    const countrycode = panelARCCountryCodes?.filter((codes: any) => codes.code === panelARCSettings.arcCode);

    const handleRemoteServicingEdit = async() => {
        setRemoteServicingModalOpen(true)
        await dispatch(fetchARDscheduler(specificPanel?.panelId))
        setTypeOfARDModal('edit')
    }

    const handleRemoteServicing = async(event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setRemoteServicingModalOpen(true)
            setTypeOfARDModal('enable')
             setEnableMsg(true)
        }
        else {
           await dispatch(setRemoteServicingEnabled(specificPanel?.panelId,{enabled:false}))
           setTypeOfARDModal('enable')
           setEnableMsg(false)
           setCount((prev) => prev+1)
        }
    }

    const handleRemoteServicingemaildestinations = () => {
        setTypeOfDestinationsModal('add')
        setOpenRemoteEmailModal(true)
    }

    const handleEditRemoteServicingemaildestinations = (users:any) => {
        setTypeOfDestinationsModal('edit')
        setEmailUser(users)
        if(users.verified === '0'){
            setOpenEmailVerification(true)
        }
        else{
        setOpenRemoteEmailModal(true)
        }
    }

    const handleDeleteRemoteServicingemaildestinations = (users:any) => {
        setOpenDeleteEmailDestination(true)
        setEmailUser(users)
    }

    const handleDisableInstallerEmailAlertSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertsMessage('')
        setAlertsSnackOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleDisableInstallerEmailAlertSnackClose}>
                {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    const handleEditUserAlerts = (id: number, key: number, users: SiaEmailSettingsProps) => {
        setEditUserIndex(key)
        setEditUserId(id)
        setSiaEmailSetting(users)
        setEditUserEmailAlertsModalOpen(true)
    }
    const handleDeleteUserAlerts = (id: number) => {
        setDeleteUserId(id)
        setDeleteUserEmailAlertsModalOpen(true)
    }

    const handleAlertModal = () => {
        setAlertsModalOpen(true);
        setIsInstallerEmailAlertsEdit(true);
    };

    const handleUserEmailAlertsModal = () => {
        setEmailAlertsModalOpen(true);
    };

    const handleAlertsDisableModal = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setAlertsModalOpen(true);
            setIsInstallerEmailAlertsEdit(false);
        }
        else
            setAlertsDisableModalOpen(true);
    };

    const handleOpen = (): void => {
        setOpenModal(true);
        setIsARCEdit(true)
    };

    React.useEffect(() => {
        const getPanelData = async () => {
            await dispatch(getCurrentUserDetails());
            await dispatch(fetchSpecificPanelAndEstate(Number(id)));
            //await dispatch(fetchPanelARCSettings(Number(id)));
            await dispatch(fetchInstallerEmailAlerts(specificPanel?.panelId))
            await dispatch(getRemoteServicing(specificPanel?.panelId))
            await dispatch(fetchUserEmailAlerts(specificPanel?.panelId))
            //await dispatch(performRemoteServicing(specificPanel?.panelId,{enabled:true}))
        };
        getPanelData();
        const fetchArd = async() => {
            await dispatch(fetchZoneMonitoringList(specificPanel?.panelId))
            await dispatch(fetchRemoteServicingEmailDestinations(specificPanel?.panelId))
            await dispatch(fetchARDscheduler(specificPanel?.panelId))
        }
        fetchArd();
        if (installerEmailAlertsDisableStatus !== 0 && flagForInstallerEmailAlertsDisable) {
            setAlertsSnackOpen(true)
            if (installerEmailAlertsDisableStatus === 200) {
                setAlertsMessage(`${t('msg_emailAlert_disabled')}`)
            } else if (installerEmailAlertsDisableStatus === 404) {
                setAlertsMessage(`${t('lbl_somethingWrong')}`)
            }
            setFlagForInstallerEmailAlertsDisable(false)
        }
        if (counter !== 0) {
            setAlertsSnackOpen(true)
            if (addUserEmailAlertsStatus === 204) {
                setAlertsMessage(`${t('msg_usr_emailDeleted')}`)
            }
            else {
                setAlertsMessage(`${t('lbl_someError')}`)
            }
            setCounter(0)
        }

        if (count !== 0 ) {
            setAlertsSnackOpen(true)
        if(typeOfARDModal === 'enable'){
            if(performRemoteServicingStatus === 200){
                if (enableMsg) {
                    setAlertsMessage(`Remote servicing enabled`)
                }
                else {
                    setAlertsMessage(`Remote servicing disabled`)
                }
            }
            else if(performRemoteServicingStatus === 400 || performRemoteServicingStatus === 401 ){
                setAlertsMessage(`Unauthorized user`)
            }
            else if(performRemoteServicingStatus === 404){
                setAlertsMessage(`Panel software does not support remote servicing`)
            }
            else{
                setAlertsMessage(`Something went wrong`)
            }
        }

            else{
                    if (schedulerStatus === 200) {
                    setAlertsMessage(`Scheduled successfully`);
                } else if (schedulerStatus === 500) {
                    setAlertsMessage(`${t('msg_internal_error')}`);
                } else if (schedulerStatus === 400 || schedulerStatus === 404 ) {
                    setAlertsMessage(`${t('lbl_unauthorizedUser')}`);
                } else {
                    setAlertsMessage(`${t('lbl_someError')}`);
                }
            }
           setCount(0)
        }

        setRemoteServicingOpen(enabled?.enabled)
        setChecked(specificPanel?.userAccessEnabled);
        setArcModalEnableTrigger(false);
        setInstallerEmailAlertsStatus(installerEmailAlertsStatusValue);
    }, [enabled?.enabled, counter, count, modalCancelClick, installerEmailAlertsStatusValue, installerEmailAlertsDisableStatus, flagForInstallerEmailAlertsDisable, openModal === false]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        setStatus(event.target.checked);
        setOpenRemoteMsg(false)
        setOpen(true);
    };

    const handleArcAccessChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setOpenModal(true)
            setIsARCEdit(false)
            setRefreshArcModal(true)
        }
        else {
            setOpenArcDisableModal(true)
        }
    };

    const handleRemoteServicingActivityMonitoringModal = () => {
        setOpenRemoteMonitoringModal(true)
    }
    

    return (
        <>
            <div className="main-div">
            <div style={{maxHeight:'100vh',overflow:'auto'}}>
                <PanelAccessAppbar />
                <Box sx={{ flexGrow: 1, marginLeft: md ? 1 : 4, marginRight: md ? 1 : 4, marginTop: md ? 2 : 4 }}>
                    <Grid container spacing={3}>
                        {(currentUserDetails?.userType === 'INSTALLER' ||
                            currentUserDetails?.userType === 'MANAGER' ||
                            currentUserDetails?.userType === 'ENGINEER' ||
                            currentUserDetails?.userType === 'ADMIN') && (
                            <Grid item xs={12}>
                                <Item elevation={1}>
                                    <TableContainer>
                                        <Table aria-label="simple table">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell
                                                        width={md ? '50%' : '25%'}
                                                        variant="head"
                                                        style={{ color: '#1397D9' }}
                                                    >
                                                        {t('tooltip_ARC_Access')}
                                                    </TableCell>
                                                    <TableCell width={'25%'}></TableCell>
                                                    {ms && <TableCell width={'25%'}></TableCell>}
                                                    <TableCell width={'25%'} align="right">
                                                        <IconButton
                                                            sx={{ mt: -1 }}
                                                            size="small"
                                                            disabled={
                                                                (specificPanel?.status === 'CONNECTED'
                                                                    ? false
                                                                    : true) ||
                                                                (specificPanel?.arcAccessEnabled === true
                                                                    ? false
                                                                    : true)
                                                            }
                                                            onClick={handleOpen}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                        <ARCAccessModal
                                                            refreshArcModal={refreshArcModal}
                                                            setRefreshArcModal={setRefreshArcModal}
                                                            open={openModal}
                                                            setOpen={setOpenModal}
                                                            setArcModalEnableTrigger={setArcModalEnableTrigger}
                                                            isARCEdit={isARCEdit}
                                                            setIsARCEdit={setIsARCEdit}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell width={'25%'} variant="head">
                                                        &ensp;{t('lbl_status')}
                                                    </TableCell>
                                                    <TableCell width={'25%'} align={'left'}>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                            }}
                                                        >
                                                            <Checkbox
                                                                sx={{ mt: -0.9 }}
                                                                size={'small'}
                                                                icon={<Report color="action" />}
                                                                disabled
                                                                checkedIcon={<Report sx={{color:'#005EAB'}} />}
                                                                checked={specificPanel?.arcAccessEnabled === true}
                                                            />
                                                            &ensp;
                                                            {specificPanel?.arcAccessEnabled === true ? (
                                                                <Typography sx={{ color: '#424E54' }}>
                                                                    {t('lbl_enabled')}
                                                                </Typography>
                                                            ) : (
                                                                <Typography sx={{ color: '#424E54' }}>
                                                                    {t('lbl_disabled')}
                                                                </Typography>
                                                            )}
                                                        </div>
                                                    </TableCell>
                                                    {ms && <TableCell width={'25%'}></TableCell>}
                                                    <TableCell width={'25%'} align="right">
                                                        <Switch
                                                            disabled={specificPanel.status === 'DISCONNECTED'}
                                                            onChange={handleArcAccessChange}
                                                            checked={specificPanel?.arcAccessEnabled}
                                                        />
                                                        <ARCAccessDisableModal
                                                            open={openArcDisableModal}
                                                            setOpen={setOpenArcDisableModal}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Item>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Item elevation={1}>
                                <TableContainer>
                                    <Table aria-label="simple table">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell
                                                    width={md ? '50%' : '25%'}
                                                    variant="head"
                                                    style={{ color: '#1397D9' }}
                                                >
                                                    {t('tooltip_remoteServicing')}
                                                </TableCell>
                                                <TableCell width={'25%'}></TableCell>
                                                {ms && <TableCell width={'25%'}></TableCell>}
                                                <TableCell width={'25%'} align="right">
                                                    <IconButton
                                                        onClick={handleRemoteServicingEdit}
                                                        disabled={enabled.enabled === false || specificPanel?.status === 'DISCONNECTED'}
                                                        sx={{ mt: -1 }}
                                                        size="small"
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <RemoteServiceModal
                                                        type={typeOfARDModal}
                                                        counter={count}
                                                        setCounter={setCount}
                                                        open={remoteServicingModalOpen}
                                                        setOpen={setRemoteServicingModalOpen}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell width={'25%'} variant="head">
                                                    &ensp;{t('lbl_status')}
                                                </TableCell>
                                                <TableCell width={'25%'} align="left">
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                        }}
                                                    >
                                                        {/* <Checkbox
                                                            sx={{ mt: -0.9 }}
                                                            size={'small'}
                                                            icon={<Maintenance color="error" />}
                                                            disabled
                                                            checkedIcon={<Maintenance color="success" />}
                                                            checked={enabled?.enabled}
                                                        /> */}
                                                        {enabled?.enabled === true ? (
                                                            <Maintenance
                                                                sx={{ ml: 1.1, mt: 0.3, color:specificPanel?.status === 'DISCONNECTED' ? "#727E84":'#005EAB' }}
                                                                fontSize="small"
                                                                color={specificPanel?.status === 'DISCONNECTED' ? "disabled":"success"}
                                                            />
                                                        ) : (
                                                            <Maintenance
                                                                sx={{ ml: 1.1, mt: 0.3 }}
                                                                fontSize="small"
                                                                color={specificPanel?.status === 'DISCONNECTED' ? "disabled":"action"}
                                                            />
                                                        )}
                                                        &ensp;&ensp;
                                                        {enabled?.enabled === true ? (
                                                            <Typography sx={{ color: '#424E54' }}>
                                                                {t('lbl_enabled')}
                                                            </Typography>
                                                        ) : (
                                                            <Typography sx={{ color: '#424E54' }}>
                                                                {t('lbl_disabled')}
                                                            </Typography>
                                                        )}
                                                    </div>
                                                </TableCell>
                                                {ms && <TableCell width={'25%'}></TableCell>}
                                                <TableCell width={'25%'} align="right">
                                                    <Switch
                                                        disabled={specificPanel?.status === 'DISCONNECTED'}
                                                        onChange={handleRemoteServicing}
                                                        checked={remoteServicingOpen === true}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Item>
                        </Grid>
                        {enabled?.enabled === true && (
                            <Grid item xs={12}>
                                <Item elevation={1}>
                                    <TableContainer>
                                        <Table aria-label="simple table">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell
                                                        width={md ? '50%' : '25%'}
                                                        variant="head"
                                                        style={{ color: '#1397D9' }}
                                                    >
                                                        Remote Servicing - Activity monitoring
                                                    </TableCell>
                                                    <TableCell width={md? '50%' :'75%'}></TableCell>
                                                </TableRow>
                                                {
                                                    <TableRow>
                                                        <TableCell align="left">
                                                            {
                                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                    <input
                                                                        readOnly
                                                                        value={'Excluded zones'}
                                                                        style={{
                                                                            border: 'none',
                                                                            outline: 'none',
                                                                            fontSize: 16,
                                                                            fontFamily: 'Open Sans',
                                                                            fontStyle: 'normal',
                                                                            fontWeight: 600,
                                                                            lineHeight: 1,
                                                                            width: md ? '70%' : '50%',
                                                                            textAlign: 'left',
                                                                        }}
                                                                    />
                                                                    &emsp;
                                                                    {md ? (
                                                                        <>&emsp;&ensp;</>
                                                                    ) : (
                                                                        <>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</>
                                                                    )}
                                                                </div>
                                                            }
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <Typography sx={{ mt: 0.5 }} variant="body2">
                                                                    <Box sx={{ flexGrow:1, width:'100%',maxHeight:200,overflow:'auto' }}>
                                                                        <Paper square elevation={0}>
                                                                        
                                                                            {zonesList.filter(
                                                                                (item: ZoneMonitoringProps) =>
                                                                                    item.activityMonitorEnabled ===
                                                                                    false
                                                                            ).length === 0 ? (
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    sx={{ mt: 0.5 }}
                                                                                >
                                                                                    No Excluded zones
                                                                                </Typography>
                                                                            ) 
                                                                            : 
                                                                            zonesList.filter(
                                                                                (item: ZoneMonitoringProps) =>
                                                                                    item.activityMonitorEnabled ===
                                                                                    false
                                                                            ).length > 6 ?
                                                                            (
                                                                                <Grid container spacing={2}>{
                                                                                zonesList
                                                                                    .filter(
                                                                                        (item: ZoneMonitoringProps) =>
                                                                                            item.activityMonitorEnabled ===
                                                                                            false
                                                                                    )
                                                                                    .map(
                                                                                        (
                                                                                            item: ZoneMonitoringProps,
                                                                                            key: number
                                                                                        ) => (
                                                                                            <Grid item key={key} xs={md?12:2}>
                                                                                            <Chip
                                                                                                
                                                                                                label={item.name}
                                                                                            />
                                                                                            </Grid>
                                                                                        )
                                                                                    )
                                                                                        }</Grid>
                                                                                )
                                                                                :
                                                                                (
                                                                                    <Stack direction={md? 'column':'row'} spacing={3}>
                                                                                    {
                                                                                    zonesList
                                                                                    .filter(
                                                                                        (item: ZoneMonitoringProps) =>
                                                                                            item.activityMonitorEnabled ===
                                                                                            false
                                                                                    )
                                                                                    .map(
                                                                                        (
                                                                                            item: ZoneMonitoringProps,
                                                                                            key: number
                                                                                        ) => (
                                                                                            
                                                                                            <Chip key={key}
                                                                                                label={item.name}
                                                                                            />
                                                                                           
                                                                                        )
                                                                                    ) 
                                                                                        }</Stack>
                                                                                )
                                                                            }
                                                                        
                                                                        </Paper>
                                                                    </Box>
                                                                </Typography>
                                                                <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                                                                <IconButton
                                                                    onClick={
                                                                        handleRemoteServicingActivityMonitoringModal
                                                                    }
                                                                    size="small"
                                                                >
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Item>
                            </Grid>
                        )}
                        <RemoteServicingMonitoringModal
                            open={openRemoteMonitoringModal}
                            setOpen={setOpenRemoteMonitoringModal}
                        />
                        {enabled?.enabled === true && (
                            <Grid item xs={12}>
                                <Item elevation={1}>
                                    <TableContainer>
                                        <Table aria-label="simple table">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell
                                                        width={md ? '50%' : '25%'}
                                                        variant="head"
                                                        style={{ color: '#1397D9' }}
                                                    >
                                                        {t('lbl_remove_destination')}
                                                    </TableCell>
                                                    <TableCell align="right" padding="normal" width={md ? '25%' : '5%'}>
                                                        {md ? (
                                                            <Button
                                                                onClick={handleRemoteServicingemaildestinations}
                                                                variant="text"
                                                                sx={{ paddingLeft: 10 }}
                                                            >
                                                                <AddIcon sx={{ fontSize: 20 }} />
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                onClick={handleRemoteServicingemaildestinations}
                                                                variant="outlined"
                                                                sx={{ fontSize: 13 }}
                                                                fullWidth
                                                            >
                                                                <AddIcon sx={{ fontSize: 15 }} />
                                                                &nbsp;&nbsp;{t('btn_addEmail')}
                                                            </Button>
                                                        )}
                                                        <RemoteServicingEmailDestinationsModal
                                                            type={typeOfDestinationsModal}
                                                            user={emailUser}
                                                            open={openRemoteEmailModal}
                                                            setOpen={setOpenRemoteEmailModal}
                                                        />
                                                        <EmailAddressVerifyModal
                                                            open={openEmailVerification}
                                                            setOpen={setOpenEmailVerification}
                                                            user={emailUser}
                                                        />
                                                        <DeleteEmailDestinationsModal
                                                            open={openDeleteEmailDestination}
                                                            setOpen={setOpenDeleteEmailDestination}
                                                            user={emailUser}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                                {emailDestinations?.message?.length > 0 &&
                                                    emailDestinations?.message.map((users: any, key: number) => (
                                                        <TableRow key={key}>
                                                            <TableCell colSpan={2} width={'100%'} align="left">
                                                                {md ? (
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                        }}
                                                                    >
                                                                        <Typography variant="subtitle1">
                                                                            {users.emailAddress}
                                                                        </Typography>
                                                                        &emsp;
                                                                        {md ? (
                                                                            <></>
                                                                        ) : (
                                                                            <>
                                                                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                                                                            </>
                                                                        )}
                                                                        <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                                                                        {users?.verified === '0' && (
                                                                            <IconButton disabled>
                                                                                <InfoIcon color="error" />
                                                                            </IconButton>
                                                                        )}
                                                                        <IconButton
                                                                            size="small"
                                                                            onClick={() =>
                                                                                handleEditRemoteServicingemaildestinations(
                                                                                    users
                                                                                )
                                                                            }
                                                                        >
                                                                            <ChevronRightIcon color="action" />
                                                                        </IconButton>
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                        }}
                                                                    >
                                                                        <input
                                                                            readOnly
                                                                            value={users.emailAddress}
                                                                            style={{
                                                                                border: 'none',
                                                                                outline: 'none',
                                                                                fontSize: 16,
                                                                                fontFamily: 'Open Sans',
                                                                                fontStyle: 'normal',
                                                                                fontWeight: 600,
                                                                                lineHeight: 1,
                                                                                width: md ? '30%' : '20%',
                                                                                textAlign: 'left',
                                                                            }}
                                                                        />
                                                                        &emsp;
                                                                        {md ? (
                                                                            <></>
                                                                        ) : (
                                                                            <>
                                                                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                                                                            </>
                                                                        )}
                                                                        <Typography sx={{ mt: 0.5 }} variant="body2">
                                                                            <Stack direction={'row'} spacing={1}>
                                                                                {users.verified === 1 ? (
                                                                                    [
                                                                                        users.sendGreen === 1
                                                                                            ? 'GREEN'
                                                                                            : null,
                                                                                        users.sendAmber === 1
                                                                                            ? 'AMBER'
                                                                                            : null,
                                                                                        users.sendRed === 1
                                                                                            ? 'RED'
                                                                                            : null,
                                                                                        users.sendFailed === 1
                                                                                            ? 'FAILED'
                                                                                            : null,
                                                                                    ]
                                                                                        .filter((item) => item !== null)
                                                                                        .map(
                                                                                            (
                                                                                                item: any,
                                                                                                key: number
                                                                                            ) => (
                                                                                                <Chip
                                                                                                    key={key}
                                                                                                    label={item}
                                                                                                />
                                                                                            )
                                                                                        )
                                                                                ) : (
                                                                                    <ListItemTag nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                                                        label={
                                                                                            'EMAIL ADDRESS PENDING VERIFICATION'
                                                                                        }
                                                                                        backgroundColor="#CA3C3D"
                                                                                    />
                                                                                )}
                                                                            </Stack>
                                                                        </Typography>
                                                                        <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                                                                        <IconButton
                                                                            size="small"
                                                                            onClick={() =>
                                                                                handleEditRemoteServicingemaildestinations(
                                                                                    users
                                                                                )
                                                                            }
                                                                        >
                                                                            <EditIcon />
                                                                        </IconButton>
                                                                        <IconButton
                                                                            size="small"
                                                                            onClick={() =>
                                                                                handleDeleteRemoteServicingemaildestinations(
                                                                                    users
                                                                                )
                                                                            }
                                                                        >
                                                                            <Delete />
                                                                        </IconButton>
                                                                    </div>
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                <TableRow>
                                                    <TableCell align="center" colSpan={2}>
                                                        <Typography variant="body2">
                                                            {t('lbl_emailDestinationAdd')}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Item>
                            </Grid>
                        )}
                        {(currentUserDetails?.userType === 'INSTALLER' ||
                            currentUserDetails?.userType === 'MANAGER' ||
                            currentUserDetails?.userType === 'ADMIN') && (
                            <Grid item xs={12}>
                                <Item elevation={1}>
                                    <TableContainer>
                                        <Table aria-label="simple table">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell
                                                        width={md ? '50%' : '25%'}
                                                        variant="head"
                                                        style={{ color: '#1397D9' }}
                                                    >
                                                        {t('tooltip_installerEmail_alerts')}
                                                    </TableCell>
                                                    <TableCell width={'25%'}></TableCell>
                                                    {ms && <TableCell width={'25%'}></TableCell>}
                                                    <TableCell width={'25%'} align="right">
                                                        <IconButton
                                                            disabled={!installerEmailAlertsStatus}
                                                            onClick={handleAlertModal}
                                                            sx={{ mt: -1 }}
                                                            size="small"
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                        <AlertsAccessModal
                                                            setInstallerEmailAlertsStatus={
                                                                setInstallerEmailAlertsStatus
                                                            }
                                                            installerEmailAlertsStatus={installerEmailAlertsStatus}
                                                            panelId={specificPanel?.panelId}
                                                            open={alertsModalOpen}
                                                            setOpen={setAlertsModalOpen}
                                                            isEdit={isInstallerEmailAlertsEdit}
                                                            setIsEdit={setIsInstallerEmailAlertsEdit}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell width={'25%'} variant="head">
                                                        &ensp;{t('lbl_status')}
                                                    </TableCell>
                                                    <TableCell width={'25%'} align={'left'}>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                            }}
                                                        >
                                                            <Checkbox
                                                                sx={{ mt: -0.9 }}
                                                                size={'small'}
                                                                icon={<NotificationsActiveIcon color="action" />}
                                                                disabled
                                                                checkedIcon={<NotificationsActiveIcon sx={{color:'#005EAB'}} />}
                                                                checked={installerEmailAlertsStatus}
                                                            />
                                                            &ensp;
                                                            {installerEmailAlertsStatus ? (
                                                                <Typography sx={{ color: '#424E54' }}>
                                                                    {t('lbl_enabled')}
                                                                </Typography>
                                                            ) : (
                                                                <Typography sx={{ color: '#424E54' }}>
                                                                    {t('lbl_disabled')}
                                                                </Typography>
                                                            )}
                                                        </div>
                                                    </TableCell>
                                                    {ms && <TableCell width={'25%'}></TableCell>}
                                                    <TableCell width={'25%'} align="right">
                                                        <Switch
                                                            checked={installerEmailAlertsStatus}
                                                            onChange={handleAlertsDisableModal}
                                                        />
                                                        {alertsDisableModalOpen && (
                                                            <AlertsDisableModal
                                                                flagForInstallerEmailAlertsDisable={
                                                                    flagForInstallerEmailAlertsDisable
                                                                }
                                                                setFlagForInstallerEmailAlertsDisable={
                                                                    setFlagForInstallerEmailAlertsDisable
                                                                }
                                                                installerEmailAlertsStatus={installerEmailAlertsStatus}
                                                                setInstallerEmailAlertsStatus={
                                                                    setInstallerEmailAlertsStatus
                                                                }
                                                                panelId={specificPanel?.panelId}
                                                                open={alertsDisableModalOpen}
                                                                setOpen={setAlertsDisableModalOpen}
                                                            />
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Item>
                            </Grid>
                        )}
                        {(currentUserDetails?.userType === 'INSTALLER' ||
                            currentUserDetails?.userType === 'MANAGER' ||
                            currentUserDetails?.userType === 'ENGINEER' ||
                            currentUserDetails?.userType === 'ADMIN') && (
                            <Grid item xs={12}>
                                <Item elevation={1}>
                                    <TableContainer>
                                        <Table aria-label="simple table">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell
                                                        width={md ? '50%' : '25%'}
                                                        variant="head"
                                                        style={{ color: '#1397D9' }}
                                                    >
                                                        {t('tooltip_usrAccess')}
                                                    </TableCell>
                                                    <TableCell width={'25%'}></TableCell>
                                                    {ms && <TableCell width={'25%'}></TableCell>}
                                                    <TableCell width={'25%'}></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell width={'25%'} variant="head">
                                                        &ensp;{t('lbl_status')}
                                                    </TableCell>
                                                    <TableCell width={'25%'} align="left">
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <Checkbox
                                                                sx={{ mt: -0.9 }}
                                                                size={'small'}
                                                                icon={<PeopleIcon color="action" />}
                                                                disabled
                                                                checkedIcon={<PeopleIcon sx={{color:'#005EAB'}} />}
                                                                checked={specificPanel?.userAccessEnabled === true}
                                                            />
                                                            &ensp;
                                                            {specificPanel?.userAccessEnabled === true ? (
                                                                <Typography sx={{ color: '#424E54' }}>
                                                                    {t('lbl_enabled')}
                                                                </Typography>
                                                            ) : (
                                                                <Typography sx={{ color: '#424E54' }}>
                                                                    {t('lbl_disabled')}
                                                                </Typography>
                                                            )}
                                                        </div>
                                                    </TableCell>
                                                    {ms && <TableCell width={'25%'}></TableCell>}
                                                    <TableCell width={'25%'} align="right">
                                                        <Switch
                                                            checked={checked}
                                                            onChange={handleChange}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                        <UserAccessModal
                                                            modalCancelClick={modalCancelClick}
                                                            setModalCancelClick={setModalCancelClick}
                                                            open={open}
                                                            setOpen={setOpen}
                                                            status={status}
                                                            setStatus={setStatus}
                                                            panelId={id}
                                                        ></UserAccessModal>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Item>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Item elevation={1}>
                                <TableContainer>
                                    <Table aria-label="simple table">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell
                                                    width={md ? '50%' : '25%'}
                                                    variant="head"
                                                    style={{ color: '#1397D9' }}
                                                >
                                                    {t('tooltip_usrEmail_alerts')}
                                                </TableCell>
                                                <TableCell align="right" padding="normal" width={md ? '25%' : '2%'}>
                                                    {md ? (
                                                        <Button
                                                            onClick={handleUserEmailAlertsModal}
                                                            variant="text"
                                                            sx={{ paddingLeft: 10 }}
                                                        >
                                                            <AddIcon sx={{ fontSize: 20 }} />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            onClick={handleUserEmailAlertsModal}
                                                            variant="outlined"
                                                            sx={{ fontSize: 13 }}
                                                            fullWidth
                                                        >
                                                            <AddIcon sx={{ fontSize: 15 }} />
                                                            &nbsp;&nbsp;{t('btn_addUsr')}
                                                        </Button>
                                                    )}
                                                    <UserEmailAlertsModal
                                                        open={EmailAlertsModalOpen}
                                                        setOpen={setEmailAlertsModalOpen}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            {userEmailAlerts?.siaEmailSettings?.map(
                                                (users: SiaEmailSettingsProps, key: number) => (
                                                    <TableRow key={key}>
                                                        <TableCell colSpan={2} width={'100%'} align="left">
                                                            {md ? (
                                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                    <Typography variant="subtitle1">
                                                                        {users.email}
                                                                    </Typography>
                                                                    &emsp;
                                                                    {md ? (
                                                                        <></>
                                                                    ) : (
                                                                        <>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</>
                                                                    )}
                                                                    <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined}/>
                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={() =>
                                                                            handleEditUserAlerts(users.ID, key, users)
                                                                        }
                                                                    >
                                                                        <ChevronRightIcon color="action" />
                                                                    </IconButton>
                                                                </div>
                                                            ) : (
                                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                    <input
                                                                        readOnly
                                                                        value={users.email}
                                                                        style={{
                                                                            border: 'none',
                                                                            outline: 'none',
                                                                            fontSize: 16,
                                                                            fontFamily: 'Open Sans',
                                                                            fontStyle: 'normal',
                                                                            fontWeight: 600,
                                                                            lineHeight: 1,
                                                                            width: md ? '30%' : '20%',
                                                                            textAlign: 'left',
                                                                        }}
                                                                    />
                                                                    &emsp;
                                                                    {md ? (
                                                                        <></>
                                                                    ) : (
                                                                        <>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</>
                                                                    )}
                                                                    <Typography sx={{ mt: 0.5 }} variant="body2">
                                                                        <Stack direction={'row'} spacing={1}>
                                                                            {[
                                                                                users.setEnabled ? 'Sets' : null,
                                                                                users.systemEnabled ? 'System' : null,
                                                                                users.alarmsEnabled ? 'Alarms' : null,
                                                                                users.tampersEnabled ? 'Tampers' : null,
                                                                            ]
                                                                                .filter((item) => item !== null)
                                                                                .map((item: any, key: number) => (
                                                                                    <Chip key={key} label={item} />
                                                                                ))}
                                                                        </Stack>
                                                                    </Typography>
                                                                    <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined}/>
                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={() =>
                                                                            handleEditUserAlerts(users.ID, key, users)
                                                                        }
                                                                    >
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={() => handleDeleteUserAlerts(users.ID)}
                                                                    >
                                                                        <Delete />
                                                                    </IconButton>
                                                                </div>
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                            <EditUserEmailAlertsModal
                                                counter={counter}
                                                setCounter={setCounter}
                                                siaEmailSetting={siaEmailSetting}
                                                index={editUserIndex}
                                                id={editUserId}
                                                open={editUserEmailAlertsModalOpen}
                                                setOpen={setEditUserEmailAlertsModalOpen}
                                            />
                                            <DeleteUserEmailAlertsModal
                                                counter={counter}
                                                setCounter={setCounter}
                                                open={editUserEmailAlertsModalOpen}
                                                setOpen={setEditUserEmailAlertsModalOpen}
                                                isMobile={isMobile}
                                                setIsMobile={setIsMobile}
                                                id={deleteUserId}
                                                openDeleteModal={deleteUserEmailAlertsModalOpen}
                                                setOpenDeleteModal={setDeleteUserEmailAlertsModalOpen}
                                            />
                                            <TableRow>
                                                <TableCell align="center" colSpan={2}>
                                                    <Typography variant="body2">{t('lbl_addUsr')}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <Snackbar
                                            open={alertsSnackOpen}
                                            autoHideDuration={6000}
                                            onClose={handleDisableInstallerEmailAlertSnackClose}
                                            message={alertsmessage}
                                            action={action}
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        />
                                    </Table>
                                </TableContainer>
                            </Item>
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}></Grid>
                    </Grid>
                </Box>
                </div>
            </div>
        </>
    );
}
