export const InstallerActionTypes = {
    SET_INSTALLERS : 'SET_INSTALLERS',
    SET_INSTALLER_DETAILS: 'SET_INSTALLER_DETAILS',
    SET_INSTALLER_PANELS: 'SET_INSTALLER_PANELS',
    SET_INSTALLER_USERS: 'SET_INSTALLER_USERS',
    SET_ACTIVATE_DEACTIVATE_INSTALLER:'SET_ACTIVATE_DEACTIVATE_INSTALLER',
    SET_REMOVE_INSTALLER:'SET_REMOVE_INSTALLER',
    SET_REMOVE_INSTALLER_STATE:'SET_REMOVE_INSTALLER_STATE',
    SET_INSTALLERS_LENGTH:'SET_INSTALLERS_LENGTH',
    SET_EDIT_INSTALLER_STATUS:'SET_EDIT_INSTALLER_STATUS',
}