/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect } from 'react'
import { InfoListItem, ScoreCard, EmptyState } from '@brightlayer-ui/react-components'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Button, Checkbox, Divider, Grid, IconButton, List, Paper, Stack, styled, Typography, useMediaQuery, useTheme, Tooltip, Theme, TablePagination } from '@mui/material';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { PanelContext, EstateContext } from '../ListAndGridView/PanelsView';
import { EstateProps, PanelProps, EstateArrayProps, PanelArrayProps } from './panelEstateTypes';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { SEARCH_PARAM_ESTATES } from '../../constants/index';
import { useNavigate } from 'react-router';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import PanelMenuList from '../PanelMenuButton/PanelMenuList';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import { setPanelView } from '../../redux/actions/specificPanelActions';
import { useDispatch, useSelector } from 'react-redux';
import AbcIcon from '@mui/icons-material/Abc';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { Maintenance } from '@brightlayer-ui/icons-mui';
import PeopleIcon from '@mui/icons-material/People';
import { Report } from '@mui/icons-material';
import { userEmailAlertsForAllPanelsToEmpty } from '../../redux/actions/panelEstateActions';
import EstateMenuList from '../EstateMenuButton/EstateMenuList';
import { setEstate } from '../../redux/actions/esateActions';
import { createStyles, makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import secureLocalStorage  from  "react-secure-storage";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),

    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerStyles: {
            height: 'auto'
        }
    })
);

type Props = {
    path?:string
}

export default function GridOfEstates({path}:Props): JSX.Element {
    const theme = useTheme();
    const styles = useStyles(theme);
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate()
    const estates: EstateArrayProps = React.useContext(EstateContext);
    const searchParam = secureLocalStorage.getItem(SEARCH_PARAM_ESTATES);
    const { currentUser } = useSelector((state: any) => state.userReducer);
    //const currentUser = JSON.parse(localStorage.getItem('currentUser') || '')
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [estateId, setEstateId] = React.useState<number | undefined>(undefined);
    const [estateName, setEstateName] = React.useState<string>('');
    const [page, setPage] = React.useState<number>(0);
    const { t } = useTranslation();

    const handleEstateMenuClick = (event: React.MouseEvent<HTMLButtonElement>, id: number | undefined, name: string): any => {
        setAnchorEl(event.currentTarget);
        setEstateId(id)
        setEstateName(name)
    };

    const handleEstateClick = (estate: EstateProps) => {
        secureLocalStorage.setItem('estate',estate)
        secureLocalStorage.setItem('pathArray',[estate])
        dispatch(setEstate(estate))
        navigate(`/panel/estate-group/${estate.ID}`)
    }

    useEffect(() => {
        setPage(0)
    }, [searchParam?.toString().length])

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };


    return (
        <>
            {
                //estates
                estates?.length > 0 && estates !== undefined &&
                estates?.slice((page * 10),(page * 10) + 10).map((estate: EstateProps, key: number) => (

                    <Grid item xs={md ? 12 : 4} key={key}>
                        <Item elevation={0}>
                            <ScoreCard nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                headerTitle={estate.name}
                                headerColor="#007BC1"
                                classes={{ header: styles.headerStyles }}
                                actionItems={[
                                    <>
                                        {currentUser?.userType !== 'ADMIN' &&
                                            <IconButton onClick={(event) => handleEstateMenuClick(event, estate.ID, estate.name)}>
                                            <MoreVertIcon sx={{ color: 'white' }} />
                                        </IconButton>}
                                        <EstateMenuList
                                            path={path}
                                            screen='overview'
                                            subEstates={undefined}
                                            estatePage={false}
                                            anchorEl={anchorEl}
                                            open={open}
                                            setAnchorEl={setAnchorEl}
                                            estateId={estateId}
                                            estateName={estateName}
                                        />
                                    </>,
                                ]}
                                actionLimit={2}
                                actionRow={
                                    <List style={{ padding: 0 }}>
                                        <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                         onClick={() => handleEstateClick(estate)} dense chevron title={t('lbl_viewEstate')} hidePadding />
                                    </List>
                                }
                            ></ScoreCard>
                        </Item>
                    </Grid>
                ))
            }

            {estates.length === 0 && searchParam !== '' && (
                <div style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%' }}>
                    <div style={{ flex: '1 1 0px' }}>
                        <EmptyState nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                            icon={<ManageSearchIcon fontSize={'inherit'} />}
                            title={`${t('msg_noSearch_results')} "${searchParam}"...`}
                        />
                    </div>
                </div>
            )}
            <Grid item xs={12}>
                <TablePagination
                    component="div"
                    count={estates?.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={10}
                    rowsPerPageOptions={[10]}
                    showFirstButton
                    showLastButton
                />
            </Grid>

            <Grid item xs={12}>
                <Item elevation={0}></Item>
            </Grid>
        </>
    );
}