/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-misused-promises */

import React, { useEffect, useState } from 'react'
import { LocalStorage } from '../../store/local-storage';
import secureLocalStorage  from  "react-secure-storage";
import { UserMenu } from '@brightlayer-ui/react-components';
//import { useSecurityActions } from '@brightlayer-ui/react-auth-shared';
import { Avatar } from '@mui/material';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import ExitToApp from '@mui/icons-material/ExitToApp';
import EmailIcon from '@mui/icons-material/Email';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { useSelector } from 'react-redux';
import { setMenuSelected } from '../../redux/actions/settingsActions';
import { REACT_APP_BASE_URL } from '../../entities/Endpoints';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer'
import { setPanelView } from '../../redux/actions/specificPanelActions';
import { useApp } from '../../contexts/AppContextProvider';


const Usermenu = (): JSX.Element => {
    const app = useApp();
    const navigate = useNavigate()
    const dispatch = useTypedDispatch();
    //const securityHelper = useSecurityActions();
    const { currentUser } = useSelector((state: any) => state.userReducer);
    //const currentUser = JSON.parse(localStorage.getItem('currentUser') || '')
    const firstName = `${secureLocalStorage.getItem('firstName')}`
    const lastName = `${secureLocalStorage.getItem('lastName')}`
    const fullName = `${firstName} ${lastName}`
    const email = `${secureLocalStorage.getItem('email')}`
    const initials = `${firstName.slice(0, 1).toUpperCase()}${lastName.slice(0, 1).toUpperCase()}`;
    const [state, setState] = useState<string>('Active')
    const { t } = useTranslation();
    const isBrowserClosed = sessionStorage.getItem('isClosed') || ''

    const logOut = async(): Promise<any> => {
        //securityHelper.onUserNotAuthenticated();
        
        /** Set default panel view to list view **/
        secureLocalStorage.setItem('view', 'list');
        secureLocalStorage.setItem('viewSubEstate', 'list');
        secureLocalStorage.setItem('currentUser', '')
        dispatch(setPanelView('list'));
       
        try {
            await fetch(`${REACT_APP_BASE_URL}/m2m-auth/rest/auth/logout`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        }
        catch (err) { console.log(err) }
        LocalStorage.clearAuthCredentials();
        LocalStorage.clearAuthCredentials();
        app.onUserNotAuthenticated();
        navigate('/login');
    };

    const onIdle = async () => {
        await fetch(
            `${REACT_APP_BASE_URL}/m2m-auth/rest/auth/checkAuth`,
            {
                method: 'GET',
                credentials:'include',
            }
        )
            .then((res: any) => {
                console.log(`res stat is: ${res.status}`)
                if(res.status === 401){
                    setState('Idle')
                }
                else{
                    setState('Active')
                }
            })
      }
    
      const onActive = () => {
        setState('Active')
      }

      const { getRemainingTime } = useIdleTimer({
        onIdle,
        onActive,
        timeout: 1900_000,
        throttle: 500
      })
      
   useEffect(() => {
    if(isBrowserClosed === '') logOut(); 
    const interval = setInterval(() => {
        getRemainingTime()
      }, 500)

      if(state === 'Idle') logOut();
  
      return () => {
        clearInterval(interval)
      }

   }, [state])
   
    const navigateToSettings = async (): Promise<void> => {
        await dispatch(setMenuSelected('settings'))
        navigate(`/settings`)
    }

    const openDefaultMail = (mail: string): void => {
        window.open(`mailto:${mail}?subject=`)
    }

    return (
        <div>
            <UserMenu
                avatar={<Avatar>{initials}</Avatar>}
                menuTitle={fullName}
                menuSubtitle={email}
                menuGroups={[
                    {
                        items: [
                            {
                                title: `${currentUser?.installerInfo?.cloudId}`,
                                rightComponent: <span>{t('lbl_cloudId')}</span>,
                                icon: <CloudQueueIcon />,
                            },
                            {
                                title: `${t('menu_settings')}`,
                                icon: <SettingsIcon />,
                                onClick: navigateToSettings,
                            },
                            {
                                title: `${t('lbl_logOut')}`,
                                icon: <ExitToApp />,
                                onClick: logOut,
                                divider: true
                            },
                        ],
                    },
                    {
                        items: [
                            {
                                title: 'admin@eatonsecureconnect.com',
                                icon: <EmailIcon />,
                                itemFontColor: 'blue',
                                onClick: () => openDefaultMail('admin@eatonsecureconnect.com'),
                            }
                        ],
                        title: `${t('lbl_contactSupport')}`
                    }
                ]}

                MenuProps={{
                    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                    transformOrigin: { horizontal: 'right', vertical: 'top' },
                }} nonce={undefined} onResize={undefined} onResizeCapture={undefined}            />
        </div>
    )
}

export default Usermenu