/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {
    Button,
    Theme,
    InputAdornment,
    TextField,
    useMediaQuery,
    useTheme,
    IconButton,
    Fab,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableBody,
    Typography,
    TablePagination,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import { EmptyState, InfoListItem, ThreeLiner } from '@brightlayer-ui/react-components';
import { useSelector } from 'react-redux';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AddIcon from '@mui/icons-material/Add';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { fetchInstallerUsers } from '../../../redux/actions/installersActions';
import { useTypedDispatch } from '../../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { useParams } from 'react-router';
import Switch from '@mui/material/Switch';
import { AccountUsersProps } from '../../AccountUsers/AccountUsersTypes';
import { fetchUserGroups } from '../../../redux/actions/accountUsersActions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    })
);

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
}));

export function Users(): JSX.Element {
    const theme = useTheme();
    const classes = useStyles(theme);
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useTypedDispatch();
    const { t } = useTranslation();
    let { installerUsersList } = useSelector((state: any) => state.installerReducer);
    const [query, setQuery] = React.useState('');
    const [page, setPage] = React.useState(0);
    const { id } = useParams()

    const fetching = async () => {
        await dispatch(fetchInstallerUsers(id,(page * 10)));
        //await dispatch(fetchUserGroups(Number(id)));
    };

    React.useEffect(() => {
         fetching();
    }, [id,page]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    try {
        if (installerUsersList.length > 0 && installerUsersList !== undefined) {
            const filteredList = installerUsersList.filter(
                (notification: AccountUsersProps) => (
                    notification.firstName.toLowerCase().includes(query.toLowerCase()) ||
                    notification.firstName.toLowerCase().includes(query.toLowerCase()) 
                )
            );
            installerUsersList = query.length > 0 ? filteredList : installerUsersList;
        }
    } catch (exception) {
        console.log(`Exception in panel notification filtering: ${JSON.stringify(exception)}`);
    }

    return (
        <div style={md ? {} : { maxHeight: '100vh', overflow: 'auto' }}>
            {/* {md ? (
                    <></>
                ) : (
                    <Box sx={{ flexGrow: 1, marginLeft: '4%', marginRight: '4%' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={1.5}>
                                <Item elevation={0}>
                                    <Button fullWidth variant="outlined" sx={{ fontSize: 13 }}>
                                        <AddIcon sx={{ fontSize: 15 }} />
                                        &nbsp;&nbsp;Add User
                                    </Button>
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>
                )} */}

            <Box
                sx={
                    md
                        ? {
                              flexGrow: 1,
                              marginLeft: '-6%',
                              marginRight: '-7.5%',
                              marginTop: '-7.6%',
                              pb: 40,
                              maxHeight: '100vh',
                              overflow: 'auto',
                          }
                        : { flexGrow: 1, marginLeft: '4%', marginRight: '4%', marginTop: 4, pb: 40 }
                }
            >
                <Grid container spacing={2}>
                    {!md && (
                        <Grid item xs={1.5}>
                            <Item elevation={0}>
                                <Button fullWidth variant="outlined" sx={{ fontSize: 13 }}>
                                    <AddIcon sx={{ fontSize: 15 }} />
                                    &nbsp;&nbsp;Add User
                                </Button>
                            </Item>
                        </Grid>
                    )}
                    {!md && <Grid item xs={10.5}></Grid>}
                    <Grid item xs={12}>
                        <Item elevation={0}>
                            <Box
                                sx={{ flexGrow: 1, marginLeft: md ? 0.2 : 3, marginRight: md ? 0.5 : 3, pt: 3, pb: 3 }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Item elevation={0}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                placeholder={t('lbl_search')}
                                                value={query}
                                                onChange={(e) => setQuery(e.target.value)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {query.length !== 0 && (
                                                                <IconButton>
                                                                    <CancelIcon />
                                                                </IconButton>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item elevation={1}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell width={'20%'} variant="head">
                                                            User Info&ensp;
                                                            <Checkbox
                                                                icon={
                                                                    <ArrowDownwardIcon
                                                                        fontSize="small"
                                                                        color="disabled"
                                                                    />
                                                                }
                                                                checkedIcon={
                                                                    <ArrowUpwardIcon
                                                                        fontSize="small"
                                                                        color="disabled"
                                                                    />
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            width={'80%'}
                                                            align={md ? 'left' : 'right'}
                                                            variant="head"
                                                        >
                                                            E-mail Notification
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {installerUsersList !== undefined &&
                                                        installerUsersList.length > 0 &&
                                                        installerUsersList.map(
                                                            (accountUser: AccountUsersProps, key: number) => (
                                                                <TableRow key={key} sx={{ backgroundColor: 'white' }}>
                                                                    <TableCell align="left" padding="normal">
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                flexDirection: 'column',
                                                                            }}
                                                                        >
                                                                            <Typography variant="subtitle1">
                                                                                {accountUser.firstName}{' '}
                                                                                {accountUser.lastName}
                                                                            </Typography>
                                                                            <Typography variant="body2">
                                                                                {accountUser.userType === 'ENGINEER'
                                                                                    ? 'Engineer'
                                                                                    : accountUser.userType === 'MANAGER'
                                                                                    ? 'Manager'
                                                                                    : accountUser.userType ===
                                                                                      'OFFICE_STAFF'
                                                                                    ? 'Office Staff'
                                                                                    : 'Installer'}
                                                                            </Typography>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontWeight: 300,
                                                                                    fontSize: '14px',
                                                                                }}
                                                                            >
                                                                                {accountUser.login}
                                                                            </Typography>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align={md ? 'left' : 'right'}
                                                                        padding="normal"
                                                                    >
                                                                        <Switch
                                                                            checked={
                                                                                accountUser.notificationEnabledUserArray
                                                                            }
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                </TableBody>
                                            </Table>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item elevation={0}>
                                            <TablePagination
                                                component="div"
                                                count={10000}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                rowsPerPage={10}
                                                rowsPerPageOptions={[10]}
                                                showFirstButton
                                                showLastButton = {!(installerUsersList.length < 10)}
                                                nextIconButtonProps={{disabled:installerUsersList.length < 10}}
                                            />
                                        </Item>
                                    </Grid>
                                    {(query !== '' && installerUsersList.length === 0 ) && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                height: '100%',
                                                width: '100%',
                                            }}
                                        >
                                            <div style={{ flex: '1 1 0px' }}>
                                                <EmptyState nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                    icon={<ManageSearchIcon fontSize={'inherit'} />}
                                                    title={`${t('msg_noSearch_results')} ${query}...`}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </Grid>
                                {md && (
                                    <Paper
                                        elevation={0}
                                        sx={{
                                            position: 'absolute' as 'absolute',
                                            top: '95vh',
                                            zIndex: 1000,
                                            transform: 'translateY(-100%)',
                                            width: '100%',
                                            p: 2,
                                        }}
                                    >
                                       <Button fullWidth variant="outlined" sx={{ fontSize: 13 }}>
                                        <AddIcon sx={{ fontSize: 15 }} />
                                        &nbsp;&nbsp;Add User
                                        </Button> 
                                    </Paper>
                                )}
                            </Box>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}
