/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { fetchConnectLoginHtmlCodeStatus } from '../../redux/actions/specificPanelActions';
import { REACT_APP_BASE_URL } from '../../entities/Endpoints';
import { Button } from '@mui/material';
import { EmptyState } from '@brightlayer-ui/react-components';
import { DeviceWithKeypad } from '@brightlayer-ui/icons-mui';
import RefreshIcon from '@mui/icons-material/Refresh';

type Props = {
    value: string
}

export default function IFrameTab({ value }: Props): JSX.Element {
    const dispatch = useTypedDispatch()
    const { drawerState } = useSelector((state: any) => state.specificPanelAndEstateReducer);
    const { currentUser } = useSelector((state: any) => state.userReducer);
    //const currentUser = JSON.parse(localStorage.getItem('currentUser') || '')
    const { specificPanel, htmlCodeStatus } = useSelector((state: any) => state.specificPanelAndEstateReducer);
    const [state, setState] = React.useState(true)

    const fetching = async () => {
        await dispatch(fetchConnectLoginHtmlCodeStatus(specificPanel?.panelId))
    }

    const handleTryAgain = () => {
        fetching()
    }

    React.useEffect(() => {
        if (state) {
            fetching()
            setState(false)
        }
    }, [])

    return (
        <div>
            {currentUser?.userType !== 'OFFICE_STAFF' && htmlCodeStatus !== 200 ? (
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'80vh'}}>
                    <EmptyState
                        icon={<DeviceWithKeypad fontSize="inherit" />}
                        title={undefined}
                        description={'Unable to connect to the panel'}
                        actions={
                        <Button variant={'contained'} color={'primary'} onClick={handleTryAgain} startIcon={<RefreshIcon />}>
                            {'Try again'}
                        </Button>} 
                        nonce={undefined} onResize={undefined} onResizeCapture={undefined}                    
                        />
                </div>
            ) : (
                <iframe
                    id="connect"
                    onLoad={() => setState(true)}
                    style={{
                        position: 'fixed',
                        width: drawerState === 'open' ? '72vw' : '93vw',
                        height: '75vh',
                        border: 'none',
                        overflow: 'hidden',
                        margin: 0,
                        padding: 0,
                    }}
                    src={
                        htmlCodeStatus === 200
                            ? value === 'connect'
                                ? `${REACT_APP_BASE_URL}/m2m-eaton-web/iframe/panelid-${specificPanel?.panelId}/expart/expart/login.cgi`
                                : `${REACT_APP_BASE_URL}/m2m-eaton-web/iframe/panelid-${specificPanel?.panelId}/expart/expart/login.cgi?vkp=1`
                            : ''
                    }
                />
            )}
        </div>
    );
}
