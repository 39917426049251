/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import Appbar from '../../components/Appbar/Appbar';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {
    Theme,
    InputAdornment,
    TextField,
    useMediaQuery,
    useTheme,
    IconButton,
    Fab,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableBody,
    Typography,
    TablePagination,
    Snackbar,
    Button,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import { EmptyState, InfoListItem, ListItemTag } from '@brightlayer-ui/react-components';
import { useSelector } from 'react-redux';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { useTranslation } from 'react-i18next';
import { InstallersProps } from '../../components/Installers/InstallersTypes';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { fetchInstallerDetails, fetchInstallerPanels, fetchInstallers, fetchInstallersLength, setRemoveInstaller } from '../../redux/actions/installersActions';
import { useNavigate } from 'react-router';
import secureLocalStorage from 'react-secure-storage';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    })
);

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
}));

export function Installers(): JSX.Element {
    const theme = useTheme();
    const classes = useStyles(theme);
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { removeInstallerStatus, removeInstallerState, installerLength } = useSelector((state: any) => state.installerReducer);
    let { installersList } = useSelector((state: any) => state.installerReducer);
    const [query, setQuery] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [fetchLen, setFetchLen] = React.useState(true);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');

    const fetching = async () => {
        await dispatch(fetchInstallers(page * 10));
        if (fetchLen) {
            setFetchLen(false);
            await dispatch(fetchInstallersLength());
        }
    };

    React.useEffect(() => {
        void fetching();
        if (removeInstallerState) {
            if (removeInstallerStatus === 204) {
                setMessage(`Installer removed successfully`);
            } else if (removeInstallerStatus === 500) {
                setMessage(`${t('msg_internal_error')}`);
            } else if (removeInstallerStatus === 400) {
                setMessage(`${t('lbl_unauthorizedUser')}`);
            } else {
                setMessage(`${t('lbl_someError')}`);
            }
            setSnackOpen(true);
            dispatch(setRemoveInstaller(false))
        }
    }, [removeInstallerState, page]);

    try {
        if (installersList.length > 0 && query !== '') {
            const filteredInstallers = installersList.filter((installer: InstallersProps) =>
                installer.firstName?.toLowerCase().includes(query.toLowerCase()) ||
                installer.lastName?.toLowerCase().includes(query.toLowerCase()) ||
                installer.installerInfo?.cloudId?.includes(query.toLowerCase()) ||
                installer.installerInfo?.contactEmail?.toLowerCase().includes(query.toLowerCase()) ||
                installer.installerInfo?.installerCompanyName?.toLowerCase().includes(query.toLowerCase()) ||
                installer.installerInfo?.installerState?.toLowerCase().includes(query.toLowerCase())
            );
            installersList = query.length > 0 ? filteredInstallers : installersList;
        }
    } catch (exception) {
        //console.log(`Exception in installers search: ${JSON.stringify(exception)}`);
    }


    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleInstallerDetails = async (installer: any) => {
        await dispatch(fetchInstallerDetails(installer?.ID))
        await dispatch(fetchInstallerPanels(installer?.groupId))
        secureLocalStorage.setItem('installer',JSON.stringify(installer))
        navigate(`/installers/installer-details/${installer?.ID}`)
    }

    const handleInstallerSearchCancelClick = (): any => {
        setQuery('');
    };

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackClose}>
                {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    return (
        <>
            <div className="main-div">
            <div style={{maxHeight:'100vh',overflow:'auto'}}>
                <Appbar toolbarMenu={false} title={'Installers'} />
                {md ? (
                    <div style={{ backgroundColor: 'white' }}>
                        <Box
                            sx={{
                                flexGrow: 1,
                                marginLeft: 2,
                                marginRight: 2,
                                marginTop: 2,
                                backgroundColor: 'background.paper',
                                height: '100vh',
                            }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={10.5}>
                                    <Item elevation={0}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder={t('lbl_search')}
                                            onChange={(e) => setQuery(e.target.value)}
                                            value={query}
                                            onClick={(event) =>
                                                handleInstallerSearchCancelClick()
                                            }
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {query.length !== 0 && (
                                                            <IconButton>
                                                                <CancelIcon />
                                                            </IconButton>
                                                        )}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Item>
                                </Grid>
                                <Grid item xs={1.5}>
                                    <IconButton size="small">
                                        <SwapVertIcon style={{ marginTop: 15 }} />
                                    </IconButton>
                                </Grid>
                                <Grid item sx={{ mb: 15 }} xs={12}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width="55%" style={{ fontWeight: 'bold' }}>
                                                    {t('lbl_name')}&ensp;
                                                    <Checkbox
                                                        icon={
                                                            <ArrowDownwardIcon
                                                                fontSize="small"
                                                                color="disabled"
                                                            />
                                                        }
                                                        checkedIcon={
                                                            <ArrowUpwardIcon
                                                                fontSize="small"
                                                                color="disabled"
                                                            />
                                                        }
                                                    />
                                                </TableCell>

                                                <TableCell width="40%" style={{ fontWeight: 'bold' }}>
                                                    {t('lbl_status')}&ensp;
                                                    <Checkbox
                                                        icon={
                                                            <ArrowDownwardIcon
                                                                fontSize="small"
                                                                color="disabled"
                                                            />
                                                        }
                                                        checkedIcon={
                                                            <ArrowUpwardIcon
                                                                fontSize="small"
                                                                color="disabled"
                                                            />
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell width="5%"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                installersList.length > 0 &&
                                                installersList.map(
                                                    (installer: InstallersProps, key: number) => (
                                                        <>
                                                            <TableRow sx={{ backgroundColor: 'white' }}>
                                                                <TableCell
                                                                    align="left"
                                                                    padding="normal"
                                                                >
                                                                    <label >
                                                                        {
                                                                            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                                                                {installer?.lastName}{' '}
                                                                                {installer?.firstName}
                                                                            </Typography>
                                                                        }
                                                                    </label>
                                                                </TableCell>

                                                                <TableCell
                                                                    align="left"
                                                                    padding="normal"
                                                                >
                                                                    <ListItemTag nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                                        sx={{
                                                                            maxWidth:
                                                                                installer?.installerInfo?.installerState ===
                                                                                    'ACTIVE'
                                                                                    ? 70
                                                                                    : 50,
                                                                            mt: 1,
                                                                        }}
                                                                        label={installer?.installerInfo?.installerState}
                                                                        backgroundColor={
                                                                            installer?.installerInfo?.installerState ===
                                                                                'ACTIVE'
                                                                                ? '#E0F1FD'
                                                                                : '#CA3C3D'
                                                                        }
                                                                        fontColor={
                                                                            installer?.installerInfo?.installerState ===
                                                                                'ACTIVE'
                                                                                ? '#39B620'
                                                                                : '#ffff'
                                                                        }
                                                                    />
                                                                </TableCell>

                                                                <TableCell
                                                                    align="right"
                                                                    padding="normal"
                                                                >
                                                                    <IconButton
                                                                        onClick={() => handleInstallerDetails(installer)}
                                                                        sx={{ mt: -1, marginRight: -2 }}
                                                                        size="small"
                                                                    >
                                                                        <ChevronRightIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        </>
                                                    )
                                                )}
                                        </TableBody>
                                    </Table>
                                </Grid>
                                <Item
                                    sx={
                                        md
                                            ? {
                                                position: 'fixed',
                                                top: '100%',
                                                transform: 'translate(-0%,-100%)',
                                                width: '100%',
                                                boxShadow:
                                                    '0px -3px 5px -1px rgba(0, 0, 0, 0.2), 0px -6px 10px rgba(0, 0, 0, 0.14), 0px -1px 18px rgba(0, 0, 0, 0.12)',
                                                borderRadius: 0,
                                                p: 1,
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }
                                            : {}
                                    }
                                    elevation={md ? 2 : 0}
                                >
                                    <TablePagination
                                        component="div"
                                        count={installerLength}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={10}
                                        rowsPerPageOptions={[10]}
                                        showFirstButton
                                        showLastButton
                                    />
                                </Item>
                                {query !== '' && installersList.length === 0 && (
                                    <div
                                        style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%' }}
                                    >
                                        <div style={{ flex: '1 1 0px' }}>
                                            <EmptyState nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                icon={<ManageSearchIcon fontSize={'inherit'} />}
                                                title={`${t('msg_noSearch_results')} ${query}...`}
                                            />
                                        </div>
                                    </div>
                                )}
                            </Grid>
                        </Box>
                    </div>
                ) : (
                    <Box sx={{ flexGrow: 1, marginLeft: '4%', marginRight: '4%', marginTop: 4 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Item elevation={0}>
                                    <Box sx={{ flexGrow: 1, marginLeft: 3, marginRight: 3, pt: 3, pb: 3 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Item elevation={0}>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        placeholder={t('lbl_search')}
                                                        value={query}
                                                        onChange={(e) => setQuery(e.target.value)}
                                                        onClick={(event) =>
                                                            handleInstallerSearchCancelClick()
                                                        }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIcon />
                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    {query.length !== 0 && (
                                                                        <IconButton>
                                                                            <CancelIcon />
                                                                        </IconButton>
                                                                    )}
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2" color={'primary'}>
                                                    {t('lbl_installers')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Item elevation={1}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell width="20%">
                                                                    {t('lbl_name')}&ensp;
                                                                    <Checkbox
                                                                        icon={
                                                                            <ArrowDownwardIcon
                                                                                fontSize="small"
                                                                                color="disabled"
                                                                            />
                                                                        }
                                                                        checkedIcon={
                                                                            <ArrowUpwardIcon
                                                                                fontSize="small"
                                                                                color="disabled"
                                                                            />
                                                                        }
                                                                    />
                                                                </TableCell>
                                                                <TableCell width="20%">
                                                                    {t('lbl_cloudId')}&ensp;
                                                                    <Checkbox
                                                                        icon={
                                                                            <ArrowDownwardIcon
                                                                                fontSize="small"
                                                                                color="disabled"
                                                                            />
                                                                        }
                                                                        checkedIcon={
                                                                            <ArrowUpwardIcon
                                                                                fontSize="small"
                                                                                color="disabled"
                                                                            />
                                                                        }
                                                                    />
                                                                </TableCell>
                                                                <TableCell width="20%">
                                                                    {t('lbl_email')}&ensp;
                                                                    <Checkbox
                                                                        icon={
                                                                            <ArrowDownwardIcon
                                                                                fontSize="small"
                                                                                color="disabled"
                                                                            />
                                                                        }
                                                                        checkedIcon={
                                                                            <ArrowUpwardIcon
                                                                                fontSize="small"
                                                                                color="disabled"
                                                                            />
                                                                        }
                                                                    />
                                                                </TableCell>
                                                                <TableCell width="20%">
                                                                    {t('lbl_company')}&ensp;
                                                                    <Checkbox
                                                                        icon={
                                                                            <ArrowDownwardIcon
                                                                                fontSize="small"
                                                                                color="disabled"
                                                                            />
                                                                        }
                                                                        checkedIcon={
                                                                            <ArrowUpwardIcon
                                                                                fontSize="small"
                                                                                color="disabled"
                                                                            />
                                                                        }
                                                                    />
                                                                </TableCell>
                                                                <TableCell width="15%">
                                                                    {t('lbl_status')}&ensp;
                                                                    <Checkbox
                                                                        icon={
                                                                            <ArrowDownwardIcon
                                                                                fontSize="small"
                                                                                color="disabled"
                                                                            />
                                                                        }
                                                                        checkedIcon={
                                                                            <ArrowUpwardIcon
                                                                                fontSize="small"
                                                                                color="disabled"
                                                                            />
                                                                        }
                                                                    />
                                                                </TableCell>
                                                                <TableCell width="5%"></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                installersList.length > 0 &&
                                                                installersList.map(
                                                                    (installer: InstallersProps, key: number) => (
                                                                        <>
                                                                            <TableRow sx={{ backgroundColor: 'white' }}>
                                                                                <TableCell
                                                                                    align="left"
                                                                                    padding="normal"
                                                                                >
                                                                                    <label>
                                                                                        {
                                                                                            <Typography variant="body1">
                                                                                                {installer?.lastName}{' '}
                                                                                                {installer?.firstName}
                                                                                            </Typography>
                                                                                        }
                                                                                    </label>
                                                                                </TableCell>

                                                                                <TableCell
                                                                                    align="left"
                                                                                    padding="normal"
                                                                                >
                                                                                    <label>
                                                                                        {
                                                                                            <Typography variant="body1">
                                                                                                {installer?.installerInfo?.cloudId}
                                                                                            </Typography>
                                                                                        }
                                                                                    </label>
                                                                                </TableCell>

                                                                                <TableCell
                                                                                    align="left"
                                                                                    padding="normal"
                                                                                >
                                                                                    <label>
                                                                                        {
                                                                                            <Typography variant="body1">
                                                                                                {installer?.login?.slice(0, 39)}
                                                                                            </Typography>
                                                                                        }
                                                                                    </label>
                                                                                </TableCell>

                                                                                <TableCell
                                                                                    align="left"
                                                                                    padding="normal"
                                                                                >
                                                                                    <label>
                                                                                        {
                                                                                            <Typography variant="body1">
                                                                                                {installer?.installerInfo?.installerCompanyName}
                                                                                            </Typography>
                                                                                        }
                                                                                    </label>
                                                                                </TableCell>

                                                                                <TableCell
                                                                                    align="left"
                                                                                    padding="normal"
                                                                                >
                                                                                    <ListItemTag nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                                                        sx={{
                                                                                            maxWidth:
                                                                                                installer?.installerInfo?.installerState ===
                                                                                                    'ACTIVE'
                                                                                                    ? 70
                                                                                                    : 50,
                                                                                            mt: 1,
                                                                                        }}
                                                                                        label={installer?.installerInfo.installerState}
                                                                                        backgroundColor={
                                                                                            installer?.installerInfo?.installerState ===
                                                                                                'ACTIVE'
                                                                                                ? '#E0F1FD'
                                                                                                : '#CA3C3D'
                                                                                        }
                                                                                        fontColor={
                                                                                            installer?.installerInfo?.installerState ===
                                                                                                'ACTIVE'
                                                                                                ? '#39B620'
                                                                                                : '#ffff'
                                                                                        }
                                                                                    />
                                                                                </TableCell>

                                                                                <TableCell
                                                                                    align="right"
                                                                                    padding="normal"
                                                                                >
                                                                                    <IconButton
                                                                                        onClick={() => handleInstallerDetails(installer)}
                                                                                        sx={{ mt: -1 }}
                                                                                        size="small"
                                                                                    >
                                                                                        <ChevronRightIcon />
                                                                                    </IconButton>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </>
                                                                    )
                                                                )}
                                                        </TableBody>
                                                    </Table>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Item elevation={0}>
                                                    <TablePagination
                                                        component="div"
                                                        count={installerLength}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        rowsPerPage={10}
                                                        rowsPerPageOptions={[10]}
                                                        showFirstButton
                                                        showLastButton
                                                    />
                                                </Item>
                                            </Grid>
                                            {query !== '' && installersList.length === 0 && (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        height: '100%',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <div style={{ flex: '1 1 0px' }}>
                                                        <EmptyState 
                                                                icon={<ManageSearchIcon fontSize={'inherit'} />}
                                                                title={`${t('msg_noSearch_results')} ${query}...`} 
                                                                nonce={undefined} onResize={undefined} onResizeCapture={undefined}                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </Grid>
                                    </Box>
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>
                )}
                <Snackbar
                    open={snackOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackClose}
                    message={message}
                    action={action}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />
            </div>
            </div>
        </>
    );
}
