export const STATUS_MESSAGES = {
  HTTP_OK: 'The request has succeeded.',
  HTTP_ERROR_RESTRICTED_USER_ACCESS: 'The user is restricted to access.',
  HTTP_ERROR_UNAUTHORIZED_USER: 'The request requires user authentication.',
  HTTP_ERROR_INVALID_REQUEST: 'The request cannot be fulfilled due to bad syntax.',
  HTTP_ERROR_NOT_FOUND: 'Error not found.',
  HTTP_INTERNAL_SERVER_ERROR: 'Internal server error.',
};

  export const ROLES = {
    INSTALLER: 'INSTALLER',
    ADMIN:'ADMIN',
    MANAGER:'MANAGER',
    ENDUSER:'MOBILE_APPLICATION_USER',
    OFFICESTAFF:'OFFICE_STAFF',
    ENGINEER:'ENGINEER',
  };
