/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import './installer-tabs.css';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import {
    Paper, useMediaQuery, useTheme, AppBar,
    IconButton,
    Theme,
    Toolbar,
    Typography,
    Divider
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Spacer } from '@brightlayer-ui/react-components';
import { useNavigate, useParams } from 'react-router';
import { Details } from './Details';
import EditIcon from '@mui/icons-material/Edit';
import { useTypedDispatch } from '../../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { fetchInstallerDetails, fetchInstallerPanels, fetchInstallers } from '../../../redux/actions/installersActions';
import Usermenu from '../../UserMenu/UserMenu';
import { InstallersProps } from '../InstallersTypes';
import EditInstallerModal from '../EditInstaller/EditInstallerModal';
import { Users } from './Users';
import { InstallerPanelTab } from './InstallerPanelTab';
import secureLocalStorage from 'react-secure-storage';

const useStyles = makeStyles({
    tabs: {
        '& .MuiTab-root': {
            textTransform: 'none',
            fontWeight: 400,
        },
        '& .MuiTabs-indicator': {
            height: 2,
            color: 'black',
        },
        '& .MuiTab-root.Mui-selected': {
            color: '#007BC1',
        },
        '& .MuiTab-root.Mui-disabled': {
            color: 'black',
        },
        '& .MuiTabs-scrollButtons.Mui-disabled ': {
            opacity: 1,
        },
    },
});

const useStylesAppbar = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            backgroundColor: 'white'
        },
    })
);

export function InstallerDetailsTabs(): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const role = secureLocalStorage.getItem('role')
    const { installersList, installerDetails } = useSelector((state: any) => state.installerReducer);
    const [value, setValue] = React.useState('1');
    const [count,setCount] = React.useState(0);
    const classes = useStyles();
    const classesApp = useStylesAppbar();
    const dispatch = useTypedDispatch()
    const navigate = useNavigate();
    const { id } = useParams()
    const { t } = useTranslation();
    const [openEditInstallerModal, setOpenEditInstallerModal] = React.useState(false);
    const [spokenPwd, setSpokenPwd] = React.useState('')
    const [installerData, setInstallerData] = React.useState<InstallersProps>({
        ID: 0,
        firstName: '',
        lastName: '',
        installerInfo: {
            cloudId: '',
            installerState: '',
            installerCompanyName: '',
            contactEmail: '',
            address: {
                address: '',
                country: '',
                postCode: ''
            },
            firstPhoneNumber: {
                number: '',
                type: ''
            },
            spokenPassword: ''
        },
        login: '',
    });

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const handleBackClick = (): any => {
        navigate('/installers')
    }

    const fetching = async () => {
        await dispatch(fetchInstallerDetails(id))
        //await dispatch(fetchInstallers());
        await dispatch(fetchInstallerPanels(id))
    }

    React.useEffect(() => {
        if (id !== undefined) {
            void fetching()
        }
        if(installersList.length > 0)
        setSpokenPwd(installersList?.filter((ins: any) => ins.ID === installerDetails?.ID)[0].installerInfo?.spokenPassword)
    }, [id, installersList?.length,count])

    const handleEditClick = (installerDetail: InstallersProps) => {
        setOpenEditInstallerModal(true)
        setInstallerData(installerDetail)
    }

    return (
        <div className="main-tab">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <AppBar elevation={md ? 0 : 0} position={'sticky'}>
                    <Toolbar className={classesApp.toolbar}>
                        {md ?
                            <IconButton
                                color={'default'}
                                onClick={handleBackClick}
                                edge={'start'}
                                style={{ marginRight: theme.spacing(3) }}
                                size="large"
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            : (
                                <IconButton
                                    color={'default'}
                                    onClick={handleBackClick}
                                    edge={'start'}
                                    style={{ marginRight: theme.spacing(3) }}
                                    size="large"
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                            )}
                        <div style={{ display: 'flex', flexDirection: 'column' }} >
                            <Typography variant='h6' sx={{ color: '#424E54' }} >&ensp;{installerDetails?.firstName} {installerDetails?.lastName}</Typography>
                        </div>
                        <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                        {md ?
                            <IconButton>
                                <EditIcon onClick={() => handleEditClick(installerDetails)} />
                            </IconButton> :
                            <Usermenu />}
                        <EditInstallerModal count={count} setCount={setCount} spokenPwd={spokenPwd} setSpokenPwd={setSpokenPwd} data={installerData} setData={setInstallerData} open={openEditInstallerModal} setOpen={setOpenEditInstallerModal} />
                    </Toolbar>
                </AppBar>
                <Divider />
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor: '#ffff', zIndex: 10000 }}>
                            <Paper square elevation={md ? 3 : 0} >
                                <TabList variant={md ? 'fullWidth' : 'fullWidth'} centered className={classes.tabs} textColor='inherit' TabIndicatorProps={{ style: { background: '#007BC1', textEmphasisColor: '#007BC1' } }} onChange={handleChange} aria-label="lab API tabs example" >
                                    <Tab label={'Details'} value="1" />
                                    <Tab label={'Panels'} value="2" />
                                    <Tab label={'Users'} value="3" />
                                </TabList>
                            </Paper>
                        </Box>
                        <TabPanel value="1"><Details count={count} setCount={setCount} data={installerData} setData={setInstallerData} open={openEditInstallerModal} setOpen={setOpenEditInstallerModal} /></TabPanel>
                        <TabPanel value="2"><InstallerPanelTab /></TabPanel>
                        <TabPanel value="3"><Users /></TabPanel>
                    </TabContext>
                </Box>
            </div>
        </div>
    );
}
