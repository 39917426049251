/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/prefer-as-const */
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { AppBar, IconButton, Paper, Toolbar, useMediaQuery, useTheme, Table, TableHead, TableRow, TableCell, TableBody, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { InfoListItem, Spacer } from '@brightlayer-ui/react-components';
import { useTranslation } from 'react-i18next';
import { AdministratorsProps } from './AdministratorsTypes';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import EditAdminModal from './EditAdmin/EditAdminModal';
import DeleteAdminModal from './DeleteAdmin/DeleteAdminModal';

type AdminModalProps = {
    count: number
    setCount: (count: number) => void
    adminData: AdministratorsProps
    open: boolean
    setOpen: (open: boolean) => void
    counter: number
    setCounter: (counter: number) => void
}

export default function AdminDetailsModal({ counter, setCounter, count, setCount, adminData, open, setOpen }: AdminModalProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const [openEditAdminModal, setOpenEditAdminModal] = React.useState(false);
    const [openDeleteAdminModal, setOpenDeleteAdminModal] = React.useState(false);
    const { t } = useTranslation();

    const handleClose = () => {
        setOpen(false);
    }

    React.useEffect(() => {
        if (counter !== 0) {
            setOpen(false);
        }
    }, [counter]);

    const handleEditAdminModal = () => {
        setOpenEditAdminModal(true)
    };
    const handleDeleteAdminModal = () => {
        setOpenDeleteAdminModal(true)
    };

    return (
        <div>
            <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box
                    sx={{
                        position: 'absolute' as 'absolute',
                        top: md ? '0%' : '40%',
                        left: md ? '0%' : '50%',
                        transform: md ? 'translate(0%, 0%)' : 'translate(-50%, -50%)',
                        width: md ? '100%' : 400,
                        height: md ? '100%' : '60%',
                        bgcolor: '#f7f8f8',
                        border: '0px solid #000',
                        boxShadow: 24,
                        p: 0,
                    }}
                >
                    <AppBar
                        elevation={2}
                        sx={{
                            pl: 2,
                            pt: 4,
                            pb: 2,
                            backgroundColor: 'white',
                        }}
                        position="static"
                    >
                        <Toolbar variant="dense">
                            <IconButton onClick={handleClose} edge="start" aria-label="menu" sx={{ mr: 2 }}>
                                <ArrowBackIcon color="action" />
                            </IconButton>
                            <Typography variant="h6" color="#424E54" component="div">
                                Administrator Details
                            </Typography>
                            <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined}  />
                            <IconButton onClick={handleEditAdminModal}>
                                <EditIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Paper
                        elevation={0}
                        sx={{
                            pl: 0,
                            overflow: 'auto',
                        }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle1">{t('lbl_name')}</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="body2">
                                            {adminData?.lastName} {adminData?.firstName}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle1">{t('lbl_login')}</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="body2">{adminData?.login}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle1">{t('lbl_email')}</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="body2">{adminData?.login}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle1">{t('lbl_contactEmail')}</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="body2">{adminData?.login}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle1">{t('lbl_userType')}</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="body2">
                                            {adminData?.userType === 'ADMIN' ? 'Administrator' : 'Normal Administrator'}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle1">{t('lbl_language')}</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="body2">
                                            {adminData.locale === 'en_US'
                                                ? 'English'
                                                : adminData.locale === 'de_DE'
                                                    ? 'Deutsch'
                                                    : adminData.locale === 'nl_NL'
                                                        ? 'Nederlands'
                                                        : adminData.locale === 'fr_FR'
                                                            ? 'Francais'
                                                            : adminData.locale === 'el_GR'
                                                                ? 'Ἑλληνική'
                                                                : adminData.locale === 'it_IT'
                                                                    ? 'Italiano'
                                                                    : adminData.locale === 'pt_PT'
                                                                        ? 'Portugues'
                                                                        : 'None'}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" colSpan={2}>
                                        <Button
                                            onClick={handleDeleteAdminModal}
                                            fullWidth
                                            color="error"
                                            disableRipple
                                            disableFocusRipple
                                        >
                                            {t('lbl_removeInstaller')}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </Paper>
                </Box>
            </Modal>
            <EditAdminModal data={adminData} count={count} setCount={setCount} open={openEditAdminModal} setOpen={setOpenEditAdminModal} />
            <DeleteAdminModal isMobile={true} counter={counter} setCounter={setCounter} data={adminData} open={openDeleteAdminModal} setOpen={setOpenDeleteAdminModal} />
        </div>
    );
}
