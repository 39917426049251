/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import Appbar from '../../components/Appbar/Appbar';

export function SupportTools(): JSX.Element {
    return (
        <>
            <div className="main-div">
                <Appbar toolbarMenu={false} title={'Support tools'} />
            </div>
        </>
    )
}