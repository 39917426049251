/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-console */
import React, { useState, useCallback } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useNavigate, useLocation } from 'react-router';
import { useDrawer } from '../contexts/drawerContextProvider';
import { Drawer, DrawerBody, DrawerHeader, DrawerNavGroup } from '@brightlayer-ui/react-components';
import { INSTALLER_MENU, ADMIN_MENU, ENGINEER_MENU, MANAGER_MENU, OFFICESTAFF_MENU, ENDUSER_MENU } from './routes';
import Menu from '@mui/icons-material/Menu';
import { ROLES } from '../entities/Constants';
import { useSelector, useDispatch } from 'react-redux';
import { useTypedDispatch } from '../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { setMenuSelected } from '../redux/actions/settingsActions';
import { userEmailAlertsForAllPanelsToEmpty } from '../redux/actions/panelEstateActions';
import { setDrawerState, setPanelView } from '../redux/actions/specificPanelActions';
import secureLocalStorage  from  "react-secure-storage";

export const NavigationDrawer: React.FC = () => {
    const { drawerOpen, setDrawerOpen } = useDrawer();
    const theme = useTheme();
    const dispatch = useTypedDispatch();
    const dispatchNormal = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const { setSelectedMenuHiglighted } = useSelector((state: any) => state.settingsReducer);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [selected, setSelected] = useState(location.pathname);

    if (selected === '/') {
        setSelected('');
    }

    const handleNavigate = useCallback(
        (id: string): void => {
            navigate(id);
            setSelected(id);
            //Set default view to list
            dispatch(setPanelView('list'));
            if (id !== '/')
                dispatch(userEmailAlertsForAllPanelsToEmpty())
        },
        [navigate, setSelected]
    );

    if (setSelectedMenuHiglighted !== '') {
        if (setSelectedMenuHiglighted === 'settings') {
            setSelected('settings')
        } else if (setSelectedMenuHiglighted === 'panels') {
            setSelected('/')
        }
        dispatch(setMenuSelected(''))
    }

    const role = secureLocalStorage.getItem('role') || '';

    // const handleMouseOver = () => {
    //     setDrawerOpen(true);
    // }

    // const handleMouseLeave = () => {
    //     setDrawerOpen(false);
    // }

    return (
        <Drawer
            open={drawerOpen}
            ModalProps={{
                onBackdropClick: (): void => {
                    setDrawerOpen(false);
                    dispatchNormal(setDrawerState('close'));
                },
            }}
            variant={isMobile ? 'temporary' : 'persistent'}
            activeItem={selected}
            openOnHover={false} 
            nonce={undefined} 
            onResize={undefined} 
            onResizeCapture={undefined}        >
            <DrawerHeader
                title={'SecureConnect'}
                subtitle={'Portal'}
                icon={<Menu />}
                onIconClick={(): void => {
                    setDrawerOpen(!drawerOpen);
                    dispatchNormal(setDrawerState(drawerOpen ? 'close' : 'open'));
                } } 
                nonce={undefined} onResize={undefined} onResizeCapture={undefined}    
                />
            {role === ROLES.INSTALLER &&
                <DrawerBody nonce={undefined} onResize={undefined} onResizeCapture={undefined} >
                    <DrawerNavGroup nonce={undefined} onResize={undefined} onResizeCapture={undefined} 
                        items={INSTALLER_MENU.map((page) => {
                            const Icon = page.icon;
                            return {
                                title: page.title,
                                itemID: page.route || '',
                                icon: <Icon />,
                                onClick:
                                    page.route !== undefined
                                        ? (): void => {
                                            handleNavigate(page.route);
                                            if (isMobile) setDrawerOpen(false);
                                        }
                                        : undefined,
                            };
                        })}
                        hidePadding
                    />
                </DrawerBody>
            }

            {secureLocalStorage.getItem('role') === ROLES.MANAGER &&
                <DrawerBody nonce={undefined} onResize={undefined} onResizeCapture={undefined} >
                    <DrawerNavGroup nonce={undefined} onResize={undefined} onResizeCapture={undefined} 
                        items={MANAGER_MENU.map((page) => {
                            const Icon = page.icon;
                            return {
                                title: page.title,
                                itemID: page.route || '',
                                icon: <Icon />,
                                onClick:
                                    page.route !== undefined
                                        ? (): void => {
                                            handleNavigate(page.route);
                                            if (isMobile) setDrawerOpen(false);
                                        }
                                        : undefined,
                            };
                        })}
                        hidePadding
                    />
                </DrawerBody>
            }

            {role === ROLES.ENGINEER &&
                <DrawerBody nonce={undefined} onResize={undefined} onResizeCapture={undefined} >
                    <DrawerNavGroup nonce={undefined} onResize={undefined} onResizeCapture={undefined} 
                        items={ENGINEER_MENU.map((page) => {
                            const Icon = page.icon;
                            return {
                                title: page.title,
                                itemID: page.route || '',
                                icon: <Icon />,
                                onClick:
                                    page.route !== undefined
                                        ? (): void => {
                                            handleNavigate(page.route);
                                            if (isMobile) setDrawerOpen(false);
                                        }
                                        : undefined,
                            };
                        })}
                        hidePadding
                    />
                </DrawerBody>
            }

            {secureLocalStorage.getItem('role') === ROLES.OFFICESTAFF &&
                <DrawerBody nonce={undefined} onResize={undefined} onResizeCapture={undefined} >
                    <DrawerNavGroup nonce={undefined} onResize={undefined} onResizeCapture={undefined} 
                        items={OFFICESTAFF_MENU.map((page) => {
                            const Icon = page.icon;
                            return {
                                title: page.title,
                                itemID: page.route || '',
                                icon: <Icon />,
                                onClick:
                                    page.route !== undefined
                                        ? (): void => {
                                            handleNavigate(page.route);
                                            if (isMobile) setDrawerOpen(false);
                                        }
                                        : undefined,
                            };
                        })}
                        hidePadding
                    />
                </DrawerBody>
            }

            {secureLocalStorage.getItem('role') === ROLES.ENDUSER &&
                <DrawerBody nonce={undefined} onResize={undefined} onResizeCapture={undefined} >
                    <DrawerNavGroup nonce={undefined} onResize={undefined} onResizeCapture={undefined} 
                        items={ENDUSER_MENU.map((page) => {
                            const Icon = page.icon;
                            return {
                                title: page.title,
                                itemID: page.route || '',
                                icon: <Icon />,
                                onClick:
                                    page.route !== undefined
                                        ? (): void => {
                                            handleNavigate(page.route);
                                            if (isMobile) setDrawerOpen(false);
                                        }
                                        : undefined,
                            };
                        })}
                        hidePadding
                    />
                </DrawerBody>
            }

            {role === ROLES.ADMIN &&
                <DrawerBody nonce={undefined} onResize={undefined} onResizeCapture={undefined} >
                    <DrawerNavGroup nonce={undefined} onResize={undefined} onResizeCapture={undefined} 
                        items={ADMIN_MENU.map((page) => {
                            const Icon = page.icon;
                            return {
                                title: page.title,
                                itemID: page.route || '',
                                icon: <Icon />,
                                onClick:
                                    page.route !== undefined
                                        ? (): void => {
                                            handleNavigate(page.route);
                                            if (isMobile) setDrawerOpen(false);
                                        }
                                        : undefined,
                            };
                        })}
                        hidePadding
                    />
                </DrawerBody>
            }
        </Drawer>
    );
};
