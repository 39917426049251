import { SiaEmailOptionsProps } from './../../components/UserEmailAlertsModal/UserEmailAlertsTypes';
import { UserGroupsProps } from './../../components/AccountUsers/AddUserModal/addUserModalTypes';
import { PanelARCReportingTypeProps } from './../../components/PanelARCReporting/PanelARCReportingTypes';
import { PanelNotificationTypeProps } from './../../components/PanelNotifications/PanelNotificationTypes';
import { ConnectionStatsTypeProps, PanelARCCountryCodesProps, PanelARCSettingsProps } from '../../components/SpecificPanelDetails/SpecificPanelEstateTypes';
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import { AnyAction } from "redux"
import { ThunkAction, ThunkDispatch } from "redux-thunk"
import { CurrentUserProps, EstateArrayProps, PanelArrayProps, EstateProps } from "../../components/ListAndGridView/panelEstateTypes"
import { DeletePanelProps } from '../../components/DeletePanelModal/DeletePanelModalTypes';
import reducers from "../reducers"
import store from "../store/store"
import { MobileAppUserByLoginPropTypes, MobileAppUsersPropTypes } from '../../components/MobileApplicationUsers/MobileApplicationUsersTypes';
import { AccountUsersProps } from '../../components/AccountUsers/AccountUsersTypes';
import { ReportGroupsPropTypes } from '../../components/ARCAccessModal/ARCAccessProps';
import { PanelLogsArrayProps } from '../../components/PanelLogs/PanelLogsTypes';
import { EndUserInfoProps } from '../../components/EditEndUserInfoModal/EditEndUserInfoTypes';
import { RemoteServiceReportProps } from '../../components/RemoteServicingModal/remoteServicingTypeProps';
import { AdministratorsArrayProps } from '../../components/Administrators/AdministratorsTypes';
import { InstallersArrayProps } from '../../components/Installers/InstallersTypes';


export type DispatchTypesProps = CurrentUserProps | PanelArrayProps | EstateArrayProps | EstateProps | 
                                  ConnectionStatsTypeProps | DeletePanelProps | PanelNotificationTypeProps |
                                   PanelARCReportingTypeProps | PanelARCSettingsProps | PanelARCCountryCodesProps |
                                   MobileAppUsersPropTypes | AccountUsersProps | ReportGroupsPropTypes | MobileAppUserByLoginPropTypes | UserGroupsProps |
                                   PanelLogsArrayProps | string[] | SiaEmailOptionsProps | EndUserInfoProps | RemoteServiceReportProps | AdministratorsArrayProps| InstallersArrayProps

//action required types for thunk
export type AppDispatch = typeof store.dispatch;
export type ReduxState = ReturnType<typeof reducers>;
export type TypedDispatch = ThunkDispatch<ReduxState, any, AnyAction>;
export type TypedThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  ReduxState,
  unknown,
  AnyAction
>;
export const useTypedDispatch = ():any => useDispatch<TypedDispatch>();
export const useTypedSelector: TypedUseSelectorHook<ReduxState> = useSelector;