/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint no-unused-expressions: ["error", { "allowTernary": true }] */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Button, Divider, IconButton, InputAdornment, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import { InfoListItem, ListItemTag, ThreeLiner, EmptyState } from '@brightlayer-ui/react-components';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { useParams } from 'react-router-dom';
import { fetchPanelARCReporting } from '../../redux/actions/specificPanelActions';
import TablePagination from '@mui/material/TablePagination';
import { PanelARCReportingProps, PanelARCReportingTypeProps } from './PanelARCReportingTypes';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ARCReportModal from './ARCReportModal';
import CancelIcon from '@mui/icons-material/Cancel';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { useTranslation } from 'react-i18next';
import { getAMorPM, getDate, getDateFormat, getHours, getMinutes, getSeconds, getMonth, getTimeFormat, getYear } from '../Timestamp/timestampCal';
import { CSVLink } from 'react-csv';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),

    color: theme.palette.text.secondary,
}));

export default function PanelARCReporting(): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const { panelARCReporting, panelARCData } = useSelector((state: any) => state.specificPanelAndEstateReducer);
    const { currentUser } = useSelector((state: any) => state.userReducer);
    //const currentUser = JSON.parse(localStorage.getItem('currentUser') || '')
    const [page, setPage] = React.useState(0);
    const [query, setQuery] = React.useState('');
    let panelARCReportings = (panelARCReporting !== undefined && panelARCReporting.length > 0 )? panelARCReporting: []
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [arcReportOpen, setArcReportOpen] = React.useState(false);
    const [arcReport, setArcReport] = React.useState<PanelARCReportingProps>();
    const dispatch = useTypedDispatch();
    const { id } = useParams();
    const { t } = useTranslation();

    React.useEffect(() => {
        dispatch(fetchPanelARCReporting(Number(id)));
    }, []);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleARCReportModal = (key: number) => {
        setArcReport(panelARCReporting[(page * 10)+key])
        setArcReportOpen(true);
    };

    const handleCancelClick = (): any => {
        setQuery('');
    }

    

    const getState = (status: string): string => {
        const state =
            (status === 'NACK_NO_RESPONSE_FROM_ARC'
                ? 'no response'
                : status === 'SUSPENDED_ON_CLOUD'
                ? 'Rejected'
                : 'sent');

        return state;
    };


    try {
        if (panelARCReportings.length > 0) {
            const filteredARCReporting = panelARCReportings.filter(
                (ARCReporting: PanelARCReportingProps) => (
                    ARCReporting.ascii && ARCReporting.ascii.toLowerCase().includes(query.toLowerCase()) || 
                    ARCReporting.value && ARCReporting.value.toLowerCase().includes(query.toLowerCase()) || 
                    ARCReporting.arcProvider && ARCReporting.arcProvider.toLowerCase().includes(query.toLowerCase()) || 
                    ARCReporting.state && getState(ARCReporting.state).toLowerCase().includes(query.toLowerCase()) || 
                    ARCReporting.raiseTimestamp && getDateFormat(ARCReporting.raiseTimestamp).toString().toLowerCase().includes(query.toLowerCase()) || 
                    ARCReporting.raiseTimestamp && getTimeFormat(ARCReporting.raiseTimestamp).toString().toLowerCase().includes(query.toLowerCase())
                )
            );
            panelARCReportings = query.length > 0 ? filteredARCReporting : panelARCReportings;
        }
    } catch (exception) {
        console.log(`Exception in panel notification filtering: ${JSON.stringify(exception)}`);
    }

    const Headers = [
        { label: 'Time Stamp', key: 'raiseTimestamp' },
        { label: 'ARC Provider', key: 'arcProvider' },
        { label: 'Place', key: 'value' },
        { label: 'ascii', key: 'ascii' },
        { label: 'State', key: 'state' },
    ];

    const csvReport = {
        filename: `panel-${id}-ARCreports.csv`,
        headers: Headers,
        data: panelARCData,
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

    return (
        <div style={md ? {} : { maxHeight: '100vh', overflow: 'auto' }}>
            <Box
                sx={
                    md
                        ? {
                              flexGrow: 1,
                              marginLeft: -2 ,
                              marginRight: -2 ,
                              marginTop: -2 ,
                              maxHeight: '80vh',
                              overflow: 'auto',
                              pb: 40,
                          }
                        : {
                              flexGrow: 1,
                              marginLeft:  8,
                              marginRight:  8,
                              marginTop:  4,
                              pb: 40,
                          }
                }
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Item elevation={0}>
                            <Paper sx={{ height: md ? '100vh' : '' }} elevation={0}>
                                <Box sx={{ flexGrow: 1, marginLeft: 2, marginRight: 2 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={md ? 10.5 : 12}>
                                            <Item elevation={0}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    placeholder={t('lbl_search')}
                                                    onChange={(e) => {
                                                        setPage(0)
                                                        setQuery(e.target.value)}}
                                                    value={query}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {query.length !== 0 && (
                                                                    <IconButton
                                                                        onClick={(event) => handleCancelClick()}
                                                                    >
                                                                        <CancelIcon />
                                                                    </IconButton>
                                                                )}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Item>
                                        </Grid>
                                        {md && (
                                            <Grid item xs={1.5}>
                                                <Item elevation={0}>
                                                    {(panelARCReportings?.length > 0 && panelARCData.length > 0) ?
                                                        <CSVLink {...csvReport}>
                                                            <IconButton size="small">
                                                                <DownloadIcon style={{ marginTop: 15 }} />
                                                            </IconButton>
                                                        </CSVLink>
                                                        :
                                                        <IconButton disabled size="small">
                                                            <DownloadIcon style={{ marginTop: 15 }} />
                                                        </IconButton>
                                                    }
                                                </Item>
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <Item
                                                sx={{ marginLeft: md ? -5.5 : 0, marginRight: md ? -2 : 0 }}
                                                elevation={md ? 0 : 1}
                                            >
                                                {panelARCReportings !== undefined &&
                                                    panelARCReportings.length > 0 && currentUser?.userType !== 'END_USER' &&
                                                    panelARCReportings?.slice((page * rowsPerPage),(page * rowsPerPage) + rowsPerPage).map(
                                                        (arcReporting: PanelARCReportingProps, key: number) => (
                                                            <div key={key}>
                                                                {md ? (
                                                                    <>
                                                                        <div
                                                                            style={{
                                                                                paddingTop: 10,
                                                                                paddingBottom: 10,
                                                                                backgroundColor:'#FBFBFB',
                                                                            }}
                                                                        >
                                                                            <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                                                title={undefined}
                                                                                subtitle={undefined}
                                                                                info={undefined}
                                                                                backgroundColor='#FBFBFB'
                                                                                iconAlign="right"
                                                                                statusColor={
                                                                                    arcReporting?.state ===
                                                                                    'NACK_NO_RESPONSE_FROM_ARC'
                                                                                        ? '#F0CB2F'
                                                                                        : arcReporting?.state ===
                                                                                          'SUSPENDED_ON_CLOUD'
                                                                                        ? '#CA3C3D'
                                                                                        : ''
                                                                                }
                                                                                chevron
                                                                                onClick={() =>
                                                                                    handleARCReportModal(key)
                                                                                }
                                                                                leftComponent={
                                                                                    <div
                                                                                        style={{
                                                                                            display: 'flex',
                                                                                            flexDirection: 'row',
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            style={{
                                                                                                display: 'flex',
                                                                                                flexDirection: 'column',
                                                                                                marginTop: 10,
                                                                                            }}
                                                                                        >
                                                                                            <div>
                                                                                                <strong>
                                                                                                    {getHours(
                                                                                                        arcReporting?.raiseTimestamp
                                                                                                    )}
                                                                                                    :
                                                                                                    {getMinutes(
                                                                                                        arcReporting?.raiseTimestamp
                                                                                                    )}
                                                                                                    :
                                                                                                    {getSeconds(
                                                                                                        arcReporting?.raiseTimestamp
                                                                                                    )}
                                                                                                </strong>
                                                                                                &ensp;
                                                                                                UTC
                                                                                            </div>
                                                                                            <div>
                                                                                                {getDate(
                                                                                                    arcReporting?.raiseTimestamp
                                                                                                )}
                                                                                                /
                                                                                                {getMonth(
                                                                                                    arcReporting?.raiseTimestamp
                                                                                                )}
                                                                                                /
                                                                                                {getYear(
                                                                                                    arcReporting?.raiseTimestamp
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                        &ensp;&ensp;&ensp;
                                                                                        <div
                                                                                            style={{
                                                                                                display: 'flex',
                                                                                                flexDirection: 'column',
                                                                                            }}
                                                                                        >
                                                                                            <input
                                                                                                readOnly
                                                                                                value={
                                                                                                    arcReporting?.arcProvider ===
                                                                                                    'SIA_IP'
                                                                                                        ? `${t('lbl_SIA_IP')}`
                                                                                                        : arcReporting?.arcProvider ===
                                                                                                          'CSL_DUAL_COM'
                                                                                                        ? `${t('lbl_CLS_Dualcom')}`
                                                                                                        : `${t('lbl_webway')}`
                                                                                                }
                                                                                                style={{
                                                                                                    border: 'none',
                                                                                                    outline: 'none',
                                                                                                    fontSize: 16,
                                                                                                    fontFamily:
                                                                                                        'Open Sans',
                                                                                                    fontStyle: 'normal',
                                                                                                    fontWeight: 600,
                                                                                                    lineHeight: 1,
                                                                                                    width: '80%',
                                                                                                    textAlign: 'left',
                                                                                                    backgroundColor:'#FBFBFB',
                                                                                                }}
                                                                                            />
                                                                                            <input
                                                                                                readOnly
                                                                                                value={
                                                                                                    arcReporting?.ascii
                                                                                                }
                                                                                                style={{
                                                                                                    border: 'none',
                                                                                                    outline: 'none',
                                                                                                    fontSize: 16,
                                                                                                    fontFamily:
                                                                                                        'Open Sans',
                                                                                                    fontStyle: 'normal',
                                                                                                    fontWeight: 400,
                                                                                                    lineHeight: 1,
                                                                                                    width: '100%',
                                                                                                    textAlign: 'left',
                                                                                                    backgroundColor:'#FBFBFB'
                                                                                                }}
                                                                                            />
                                                                                            <ListItemTag nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                                                                label={
                                                                                                    arcReporting?.state ===
                                                                                                    'NACK_NO_RESPONSE_FROM_ARC'
                                                                                                        ? 'no response'
                                                                                                        : arcReporting?.state ===
                                                                                                          'SUSPENDED_ON_CLOUD'
                                                                                                        ? 'Rejected'
                                                                                                        : arcReporting?.state ===
                                                                                                          'QUEUED'
                                                                                                        ? 'Ready for delivering'
                                                                                                        : 'sent'
                                                                                                }
                                                                                                backgroundColor={
                                                                                                    arcReporting?.state ===
                                                                                                    'NACK_NO_RESPONSE_FROM_ARC'
                                                                                                        ? '#F0CB2F'
                                                                                                        : arcReporting?.state ===
                                                                                                          'SUSPENDED_ON_CLOUD'
                                                                                                        ? '#CA3C3D'
                                                                                                        : '#FBFBFB'
                                                                                                }
                                                                                                fontColor={
                                                                                                    arcReporting?.state ===
                                                                                                    'NACK_NO_RESPONSE_FROM_ARC'
                                                                                                        ? '#424E54'
                                                                                                        : arcReporting?.state ===
                                                                                                          'SUSPENDED_ON_CLOUD'
                                                                                                        ? '#FFFFFF'
                                                                                                        : '#0088F2'
                                                                                                }
                                                                                                sx={{
                                                                                                    width:
                                                                                                        arcReporting?.state ===
                                                                                                        'NACK_NO_RESPONSE_FROM_ARC'
                                                                                                            ? 90
                                                                                                            : arcReporting?.state ===
                                                                                                              'SUSPENDED_ON_CLOUD'
                                                                                                            ? 62
                                                                                                            : arcReporting?.state ===
                                                                                                              'QUEUED'
                                                                                                            ? 150
                                                                                                            : 37,
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                icon={<ContentPasteIcon />}
                                                                            />
                                                                        </div>
                                                                        <Divider />
                                                                    </>
                                                                ) : (
                                                                    <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                                        title={undefined}
                                                                        subtitle={undefined}
                                                                        info={undefined}
                                                                        backgroundColor='#FBFBFB'
                                                                        statusColor={
                                                                            arcReporting?.state ===
                                                                            'NACK_NO_RESPONSE_FROM_ARC'
                                                                                ? '#F0CB2F'
                                                                                : arcReporting?.state ===
                                                                                  'SUSPENDED_ON_CLOUD'
                                                                                ? '#CA3C3D'
                                                                                : '#FBFBFB'
                                                                        }
                                                                        iconAlign="left"
                                                                        divider="full"
                                                                        leftComponent={
                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'row',
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        flexDirection: 'column',
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            paddingTop: 10,
                                                                                        }}
                                                                                    >
                                                                                        <strong>
                                                                                            {
                                                                                                <input
                                                                                                    readOnly
                                                                                                    value={getHours(
                                                                                                        arcReporting?.raiseTimestamp
                                                                                                    )}
                                                                                                    style={{
                                                                                                        border: 'none',
                                                                                                        outline: 'none',
                                                                                                        fontSize: 13,
                                                                                                        fontFamily:
                                                                                                            'Open Sans',
                                                                                                        fontStyle:
                                                                                                            'normal',
                                                                                                        fontWeight: 700,
                                                                                                        lineHeight: 1,
                                                                                                        width: 20,
                                                                                                        textAlign:
                                                                                                            'right',
                                                                                                            backgroundColor:'#FBFBFB'
                                                                                                    }}
                                                                                                />
                                                                                            }
                                                                                            :
                                                                                            {
                                                                                                <input
                                                                                                    readOnly
                                                                                                    value={getMinutes(
                                                                                                        arcReporting?.raiseTimestamp
                                                                                                    )}
                                                                                                    style={{
                                                                                                        border: 'none',
                                                                                                        outline: 'none',
                                                                                                        fontSize: 13,
                                                                                                        fontFamily:
                                                                                                            'Open Sans',
                                                                                                        fontStyle:
                                                                                                            'normal',
                                                                                                        fontWeight: 700,
                                                                                                        lineHeight: 1,
                                                                                                        width: 20,
                                                                                                        textAlign:
                                                                                                            'right',
                                                                                                            backgroundColor:'#FBFBFB'
                                                                                                    }}
                                                                                                />
                                                                                            }
                                                                                            :
                                                                                            {
                                                                                                <input
                                                                                                    readOnly
                                                                                                    value={getSeconds(
                                                                                                        arcReporting?.raiseTimestamp
                                                                                                    )}
                                                                                                    style={{
                                                                                                        border: 'none',
                                                                                                        outline: 'none',
                                                                                                        fontSize: 13,
                                                                                                        fontFamily:
                                                                                                            'Open Sans',
                                                                                                        fontStyle:
                                                                                                            'normal',
                                                                                                        fontWeight: 700,
                                                                                                        lineHeight: 1,
                                                                                                        width: 20,
                                                                                                        textAlign:
                                                                                                            'right',
                                                                                                            backgroundColor:'#FBFBFB'
                                                                                                    }}
                                                                                                />
                                                                                            }
                                                                                        </strong>
                                                                                        &ensp;
                                                                                        UTC
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            readOnly
                                                                                            value={getDate(
                                                                                                arcReporting?.raiseTimestamp
                                                                                            )}
                                                                                            style={{
                                                                                                border: 'none',
                                                                                                outline: 'none',
                                                                                                fontSize: 13,
                                                                                                fontFamily: 'Open Sans',
                                                                                                fontStyle: 'normal',
                                                                                                fontWeight: 400,
                                                                                                lineHeight: 1,
                                                                                                width: 20,
                                                                                                textAlign: 'right',
                                                                                            }}
                                                                                        />
                                                                                        /
                                                                                        {getMonth(
                                                                                            arcReporting?.raiseTimestamp
                                                                                        )}
                                                                                        /
                                                                                        {getYear(
                                                                                            arcReporting?.raiseTimestamp
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
                                                                                <div
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        flexDirection: 'column',
                                                                                    }}
                                                                                >
                                                                                    <Typography variant="subtitle1">
                                                                                        {arcReporting?.arcProvider ===
                                                                                        'SIA_IP'
                                                                                            ? `${t('lbl_SIA_IP')}`
                                                                                            : arcReporting?.arcProvider ===
                                                                                              'CSL_DUAL_COM'
                                                                                            ? `${t('lbl_CLS_Dualcom')}`
                                                                                            : `${t('lbl_webway')}`}
                                                                                    </Typography>
                                                                                    <input
                                                                                        readOnly
                                                                                        value={arcReporting?.value}
                                                                                        style={{
                                                                                            border: 'none',
                                                                                            outline: 'none',
                                                                                            fontSize: 16,
                                                                                            fontFamily: 'Open Sans',
                                                                                            fontStyle: 'normal',
                                                                                            fontWeight: 400,
                                                                                            lineHeight: 1,
                                                                                            width: 250,
                                                                                            textAlign: 'left',
                                                                                            backgroundColor:'#FBFBFB'
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                &ensp;&ensp;&ensp;&ensp;
                                                                                <div>
                                                                                    <ThreeLiner nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                                                        subtitle={
                                                                                            <Typography variant="body1">
                                                                                                {arcReporting?.ascii}
                                                                                            </Typography>
                                                                                        }
                                                                                        info=""
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        rightComponent={
                                                                            md ? (
                                                                                <>
                                                                                    <IconButton
                                                                                        onClick={() =>
                                                                                            handleARCReportModal(key)
                                                                                        }
                                                                                    >
                                                                                        <ChevronRightIcon />
                                                                                    </IconButton>
                                                                                </>
                                                                            ) : (
                                                                                <div
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        flexDirection: 'row',
                                                                                    }}
                                                                                >
                                                                                    <ListItemTag nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                                                        label={
                                                                                            arcReporting?.state ===
                                                                                            'NACK_NO_RESPONSE_FROM_ARC'
                                                                                                ? 'no response'
                                                                                                : arcReporting?.state ===
                                                                                                  'SUSPENDED_ON_CLOUD'
                                                                                                ? 'Rejected'
                                                                                                : arcReporting?.state ===
                                                                                                  'QUEUED'
                                                                                                ? 'Ready for delivering'
                                                                                                : 'sent'
                                                                                        }
                                                                                        backgroundColor={
                                                                                            arcReporting?.state ===
                                                                                            'NACK_NO_RESPONSE_FROM_ARC'
                                                                                                ? '#F0CB2F'
                                                                                                : arcReporting?.state ===
                                                                                                  'SUSPENDED_ON_CLOUD'
                                                                                                ? '#CA3C3D'
                                                                                                : '#E0F1FD'
                                                                                        }
                                                                                        fontColor={
                                                                                            arcReporting?.state ===
                                                                                            'NACK_NO_RESPONSE_FROM_ARC'
                                                                                                ? '#424E54'
                                                                                                : arcReporting?.state ===
                                                                                                  'SUSPENDED_ON_CLOUD'
                                                                                                ? '#FFFFFF'
                                                                                                : '#0088F2'
                                                                                        }
                                                                                        sx={{ mt: 1.5 }}
                                                                                    />
                                                                                    &ensp;&ensp;
                                                                                    <IconButton
                                                                                        onClick={() =>
                                                                                            handleARCReportModal(key)
                                                                                        }
                                                                                    >
                                                                                        <ChevronRightIcon />
                                                                                    </IconButton>
                                                                                    &ensp;&ensp;
                                                                                </div>
                                                                            )
                                                                        }
                                                                        icon={
                                                                            <IconButton>
                                                                                <ContentPasteIcon />
                                                                            </IconButton>
                                                                        }
                                                                    />
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                                <ARCReportModal
                                                    arcReport={arcReport}
                                                    open={arcReportOpen}
                                                    setOpen={setArcReportOpen}
                                                />
                                            </Item>
                                        </Grid>
                                        {md && <Grid item xs={12}></Grid>}
                                    {md && <Grid item xs={12}></Grid>}
                                    {md && <Grid item xs={12}></Grid>}
                                        {panelARCReportings?.length === 0 && query !== '' && (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    height: '100%',
                                                    width: '100%',
                                                }}
                                            >
                                                <div style={{ flex: '1 1 0px' }}>
                                                    <EmptyState nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                        icon={<ManageSearchIcon fontSize={'inherit'} />}
                                                        title={`${t('msg_noSearch_results')} "${query}"...`}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {md && <Grid item xs={12}></Grid>}
                                        {!md &&
                                         <Grid item xs={6}>
                                                <Item elevation={0}>
                                                    {(panelARCReportings?.length > 0 && panelARCData.length > 0) ?
                                                        <CSVLink {...csvReport}>
                                                            <Button variant='contained' disabled={panelARCReportings.length === 0} startIcon={<DownloadIcon />}>
                                                                Download ARC reporting events
                                                            </Button>
                                                        </CSVLink>
                                                        :
                                                        <Button variant='contained' disabled startIcon={<DownloadIcon />}>
                                                            Download ARC reporting events
                                                        </Button>
                                                    }
                                                </Item>
                                        </Grid>}
                                        <Grid item xs={md?12:6}>
                                            <Item
                                                sx={
                                                    md
                                                        ? {
                                                              position: 'fixed',
                                                              top: '100%',
                                                              transform: 'translate(-5%,-90%)',
                                                              width: '100%',
                                                          }
                                                        : {}
                                                }
                                                elevation={0}
                                            >
                                                <TablePagination
                                                    component="div"
                                                    count={panelARCReportings?.length}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    rowsPerPage={rowsPerPage}
                                                    onRowsPerPageChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChangeRowsPerPage(e)}
                                                    showFirstButton
                                                    showLastButton
                                                />
                                            </Item>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}
