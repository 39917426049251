export const AUTH_BASE_URL = `https://www.eatonsecureconnect.com/m2m-auth/rest`;
export const BASE_URL = `https://www.eatonsecureconnect.com/m2m-eaton-web/rest`;
export const WEB_ADMIN_BASE_URL = `https://www.eatonsecureconnect.com/m2m-web-admin/rest`;


/*** To connect to partner server ****/
export const REACT_APP_BASE_URL = `https://www.eatonsecureconnect.com`;
export const REACT_ARD_BASE_URL = `https://api.eatonsecureconnect.com`;
/*** To connect to poc1 server ****/
//export const REACT_APP_BASE_URL = `https://poc1.eatonsecureconnect.org`;
