/* eslint-disable arrow-body-style */
import { AccountsLogArrayProps } from '../../components/AccountsLog/AccountsLogType';
import { AdministratorsArrayProps } from '../../components/Administrators/AdministratorsTypes';
import { REACT_APP_BASE_URL } from '../../entities/Endpoints';
import { AdminActionTypes } from '../constants/administratorsActionTypes';
import { DispatchTypesProps } from '../dispatchActionTypes/dispatchAndThunkActionTypes';
import { Dispatch } from 'react';

export const setAdministrators = (administrators: AdministratorsArrayProps): any => {
    return {
        type: AdminActionTypes.SET_ADMINS,
        payload: administrators,
    };
}
export const setAdminAccountsLog = (accountsLog: AccountsLogArrayProps): any => {
    return {
        type: AdminActionTypes.SET_ADMINS_ACCOUNTS_LOG,
        payload: accountsLog,
    };
}
export const setAdministratorsLength = (administratorsLength: number): any => {
    return {
        type: AdminActionTypes.SET_ADMINS_LENGTH,
        payload: administratorsLength,
    };
}
export const setAddAdministrator = (addAdminStatus: number): any => {
    return {
        type: AdminActionTypes.SET_ADD_ADMINS,
        payload: addAdminStatus,
    };
}
export const setEditAdministrator = (editAdminStatus: number): any => {
    return {
        type: AdminActionTypes.SET_EDIT_ADMINS,
        payload: editAdminStatus,
    };
}
export const setDeleteAdministrator = (deleteAdminStatus: number): any => {
    return {
        type: AdminActionTypes.SET_DELETE_ADMINS,
        payload: deleteAdminStatus,
    };
}


export function fetchAdminAccountsLog(start: number) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(
            `${REACT_APP_BASE_URL}/m2m-web-admin/rest/audit?limit=10&search=&start=${start}`,
            {
                method: 'GET',
                credentials: 'include',
            }
        )
            .then((res) => res.json())
            .then((data) => {
                dispatch(setAdminAccountsLog(data));
            });
    }
}
export function fetchAdmins(start: number) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(
            `${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/admins?limit=10&search=&start=${start}`,
            {
                method: 'GET',
                credentials: 'include',
            }
        )
            .then((res) => res.json())
            .then((data) => {
                dispatch(setAdministrators(data));
            });
    }
}
export function fetchAdminsLength() {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(
            `${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/admins?limit=1000&search=&start=0`,
            {
                method: 'GET',
                credentials: 'include',
            }
        )
            .then((res) => res.json())
            .then((data) => {
                dispatch(setAdministratorsLength(data.length));
            });
    }
}

export function fetchAddAdministrator(body: any) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        }).then((res) => {
            dispatch((setAddAdministrator(res.status)))
        })
    };
};

export function deleteAdministrator(id: number) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${id}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            dispatch((setDeleteAdministrator(res.status)))
        })
    };
};

export function fetchEditAdministrator(body: any, id: number) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${id}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        }).then((res) => {
            dispatch((setEditAdministrator(res.status)))
        })
    };
};