
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';

import { makeStyles } from '@mui/styles';
import { Box, Paper,Tab,useMediaQuery, useTheme,AppBar, IconButton,Theme } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import createStyles from '@mui/styles/createStyles';
import Menu from '@mui/icons-material/Menu';
import { Spacer, ThreeLiner, ToolbarMenu } from '@brightlayer-ui/react-components';
import { useDrawer } from '../../contexts/drawerContextProvider';
import Usermenu from '../../components/UserMenu/UserMenu';
import LockIcon from '@mui/icons-material/Lock';
import { Toolbar } from '@material-ui/core';
import { useEffect } from 'react';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import Appbar from '../../components/Appbar/Appbar';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            backgroundColor:"#007BC1",
            color:"#fff"
        },
    })
);

export function SystemConfiguration(): JSX.Element{

    
    const [value, setValue] = React.useState('1');
    const { setDrawerOpen } = useDrawer();
    const theme = useTheme();
    const classes = useStyles(theme);
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useTypedDispatch();
    
    function handlerChange(event:React.SyntheticEvent,newValue:string){
        setValue(newValue);
    }

    return(
        <>
        <div className="main-div">
            <div style={{maxHeight:'100vh',overflow:'auto'}}>
                <Appbar toolbarMenu={false} title={'System config'}  />
            </div>
            </div>
        </>
    )
}