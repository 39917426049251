/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable arrow-body-style */
import { Dispatch } from 'react';
import { EndUserInfoProps } from '../../components/EditEndUserInfoModal/EditEndUserInfoTypes';
import { REACT_APP_BASE_URL } from '../../entities/Endpoints';
import { DispatchTypesProps } from '../dispatchActionTypes/dispatchAndThunkActionTypes';
import { EndUserInfoActionTypes } from './../constants/endUserInfoActionTypes';
const setEndUserInfo = (endUserInfo: EndUserInfoProps): any => {
    return {
        type: EndUserInfoActionTypes.SET_END_USER_INFO,
        payload: endUserInfo,
    };
}
const setEndUserInfoStatus = (endUserInfoStatus: number): any => {
    return {
        type: EndUserInfoActionTypes.SET_END_USER_INFO_STATUS,
        payload: endUserInfoStatus,
    };
}
const setInstallerLogo = (installerLogo: any): any => {
    return {
        type: EndUserInfoActionTypes.SET_INSTALLER_LOGO,
        payload: installerLogo,
    };
}
const setRemoveLogoStatus = (removeLogoStatus: number): any => {
    return {
        type: EndUserInfoActionTypes.SET_REMOVE_LOGO_STATUS,
        payload: removeLogoStatus,
    };
}
const setFetchLogoStatus = (fetchLogoStatus: number): any => {
    return {
        type: EndUserInfoActionTypes.SET_FETCH_LOGO_STATUS,
        payload: fetchLogoStatus,
    };
}



export function fetchEndUserInfo() {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/endUserInfo`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => dispatch(setEndUserInfo(data)))
    };
}
export function editEndUserInfo(body: EndUserInfoProps) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/endUserInfo`, {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
            },
        })
            .then((res) => dispatch(setEndUserInfoStatus(res.status)))
    };
}
export function fetchInstallerLogo() {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/installerLogo`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.blob())
            .then((blob) => {
                let base64String: any
                const reader = new FileReader()
                reader.readAsDataURL(blob)
                reader.onloadend = async function (): Promise<any> {
                    base64String = reader.result;
                    const imgBase = base64String.replace('application/octet-stream', 'image/png')
                    await fetch(imgBase, {
                        method: 'GET',
                        credentials: 'include',
                    })
                        .then((res) => {
                            dispatch(setInstallerLogo(res.url))
                        })
                }

            }
            )
    };
}
export function fetchInstallerLogoImageStatus() {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/installerLogo`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => dispatch(setFetchLogoStatus(res.status)))
    };
}

export function postInstallerLogo(preview: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(preview)
            .then((res) => res.blob())
            .then(async (blob) => {
                await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/installerLogo`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-type': 'application/octet-stream',
                    },
                    body: blob
                })
                    .then((res) => console.log(res.status))
            })

    };
}

export function removeInstallerLogoImage() {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/installerLogo/`, {
            method: 'DELETE',
            credentials: 'include',
        }).then((res) => dispatch(setRemoveLogoStatus(res.status)));
    };
}