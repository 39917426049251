/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import * as React from 'react';
import { EmptyState } from '@brightlayer-ui/react-components';
import { Divider, Paper, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import DeviceWithKeypad from '@brightlayer-ui/icons-mui/DeviceWithKeypad';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import FolderIcon from '@mui/icons-material/Folder';
import { useSelector } from 'react-redux';
import { PanelProps } from './panelEstateTypes';
import { Report } from '@mui/icons-material';
import { useTranslation, Trans } from 'react-i18next';
import { fetchSummaryDetails } from '../../redux/actions/panelEstateActions';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import secureLocalStorage  from  "react-secure-storage";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
}));

export default function PanelsOverView(): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const s = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useTypedDispatch();
    const { panels, estates, summaryDetails } = useSelector((state: any) => state.userReducer);
    const { t } = useTranslation();
    const  role = secureLocalStorage.getItem('role') || '';

    const fetchSummary = async()=>  {
        await dispatch(fetchSummaryDetails())
    }
    React.useEffect(() => {
        if(role === 'ADMIN')
        fetchSummary();
    }, [])
    return (
        <div>
            {s ? <></> : <><Typography variant="subtitle2" sx={{ p: 2 }} color={'primary'}>
                {/* {t('Welcome to React')} */}
                {t('menu_oveview')}
            </Typography>
                <Divider sx={{ height: 1 }} />
            </>
            }
            {panels !== undefined && estates !== undefined && (
                <Stack sx={{display:'flex',justifyContent:'center'}} spacing={sm? '-1.5%' : md?'7%' :'14%'} direction={'row'}>
                    <Item  elevation={0}>
                        {estates.length >= 0 && role !== 'ADMIN' && (
                            <EmptyState nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                icon={<FolderIcon sx={{ color: '#0088F2' }} fontSize="large" />}
                                title={estates?.length}
                                description={[
                                    <Typography key={0} color="action">
                                        {t('lbl_estates')}
                                    </Typography>,
                                ]}
                            />
                        )}
                    </Item>
                    <Item elevation={0}>
                        {panels.length >= 0 && (
                            <EmptyState nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                icon={<DeviceWithKeypad sx={{ color: '#0088F2' }} fontSize="large" />}
                                title={role === 'ADMIN' ? summaryDetails?.connectedPanels + summaryDetails?.disconnectedPanels : panels?.length}
                                description={[
                                    <Typography key={0} color="action">
                                        {t('lbl_panels')}
                                    </Typography>,
                                ]}
                            />
                        )}
                    </Item>
                    <Item elevation={0}>
                        {panels.length >= 0 && (
                            <EmptyState nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                icon={<CloudDoneIcon sx={{ color: '#39B620' }} fontSize="large" />}
                                //title={panels?.filter((panel: PanelProps) => panel.status === 'CONNECTED').length}
                                title={role === 'ADMIN' ? summaryDetails?.connectedPanels : panels?.filter((panel: PanelProps) => panel.status === 'CONNECTED').length}
                                description={[
                                    <Typography key={0} color="action">
                                        {t('lbl_connected')}
                                    </Typography>,
                                ]}
                            />
                        )}
                    </Item>
                    <Item elevation={0}>
                        {panels.length >= 0 && (
                            <EmptyState nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                icon={<CloudOffIcon sx={{ color: '#CA3C3D' }} fontSize="large" />}
                                //title={panels?.filter((panel: PanelProps) => panel.status === 'DISCONNECTED').length}
                                title={role === 'ADMIN' ? summaryDetails?.disconnectedPanels : panels?.filter((panel: PanelProps) => panel.status === 'DISCONNECTED').length}
                                description={[
                                    <Typography key={0} color="action">
                                        {t('lbl_disconnected')}
                                    </Typography>,
                                ]}
                            />
                        )}
                    </Item>
                </Stack>
            )}
        </div>
    );
}
