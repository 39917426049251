import { AccountUsersArrayProps } from '../../components/AccountUsers/AccountUsersTypes';
import { InstallersArrayProps } from '../../components/Installers/InstallersTypes';
import { PanelArrayProps } from '../../components/ListAndGridView/panelEstateTypes';
import { InstallerActionTypes } from '../constants/installersActionTypes';
const initialState = {
    installersList : <InstallersArrayProps>[],
    installerDetails : <any>{},
    totalPanels : <PanelArrayProps>[],
    installerUsersList : <AccountUsersArrayProps>[],
    activateOrDeactivateInstallerStatus : <number>0,
    removeInstallerStatus : <number>0,
    removeInstallerState : <boolean>false,
    installerLength : <number>0,
    editInstallerStatus : <number>0,
}

export const installerReducer = (state:any = initialState  , action: { type: any; payload:any }):any => {
        switch(action.type){
            case InstallerActionTypes.SET_INSTALLERS : 
                    return {...state,installersList : action.payload }
                    break;
            case InstallerActionTypes.SET_INSTALLER_DETAILS : 
                    return {...state,installerDetails : action.payload }
                    break;
            case InstallerActionTypes.SET_INSTALLER_PANELS : 
                    return {...state,totalPanels : action.payload }
                    break;
            case InstallerActionTypes.SET_INSTALLER_USERS : 
                    return {...state,installerUsersList : action.payload }
                    break;
            case InstallerActionTypes.SET_ACTIVATE_DEACTIVATE_INSTALLER : 
                    return {...state,activateOrDeactivateInstallerStatus : action.payload }
                    break;
            case InstallerActionTypes.SET_INSTALLERS_LENGTH : 
                    return {...state,installerLength : action.payload }
                    break;
            case InstallerActionTypes.SET_REMOVE_INSTALLER : 
                    return {...state,removeInstallerStatus : action.payload }
                    break;
            case InstallerActionTypes.SET_REMOVE_INSTALLER_STATE : 
                    return {...state,removeInstallerState : action.payload }
                    break;
            case InstallerActionTypes.SET_EDIT_INSTALLER_STATUS : 
                    return {...state, editInstallerStatus: action.payload }
                    break;
            default :
                    return state
                    break;
            
        }
}